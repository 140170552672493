import React, { useEffect, useState } from "react";
import "./GlitchEffect.css";
import "./RetryComponent.css"; // Importa el archivo CSS
import "./GlitchImage.css"; // Importa el archivo CSS

const GlitchEffect = () => {
  // Estado para almacenar las dimensiones actuales de la ventana
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const glitchLayer = document.getElementById("glitch-layer");

    function createGlitch() {
      const glitchBox = document.createElement("div");
      glitchBox.classList.add("glitch-box");
      glitchBox.classList.add("glitch");

      // Randomize size and position
      glitchBox.style.width = `${Math.random() * 100 + 50}px`;
      glitchBox.style.height = `${Math.random() * 2 + 1}px`; // Finer lines
      glitchBox.style.top = `${Math.random() * window.innerHeight}px`;
      glitchBox.style.left = `${Math.random() * window.innerWidth}px`;
      glitchBox.style.backgroundColor = `rgba(${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, 0.${Math.floor(Math.random() * 9)})`;

      glitchLayer.appendChild(glitchBox);

      setTimeout(() => {
        glitchLayer.removeChild(glitchBox);
      }, Math.floor(Math.random() * 200)); // Glitch effect duration
    }

    function startGlitch() {
      setInterval(createGlitch, Math.floor(Math.random() * 100)); // Frequency of glitch effect
    }

    startGlitch();

    // Función manejadora del cambio de tamaño
    function handleResize() {
      // Establece las nuevas dimensiones en el estado
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    document.body.style.overflow = "hidden";

    // Agrega el event listener
    window.addEventListener("resize", handleResize);

    // Función de limpieza para eliminar el event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="error-servicios"
      style={{ width: windowSize.width, height: windowSize.height }}
    >
      {" "}
      <div class="error-service-header">
        <div class="error-service-titulo glitch-text-titulo" data-text="Error!!">
          Error!!
        </div>
        <div class="error-service-subtitulo">
          Se produjo un error con los servicios de {document.title}
        </div>
      </div>
      <GlitchImage />
      <RetryComponent />
      <div id="glitch-layer"></div>
    </div>
  );
};

const GlitchImage = ({ src, alt }) => {
  return (
    <div class="glitch-img">
      <img
        src="images/error-astronauta.webp"
        loading="lazy"
        sizes="(max-width: 1279px) 40vw, 512px"
        srcset="images/error-astronauta.webp 500w, images/error-astronauta.webp 800w, images/error-astronauta.webp 1080w, images/error-astronauta.webp 1500w"
        alt=""
        class="error-service-img-astronauta"
      />
      <img
        src="images/error-astronauta.webp"
        loading="lazy"
        sizes="(max-width: 1279px) 40vw, 512px"
        srcset="images/error-astronauta.webp 500w, images/error-astronauta.webp 800w, images/error-astronauta.webp 1080w, images/error-astronauta.webp 1500w"
        alt=""
        class="error-service-img-astronauta"
      />
      <img
        src="images/error-astronauta.webp"
        loading="lazy"
        sizes="(max-width: 1279px) 40vw, 512px"
        srcset="images/error-astronauta.webp 500w, images/error-astronauta.webp 800w, images/error-astronauta.webp 1080w, images/error-astronauta.webp 1500w"
        alt=""
        class="error-service-img-astronauta"
      />
    </div>
  );
};

const RetryComponent = () => {
  const [retryCount, setRetryCount] = useState(() => {
    const savedRetryCount = localStorage.getItem("retryCount");

    return savedRetryCount
      ? parseInt(savedRetryCount, 10) > 5
        ? 5
        : parseInt(savedRetryCount, 10)
      : 1;
  });
  const [timeLeft, setTimeLeft] = useState(() => retryCount * 5); // Tiempo inicial basado en retryCount
  const [manualRetry, setManualRetry] = useState(false);

  useEffect(() => {
    // Calcula el tiempo de espera en segundos
    const delay = retryCount * 5;

    // Establece el tiempo de espera inicial
    setTimeLeft(delay);

    // Configura un temporizador de cuenta regresiva
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          clearInterval(countdown);
          // Intenta realizar la acción (en este caso, refrescar la página)
          if (!manualRetry) {
            window.location.reload();
          }
          return 0;
        }
      });
    }, 1000);

    // Limpia el temporizador de cuenta regresiva cuando el componente se desmonta
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timeLeft === 0 && !manualRetry) {
      const newRetryCount = retryCount + 1;
      setRetryCount(newRetryCount);
      localStorage.setItem("retryCount", newRetryCount.toString());
    }
  }, [timeLeft, manualRetry]);

  const handleManualRetry = () => {
    const newRetryCount = retryCount + 1;
    setManualRetry(true);
    setRetryCount(newRetryCount);
    localStorage.setItem("retryCount", newRetryCount.toString());
    window.location.reload();
  };

  return (
    <div class="error-service-footer">
      <div class="error-service-reintentar-texto">
        Reintentando en {timeLeft} segundos...
      </div>
      <div
        class="error-service-boton glitch-button"
        onClick={handleManualRetry}
      >
        <div class="error-service-boton-texto glitch-text" data-text="REINTENTAR AHORA">REINTENTAR AHORA</div>
      </div>
    </div>
  );
};
export default GlitchEffect;
