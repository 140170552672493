import React from "react";
import { Link } from "react-router-dom";

import MainLogin from "../modals/singin_singup/main";
import * as Variables from "../../utils/Global/Variables";

export default function Header(props) {
  const { vSelected, setvFrame } = props;
  return (
    <header className="header">
      <div className="header-menu-2">
        <a  href="/" className="link-style">
          <img
            src={`${Variables.V_URL_API}/uploads/e_lisa_7061f2591d.png`}
            loading="lazy"
            width="230"
            height="86.04"
            alt=""
            className="image-2"
            her
          />
        </a>
        <nav className="menu-2">
          <div
            className={
              vSelected === Variables.vFrames.home
                ? "item-menu-selected"
                : "item-menu"
            }
            onClick={setvFrame}
          >
            <a href="/" className="link-style">
              <div className="valor-2">Home</div>
            </a>
          </div>
          <div
            className={
              vSelected === Variables.vFrames.courses
                ? "item-menu-selected"
                : "item-menu"
            }
          >
            <a href="/cursos" className="link-style">
              <div className="valor-2">Cursos</div>
            </a>
          </div>
          <div
            className={
              vSelected === Variables.vFrames.about_us
                ? "item-menu-selected"
                : "item-menu"
            }
          >
            <a href="/nosotros" className="link-style">
              <div className="valor-2">Nosotros</div>
            </a>
          </div>
        </nav>
      </div>
      <div className="botones-inicio-sesion-2">
        <MainLogin setvFrame={setvFrame} />
      </div>
    </header>
  );
}
