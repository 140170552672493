import React, { useEffect, useMemo, useState } from "react";

import * as Gets from "../../utils/Rest apis/Gets";
import * as Metodos from "../../utils/Global/Metodos";
import { Empty, Spin, Tooltip } from "antd";
import { format, parseISO } from "date-fns";
import { es, vi } from "date-fns/locale";
import Fuse from "fuse.js";

import "./style.css";
import { Link } from "react-router-dom";
import Certificado from "../../components/modals/certificado";

function CCursos(props) {
  const { vCurso, vIdCursoSeleccionado, mSeleccionarCurso } = props;
  const [vClassSeleccionado, setvClassSeleccionado] = useState("-normal"); //"-contenedor"

  return (
    <div
      onClick={() => {
        mSeleccionarCurso(vCurso);
      }}
      class={
        vIdCursoSeleccionado === vCurso.id
          ? `tarjeta-8-seleccionado`
          : `tarjeta-8-normal`
      }
    >
      <div class="tarjeta-8-contenido">
        <div
          class="div-7"
          data-figma-id="546:2365"
          src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e48ed26b1aaa23cc0ea4e_curso_tarjeta_8.svg"
        >
          <img
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e48ed26b1aaa23cc0ea4e_curso_tarjeta_8.svg"
            data-figma-id="I685:2328;546:2364"
          />
        </div>
        <div class="tarjeta-8-contenedor-titulo">
          <div data-figma-id="685:2329" class="tarjeta-8-titulo truncated-text">
            {vCurso.attributes.curso.data.attributes.nombre}
          </div>
        </div>
      </div>
      <div class="tarjeta-8-calificacion">
        <div class="tarjeta-8-texto-numero" data-figma-id="685:2346">
          {vCurso.attributes.estado === "certificado"
            ? vCurso.attributes.calificacion
            : "-"}
        </div>
      </div>
    </div>
  );
}

function CRenderTemarioCalificacion(props) {
  const { vCurso, windowSize } = props;

  const [vTemario, setvTemario] = useState([]);
  const [vTotalLecciones, setvTotalLecciones] = useState(0);
  const [vTotalLeccionesTerminadas, setvTotalLeccionesTerminadas] = useState(0);
  const [vTotalSeccionesTerminadas, setvTotalSeccionesTerminadas] = useState(0);
  const [vIsLoadingTemario, setVIsLoadingTemario] = useState(true);

  useEffect(() => {
    setvTemario([]);
    setvTotalLecciones(0);
    setvTotalLeccionesTerminadas(0);
    setvTotalSeccionesTerminadas(0);
    setVIsLoadingTemario(true);
    if (vCurso !== null) {
      Gets.mGetCourse(vCurso.attributes.curso.data.id)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        })
        .then((result) => {
          mGetLeccionActual(
            result.data[0].id,
            result.data[0].attributes.secciones.data
          );
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [vCurso]);

  // Este useEffect se ejecuta cada vez que vTemario cambia
  useEffect(() => {
    // Calcula el total de secciones terminadas
    const totalTerminadas = vTemario.reduce((contador, seccion) => {
      // Comprueba si todas las lecciones de la sección están terminadas
      const todasLeccionesTerminadas = seccion.lecciones.every(
        (leccion) => leccion.terminado
      );
      // Si todas las lecciones están terminadas, incrementa el contador en 1
      return contador + (todasLeccionesTerminadas ? 1 : 0);
    }, 0);

    setvTotalSeccionesTerminadas(totalTerminadas);
  }, [vTemario]);

  const mGetLeccionActual = (vIdCurso, vSecciones) => {
    Gets.mLeccionActual(vIdCurso)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        var vNumLecciones = 0;
        var vNumLeccionesTerminadas = 0;

        const nuevoObjeto = vSecciones.map((seccion) => {
          const { id, attributes } = seccion;
          const nombre = attributes.nombre;
          const lecciones = attributes.lecciones.data.map((lecc) => {
            vNumLecciones += 1;
            return {
              id: lecc.id,
              duracion: lecc.attributes.duracion,
              nombre: lecc.attributes.nombre,
              contenido: lecc.attributes.contenido,
              num_leccion: vNumLecciones,
              calificacion: "-",
            };
          });
          // Agregar progreso y terminado si existe una coincidencia
          lecciones.forEach((lecc) => {
            result.data.forEach((item) => {
              const cursoLeccionId = item.attributes.curso_leccion.data.id;
              if (cursoLeccionId === lecc.id) {
                lecc.terminado = item.attributes.terminado;
                if (item.attributes.terminado) {
                  lecc.calificacion = item.attributes.calificacion;
                  vNumLeccionesTerminadas += 1;
                }
                lecc.idleccion_student_progresses = item.id;
              }
            });
          });

          return {
            id,
            nombre,
            totalLecciones: lecciones.length,
            lecciones,
          };
        });
        setvTotalLecciones(vNumLecciones);
        setvTotalLeccionesTerminadas(vNumLeccionesTerminadas);
        setvTemario(nuevoObjeto);
      })
      .catch(() => {})
      .finally(() => {
        setVIsLoadingTemario(false);
      });
  };

  const textoSecciones = useMemo(() => {
    return `${vTotalSeccionesTerminadas}/${vTemario.length}`;
  }, [vTotalSeccionesTerminadas, vTemario.length]);

  if (vCurso === null) {
    return (
      <>
        <div class="contenido-curso">
          <div class="frame-33">
            <div class="text-90-sin-info">
              Selecciona un curso pra mostrar su informacion
            </div>
          </div>
          <Empty description={<span>Curso no seleccionado.</span>} />
        </div>
      </>
    );
  }

  const mRenderStatus = (index, vleccion, vTotalLecciones) => {
    switch (vleccion?.terminado) {
      case true:
        return (
          <Link
            key={index}
            to={`/alumno?tipo=mio&cId=${vCurso.attributes.curso.data.id}&lecId=${vleccion?.id}`}
            className="link-style perfil-alumno-modulo-leccion-1"
            data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
          >
            <Tooltip
              title={`Da clic para repasar esta lección`}
              placement="topRight"
              className="perfil-alumno-modulo-leccion-1"
            >
              <div class="perfil-alumno-modulo-leccion-1">
              <div
              data-figma-id="767:3238"
              class="curso-alumno-temario-check-cirulo "
            >
              <img
                src="../images/check-circulo-verde.svg"
                data-figma-id="217:1395"
                class={"img-46 "}
              />
            </div>
                <div class="perfil-alumno-texto-leccion truncated-text1">
                  Lección {vleccion.num_leccion}: {vleccion.nombre}
                </div>
                <div class="perfil-alumno-leccion-texto-numero">
                  {vleccion.calificacion}
                </div>
              </div>
            </Tooltip>
          </Link>
        );
      case false:
        return (
          <Link
            key={index}
            to={`/alumno?tipo=mio&cId=${vCurso.attributes.curso.data.id}`}
            className="link-style perfil-alumno-modulo-leccion-1"
            data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
          >
            <Tooltip
              title={`Da clic para continuar con la lección`}
              placement="topRight"
              className="perfil-alumno-modulo-leccion-1"
            >
              <div class="perfil-alumno-modulo-leccion-1">
              <div data-figma-id="767:3266" class="curso-alumno-temario-circulo">
              <img
                src="../images/azul.svg"
                data-figma-id="650:6608"
                class={
                  "img-9 img-10 img-11 img-12 img-13 img-14 img-15 img-16 img-17 img-18 img-19 img-20 img-21 img-22 img-23 img-24 img-25 img-26 img-27 img-28 img-29 img-30 img-31 img-32 img-33 img-34 img-35 img-36 img-37 img-38 img-39 img-40 img-41 img-42 img-43 img-44 img-45 img-47 img-48 img-49 img-50 img-51 img-52 "
                }
              />
            </div>

                <div class="perfil-alumno-texto-leccion truncated-text1">
                  Lección {vleccion.num_leccion}: {vleccion.nombre}
                </div>

                <div class="perfil-alumno-leccion-texto-numero">
                  {vleccion.calificacion}
                </div>
              </div>
            </Tooltip>
          </Link>
        );

      default:
        return (
          <Tooltip
            key={index}
            title={`Debes terminar la lección ${vleccion.num_leccion - 1}`}
            placement="topRight"
            className="perfil-alumno-modulo-leccion-1 cursor-tache"
          >
            <div class="perfil-alumno-modulo-leccion-1 cursor-tache">
            <div
              data-figma-id="767:3287"
              class="curso-alumno-temario-play-circulo"
            >
              <img
                src="../images/play-circule.svg"
                data-figma-id="652:6651"
                class="img-8"
              />
            </div>
              <div class="perfil-alumno-texto-leccion truncated-text1">
                Lección {vleccion.num_leccion}: {vleccion.nombre}
              </div>

              <div class="perfil-alumno-leccion-texto-numero">
                {vleccion.calificacion}
              </div>
            </div>
          </Tooltip>
        );
    }
  };

  function BotonCertificado(props) {
    const { showModal } = props;

    return (
      <div
        data-figma-id="837:3136"
        class="boton-certificado-ver"
        onClick={showModal}
      >
        <div
          class="boton-certificado-ver-texto"
          data-figma-id="I837:3137;837:3135"
        >
          Ver certificado
        </div>
      </div>
    );
  }

  var vCountSeccion = 0;
  return (
    <>
      <div
        class="perfil-alumno-modulos invisible-scroll-container"
        style={{ height: windowSize.height - 250 }}
      >
        {vIsLoadingTemario ? (
          <Spin />
        ) : (
          <>
            {vTemario.map((vSeccion, index) => {
              vCountSeccion += 1;
              return (
                <>
                  <div key={new Date().now} className="perfil-alumno-modulo-1">
                    <div className="perfil-alumno-modulo-titulo truncated-text1">
                      Sección {vCountSeccion}: {vSeccion.nombre}
                    </div>
                    <>
                      {vSeccion.lecciones.map((vleccion, index) => {
                        return mRenderStatus(
                          index,
                          vleccion,
                          vSeccion.totalLecciones
                        );
                      })}
                    </>
                  </div>
                  {vTemario.length - 1 !== index && (
                    <wrapper class="vectors-wrapper-118" />
                  )}
                </>
              );
            })}
          </>
        )}
      </div>
      <div class="perfil-alumno-info">
        <div data-figma-id="588:2866" class="perfil-alumno-info-modulo">
          <div
            class="div-8 div-9 div-10 div-11 div-12 div-13 div-14 div-15 div-16 div-17 div-18 div-19 div-20 div-21 div-22 div-23 div-24 div-25 div-26 div-27 div-28 div-29 div-30 div-31 div-32 div-33 div-34 div-35 div-36 div-37 div-38 div-39 div-40 div-41 div-42 div-43 div-44 div-45 div-46 div-47 div-48"
            data-figma-id="588:2871"
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e4b777bc6b86ac899d4d2_book-modulos.svg"
          >
            <img
              src="../images/book_2.svg"
              data-figma-id="I588:2872;588:2870"
            />
          </div>
          <div class="perfil-alumno-info-titulo-1" data-figma-id="588:2827">
            Secciones
          </div>
          <div class="perfil-alumno-info-texto-1" data-figma-id="588:2828">
            {vIsLoadingTemario ? <Spin /> : textoSecciones}
          </div>
        </div>
        <div data-figma-id="588:2874" class="perfil-alumno-info-lecciones">
          <div
            class="div-49 div-50 div-51 div-52 div-53 div-54 div-55 div-56"
            data-figma-id="423:7352"
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e51d39839bd5e68857d44_tests.svg"
          >
            <img src="../images/tests.svg" data-figma-id="I588:2830;423:7351" />
          </div>
          <div class="perfil-alumno-info-titulo-2" data-figma-id="588:2831">
            Lecciones
          </div>
          <div class="perfil-alumno-info-texto-2" data-figma-id="588:2832">
            {vIsLoadingTemario ? (
              <Spin />
            ) : (
              `${vTotalLeccionesTerminadas}/${vTotalLecciones}`
            )}
          </div>
        </div>
        {vCurso.attributes.estado === "inconcluso" ? (
          <></>
        ) : vCurso.attributes.estado === "certificado" ? (
          <>
            <div
              data-figma-id="820:2908"
              class="perfil-alumno-certificado-obtenido"
            >
              <div
                class="perfil-alumno-certificado-obtenido-titulo"
                data-figma-id="820:2856"
              >
                Certificado
              </div>
              {vIsLoadingTemario ? (
                <Spin />
              ) : (
                <>
                  <div data-figma-id="820:2943">
                    <img
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/665151cab7ed9f06415d7724_certificado-4-verde.svg"
                      data-figma-id="I820:2944;820:2942"
                    />
                  </div>
                  <Certificado
                    {...props}
                    id_curso={vCurso.attributes.curso.data.id}
                    ComponenteBoton={<BotonCertificado {...props} />}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              data-figma-id="837:3129"
              class="perfil-alumno-certificado-en-proceso"
            >
              <div
                class="perfil-alumno-certificado-en-proceso-titulo"
                data-figma-id="820:2922"
              >
                Certificado
              </div>
              {vIsLoadingTemario ? (
                <Spin />
              ) : (
                <>
                  <div data-figma-id="820:2932">
                    <img
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/665151ccf71357e7effedda1_certificado-5-azul.svg"
                      data-figma-id="I820:2935;820:2931"
                    />
                  </div>
                  <div
                    class="perfil-alumno-certificado-en-proceso-texto"
                    data-figma-id="820:2934"
                  >
                    En Proceso
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default function Perfil(props) {
  const { windowSize, vIsLoadingDetallesPerfil, vUserMeDetallesPerfil } = props;
  const [vFiltroEstado, setvFiltroEstado] = useState("en curso");
  const [vValueBusqueda, setvValueBusqueda] = useState("");
  const [vCursos, setvCursos] = useState([]);
  const [vCursosBusqueda, setvCursosBusqueda] = useState([]);
  const [vIdCursoSeleccionado, setvIdCursoSeleccionado] = useState(-1);
  const [vCursoSeleccionado, setvCursoSeleccionado] = useState(null);
  const [vIsBusquedaLive, sestvIBusquedaLive] = useState(false);

  const [vIsLoadingCursos, setvIsLoadingCursos] = useState(true);

  const [vSummary, setvSummary] = useState({});
  const [vTiempoEstudio, setvTiempoEstudio] = useState({});
  const [vIsLoadingSummary, setvIsLoadingSummary] = useState(true);

  const [vPerfil, setvPerfil] = useState({});
  const [vIsLoadingPerfil, setvIsLoadingPerfil] = useState(true);

  useEffect(() => {
    Gets.mGetSummary()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvSummary(result);
        setvTiempoEstudio(Metodos.convertirYDenominar(result.segundos_estudio));
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingSummary(false);
      });
    Gets.mGetCursosAlumno()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvCursos(result.data);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingCursos(false);
      });
    Gets.mGetPerfil()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvPerfil(result);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingPerfil(false);
      });
  }, []);

  const opciones = {
    includeScore: true,
    // Especifica la ruta a la propiedad en la que deseas buscar
    keys: ["attributes.curso.data.attributes.nombre"],
    threshold: 0.2, // 0 a 1 = 0- mayor exactitud, 1 - menos exactitud
  };

  // Crear una instancia de Fuse
  const fuse = new Fuse(vCursos, opciones);

  const mBusquedaLive = (e) => {
    setvIsLoadingCursos(true);
    mLimpiar("");
    // Valor de muestra para buscar
    const valorMuestra = e.target.value;

    if (valorMuestra.length > 0) {
      sestvIBusquedaLive(true);
      // Realizar la búsqueda
      const resultados = fuse.search(valorMuestra);

      setvCursosBusqueda(resultados.map((vResultado) => vResultado.item));
    } else {
      sestvIBusquedaLive(false);
    }
    setvIsLoadingCursos(false);
  };

  const formatearFecha = (fecha) => {
    // Obtener el nombre completo del mes
    const mesCompleto = format(parseISO(fecha), "MMMM", { locale: es });

    // Convertir las primeras tres letras del mes a mayúscula la primera letra
    const mesCorto =
      mesCompleto.charAt(0).toUpperCase() +
      mesCompleto.slice(1, 3).toLowerCase();

    // Obtener el año
    const año = format(parseISO(fecha), "yyyy");

    return `${mesCorto} ${año}`;
  };

  const mRenderCuadroPerfil = () => {
    if (vIsLoadingPerfil) {
      return <Spin />;
    } else {
      return (
        <>
          <div class="perfil-alumno-nombre">{`${vPerfil.nombre} ${vPerfil.apellidos}`}</div>
          <div class="perfil-alumno-dato-1">{`${vPerfil.email}`}</div>
          <div class="perfil-alumno-dato-1">{`${vPerfil.telefono}`}</div>
          <img
            src={`${window.location.origin}/images/Vectors-Wrapper_144.svg`}
            loading="lazy"
            width="139.99783325195312"
            height="0.7777658104896545"
            alt=""
            class="vectors-wrapper-227"
          />
          <div class="perfil-alumno-dato-1">
            Estudiante desde: {formatearFecha(vPerfil.createdAt)}
          </div>
          <div class="perfil-alumno-dato-1">{`ID: ${vPerfil.id}`}</div>
        </>
      );
    }
  };
  const mRenderTemarioCalificacion = () => {
    if (vIsLoadingCursos) {
      return <Spin />;
    } else {
      return (
        <CRenderTemarioCalificacion {...props} vCurso={vCursoSeleccionado} />
      );
    }
  };

  const mSeleccionarCurso = (vCurso) => {
    setvIdCursoSeleccionado(vCurso.id);
    setvCursoSeleccionado(vCurso);
  };
  const mRenderCurso = () => {
    if (vIsLoadingCursos) {
      return <Spin />;
    } else {
      var vCursosFiltro = [];
      if (vFiltroEstado !== "") {
        vCursosFiltro = vCursos.filter(
          (vCurso) => vCurso.attributes.estado === vFiltroEstado
        );
      } else {
        vCursosFiltro = !vIsBusquedaLive ? vCursos : vCursosBusqueda;
      }
      if (vCursosFiltro.length > 0) {
        return vCursosFiltro.map((vCurso) => (
          <CCursos
            mSeleccionarCurso={mSeleccionarCurso}
            vIdCursoSeleccionado={vIdCursoSeleccionado}
            vCurso={vCurso}
          />
        ));
      } else {
        return <Empty description={<span>No hay cursos.</span>} />;
      }
    }
  };

  const mLimpiar = (vE) => {
    if (vE !== "") {
      setvValueBusqueda("");
      sestvIBusquedaLive(false);
    }
    setvIdCursoSeleccionado(-1);
    setvCursoSeleccionado(null);
  };

  const CRenderBusqueda = () => {
    const CBtnEstado = (props) => {
      const { vEstado, btnText, iconSeleccionado, iconNormal } = props;
      const [vHover, setvHover] = useState("-seleccionado");

      if (vFiltroEstado === vEstado) {
        return (
          <div class="filtro-en-curso">
            {iconSeleccionado.length > 0 && (
              <img src={iconSeleccionado} loading="lazy" />
            )}

            <div class="en-curso-1">{btnText}</div>
          </div>
        );
      } else {
        return (
          <div
            class={`filtro-en-curso${vHover}`}
            onClick={() => {
              mLimpiar(vEstado);
              setvFiltroEstado(vEstado);
            }}
            onMouseEnter={() => setvHover("")}
            onMouseLeave={() => setvHover("-seleccionado")}
          >
            {iconNormal.length > 0 && <img src={iconNormal} loading="lazy" />}

            <div class="en-curso-1">{btnText}</div>
          </div>
        );
      }
    };

    return (
      <>
        <CBtnEstado
          vEstado=""
          btnText="Todos"
          iconSeleccionado=""
          iconNormal=""
        />
        <CBtnEstado
          vEstado="en curso"
          btnText="En Curso"
          iconSeleccionado="images/pencil-1.svg"
          iconNormal="images/pencil-2.svg"
        />
        <CBtnEstado
          vEstado="certificado"
          btnText="Certificado"
          iconSeleccionado="images/certificado-1.svg"
          iconNormal="images/certificadoo.svg"
        />
        <CBtnEstado
          vEstado="inconcluso"
          btnText="Inconcluso"
          iconSeleccionado="images/stop-1.svg"
          iconNormal="images/stop-2.svg"
        />
      </>
    );
  };

  const mRenderTuProgreso = () => {
    if (vIsLoadingSummary) {
      return <Spin />;
    }
    return (
      <>
        <div data-figma-id="588:2890" class="perfil-alumno-progreso-lecciones">
          <div
            class="div-8 div-9 div-10 div-11 div-12 div-13 div-14 div-15 div-16 div-17 div-18 div-19 div-20 div-21 div-22 div-23 div-24 div-25 div-26 div-27 div-28 div-29 div-30 div-31 div-32 div-33 div-34 div-35 div-36 div-37 div-38 div-39 div-40 div-41 div-42 div-43 div-44 div-45 div-46 div-47 div-48"
            data-figma-id="588:2871"
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e4b777bc6b86ac899d4d2_book-modulos.svg"
          >
            <img
              src="../images/book_2.svg"
              data-figma-id="I588:2872;588:2870"
            />
          </div>
          <div class="perfil-alumno-numero-lecciones" data-figma-id="588:2892">
            {vSummary.lecciones_terminadas}
          </div>
          <div class="perfil-alumno-lecciones" data-figma-id="588:2893">
            lecciones completadas
          </div>
        </div>
        <div data-figma-id="588:2894" class="perfil-alumno-progreso-horas">
          <div class="div-57" data-figma-id="588:2915">
            <img
              src="../images/clock_2.svg"
              data-figma-id="I588:2916;588:2914"
            />
          </div>
          <div class="perfil-alumno-horas" data-figma-id="588:2896">
            {vTiempoEstudio[0]}
          </div>
          <div class="perfil-alumno-texto-horas" data-figma-id="588:2897">
            {vTiempoEstudio[1]} de aprendizaje
          </div>
        </div>
        <div data-figma-id="588:2898" class="perfil-alumno-progreso-test">
          <div
            class="div-49 div-50 div-51 div-52 div-53 div-54 div-55 div-56"
            data-figma-id="423:7352"
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663e51d39839bd5e68857d44_tests.svg"
          >
            <img src="../images/tests.svg" data-figma-id="I588:2830;423:7351" />
          </div>
          <div class="perfil-alumno-test" data-figma-id="588:2900">
            {vSummary.evaluaciones}
          </div>
          <div class="perfil-alumno-texto-test" data-figma-id="588:2901">
            tests completados
          </div>
        </div>
      </>
    );
  };

  return (
    <div class="perfil-alumno-contenido-principal">
      <div class="perfil-alumno-contenido-1">
        <div class="perfil-alumno-busqueda-filtros">
          <div class="barra-buscar">
            <img
              src={`${window.location.origin}/images/search.svg`}
              data-figma-id="I404:1853;404:1851"
            ></img>
            <input
              class="barra-buscar-texto"
              value={vValueBusqueda}
              onChange={(e) => {
                setvFiltroEstado("");
                setvValueBusqueda(e.target.value);
                mBusquedaLive(e);
              }}
            />
          </div>
          <CRenderBusqueda />
        </div>
        <div class="perfil-alumno-cursos-modulos">
          <div class="perfil-alumno-cursos">{mRenderCurso()}</div>
          <div class="perfil-alumno-info-modulos">
            {mRenderTemarioCalificacion()}
          </div>
        </div>
      </div>
      <div class="perfil-alumno-contenido-2">
        <div class="perfil-alumno-info-alumno">{mRenderCuadroPerfil()}</div>
        <div class="perfil-alumno-progreso">
          <div class="perfil-alumno-texto-progreso">Tu Progreso</div>
          <div class="perfil-alumno-progreso-contenido">
            {mRenderTuProgreso()}
          </div>
        </div>
      </div>
    </div>
  );
}
