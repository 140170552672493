import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Skeleton,
  ConfigProvider,
  Form,
  message,
  Spin,
} from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Inputs } from "../input_login_singup/Input";
import * as Post from "../../utils/Rest apis/Posts";
import Btn from "../botones/Boton";

import * as Gets from "../../utils/Rest apis/Gets";

export default function Contactanos(props) {
  const {
    handleCancel,
    mCambiarTipo,
    messageApi,
    vBtnText,
    vNombre,
    vApellido,
    vCorreo,
    vTelefono,
  } = props;
  const [vIsDisable, setvIsDisable] = useState(false);
  const [messageApiError, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vInfoContacto, setvInfoContacto] = useState({});
  const [vLoadingInfo, setvLoadingInfo] = useState(true);

  useEffect(() => {
    Gets.mGetContactoInfo()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {
        setvInfoContacto(response.data.attributes);
      })
      .catch((error) => {})
      .finally(() => setvLoadingInfo(false));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    setvIsDisable(true);
    Post.mContactanosSoporte(values)
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === "succes") {
          messageApi.open({
            type: "success",
            content:
              "Tu mensaje fue enviado con éxito, uno de nuestros administradores te responderá lo mas pronto posible.",
            duration: 10,
          });
          handleCancelModal();
        } else {
          if (response?.errorMessage) {
            messageApiError.open({
              type: "error",
              content: response.errorMessage,
              duration: 5,
            });
          } else {
            messageApiError.open({
              type: "error",
              content: "Error desconocido, contacta a un administrador.",
              duration: 5,
            });
          }
        }
        setvIsDisable(false);
      })
      .catch((err) => {
        messageApiError.open({
          type: "error",
          content: "Error desconocido, contacta a un administrador.",
          duration: 5,
        });
        setvIsDisable(false);
      });
  };

  const mRenderInfoContacto = () => {
    if (vLoadingInfo) {
      return <Spin tip="Loading " />;
    } else {
      return (
        <>
          <div class="contacto-md">
            <WhatsAppOutlined style={{ fontSize: "30px", color: "#fff" }} />
            <div
              class="text-contacto-md"
              dangerouslySetInnerHTML={{
                __html: vInfoContacto.telefono.replace(/\n/g, "<br/>"),
              }}
            />
          </div>
          <div class="contacto-md">
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_38.svg`}
              loading="lazy"
              width="30"
              height="30"
              alt=""
              class="icon-contacto-md"
            />
            <div class="text-contacto-md">{vInfoContacto.correo}</div>
          </div>
        </>
      );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Skeleton: {
            gradientFromColor: "#fff6",
          },
          Modal: {
            borderRadiusLG: "20px",
            contentBg: "#ffffff00",
            paddingContentHorizontalLG: "0px",
          },
        },
      }}
    >
      <Btn
        vClass="btn2"
        vClassText="btn2-text"
        vText={vBtnText}
        vActionBtn={showModal}
      />
      {contextHolder}
      <Modal
        title=""
        open={isModalOpen}
        onCancel={handleCancelModal}
        footer={null}
        closeIcon={null}
        centered={true}
        destroyOnClose={true}
        className="md-contactanos"
      >
        <div className="md-contactanos">
          <div class="div-md-contactanos-izq">
            <div class="div-title-md">
              <div class="title-md">Contáctanos</div>
              <div class="text-md">
                Completa el formulario y nos comunicaremos contigo a la
                brevedad.
              </div>
            </div>
            {mRenderInfoContacto()}
          </div>
          <div class="div-md-contactanos-der">
            <Form
              onFinish={onFinish}
              className="form-md"
              initialValues={{
                nombre: vNombre,
                apellidos: vApellido,
                email: vCorreo,
                phone: vTelefono,
              }} // Establece valores iniciales para los campos del formulario
            >
              <div className="inputs-md">
                <div div class="div-input-row">
                  <Form.Item
                    className="input-md input-modal"
                    name="nombre"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa tu nombre",
                      },
                    ]}
                  >
                    <Inputs
                      nombre="Nombre(s)*"
                      tipo="normal"
                      placeholder="Campo obligatorio"
                    />
                  </Form.Item>
                  <Form.Item
                    className="input-md input-modal"
                    name="apellidos"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa tus apellidos",
                      },
                    ]}
                  >
                    <Inputs
                      nombre="Apellidos*"
                      tipo="normal"
                      placeholder="Campo obligatorio"
                    />
                  </Form.Item>
                </div>
                <div div class="div-input-row">
                  <Form.Item
                    className="input-md input-modal"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa tu correo electrónico",
                      },
                      {
                        type: "email",
                        message: "Correo electrónico no válido!",
                      },
                    ]}
                  >
                    <Inputs
                      nombre="Correo Electrónico*"
                      tipo="normal"
                      placeholder="Campo obligatorio"
                    />
                  </Form.Item>
                  <Form.Item
                    className="input-md input-modal"
                    name="phone"
                    rules={[
                      {
                        required: false,
                        message: "Ingresa tu número de teléfono",
                      },
                    ]}
                  >
                    <Inputs
                      nombre="Teléfono"
                      tipo="normal"
                      placeholder="Opcional"
                    />
                  </Form.Item>
                </div>
                <div div class="div-input-row">
                  <Form.Item
                    className="input-md input-modal"
                    name="institucion"
                    rules={[
                      {
                        required: false,
                        message: "Ingresa tu número de teléfono",
                      },
                    ]}
                  >
                    <Inputs
                      nombre="Institución"
                      tipo="normal"
                      placeholder="Opcional"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  className="textarea-md input-textarea-modal"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Ingresa el mensaje a mandar",
                    },
                  ]}
                >
                  <Inputs nombre="Mensaje" tipo="textarea" />
                </Form.Item>
              </div>
              <div className="butons-md btn2-md-crr ">
                <div
                  class="btn1-md"
                  disabled={vIsDisable}
                  onClick={handleCancelModal}
                >
                  <div class="text-btn1-md">Cancelar</div>
                </div>
                <Button
                  htmlType="submit"
                  className="btn2-md"
                  disabled={vIsDisable}
                >
                  <div class="text-btn2-md">Enviar</div>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
