import React, { useState } from "react";
import { Collapse, Select, ConfigProvider } from "antd";
const { Option } = Select;

export default function Tabla(props) {
  const { vSecciones } = props;
  var vConteoLecciones = 0;
  const [expandIconPosition, setExpandIconPosition] = useState("start");
  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };
  const onChange = (key) => {};

  const mCrearLecciones = (vLecciones) => {
    if (vLecciones.length === 0) {
      return (
        <>
          <div className="leccion-1">
            <div className="text-63">
              Vaya, parece que esta sección no tiene lecciones...
            </div>
          </div>
        </>
      );
    }
    return vLecciones.map((vLeccion, index) => {
      vConteoLecciones += 1;
      return (
        <div key={index} className="leccion-1">
          <img
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/6543e7f0f9ab9d995a199b60_Vectors-Wrapper.svg"
            loading="lazy"
            width="24"
            height="24"
            alt=""
            className="vectors-wrapper-79"
          />
          <div className="text-63">
            Lección {vConteoLecciones}: {vLeccion.attributes.nombre}
          </div>
          <div className="duracioon">{vLeccion.attributes.duracion} min</div>
        </div>
      );
    });
  };

  const mCrearSecciones = () => {
    var vSeccionesTabla = vSecciones.map((vSeccion, index) => {
      return {
        key: `${index}`,
        label: `${vSeccion.attributes.nombre}`,
        children: (
          <div className="no-comprado-alumno-tabla-contenido-1">
            {mCrearLecciones(vSeccion.attributes.lecciones.data)}
          </div>
        ),
        extra: genExtra(
          vSeccion.attributes.lecciones.data.length,
          vSeccion.attributes.duracion_total
        ),
      };
    });
    return (
      <Collapse
        activeKey="-1"
        accordion={true}
        onChange={onChange}
        expandIconPosition={expandIconPosition}
        items={vSeccionesTabla}
        style={{
          width: "100%",
          maxWidth: "742px",
        }}
      />
    );
  };

  const genExtra = (leccion, minutos) => (
    <p>
      {leccion} lecciones // {minutos} minutos{" "}
    </p>
  );
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            colorTextHeading: "rgba(255, 255, 255, 1)",
            colorText: "rgba(255, 255, 255, 1)",
            colorBorder: "rgba(255, 255, 255, 1)",
            colorBgContainer: "rgba(255, 255, 255, 0)",
            fontSize: 14,
            fontFamily: "Poppins",
          },
        },
      }}
    >
      <div className="no-comprado-alumno-tabla-contenido">
      {mCrearSecciones()}
      </div>
    </ConfigProvider>
  );
}
