import React from "react";
import { Link } from "react-router-dom";

export default function Btn(props) {
  const {
    vActionBtn = () => {},
    vText = "Boton",
    vClass = "btn1",
    vClassText = "btn1-text",
    vIsLink = false,
    vTo = "",
  } = props;
  if (vIsLink) {
    return (
      <div className={vClass} onClick={() => vActionBtn()}>
        <Link to={vTo} className="link-style">
          <div className={vClassText}>{vText}</div>
        </Link>
      </div>
    );
  } else {
    return (
      <div className={vClass} onClick={() => vActionBtn()}>
        <div className={vClassText}>{vText}</div>
      </div>
    );
  }
}
