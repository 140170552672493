export const V_URL_APi_STRAPPI = "https://mhm.tecnologinc.com";
export const V_URL_APi_PYTHON = "https://mhm.tecnologinc.com/api2/";
export const V_URL_API =
  window.location.origin === "https://dev-view-e-lisa.cloudih.io"
    ? "https://api-e-lisa.cloudih.io"
    : window.location.origin === "https://dev-view-e-lisa.tecnologinc.com"
    ? "https://api-e-lisa.cloudih.io"
    : window.location.origin === "http://dev-view-e-lisa.cloudih.io:3000"
    ? "https://api-e-lisa.cloudih.io"
    : window.location.origin;

export const vFrames = {
  modal_login: "login",
  modal_singup: "singup",
  home: "home",
  courses: "courses",
  about_us: "about us",
  course_not_purchased: "course_not_purchased",
  course_alumno: "course_alumno",
  course_not_purchased_alumno: "course_not_purchased_alumno",
  selected_menu: {
    home: "home",
    courses: "courses",
    course_not_purchased: "course_not_purchased",
  },
  alumno: {
    mis_cursos: "mio",
    home: "",
    perfil: "perfil",
    curso_no_comprado: "cnc",
  },
  profesor: {
    mis_cursos: "mio",
    home: "",
    perfil: "perfil",
  },
};
