import React, { useCallback, useEffect, useState } from "react";
import { Home } from "./pages/Home";
import Courses from "./pages/Courses";
import AboutUs from "./pages/AboutUs";
import PublicCourseNotPurchased from "./pages/PublicCourseNotPurchased";
import Alumno from "./pages/alumno/Alumno";
import Profesor from "./pages/profesor/Profesor";
import GlitchVideo from "./components/GlitchVideo/GlitchVideo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import * as Variables from "./utils/Global/Variables";
import * as Gets from "./utils/Rest apis/Gets";

import "./css/style.css";
import "./css/style2.css";
import "./css/normalize.css";
import "./css/webflow.css";
import { ConfigProvider, Spin, notification } from "antd";

function App() {
  const [vCourse, setvCourse] = useState({});
  const [vTitulo_Courses, setvTitulo_Courses] = useState(undefined);
  const [vKeyCourses, setvKeyCourses] = useState(Date.now());
  const [vBusqueda, setvBusqueda] = useState(null);

  const [vIsValidandoApi1, setvIsValidandoApi1] = useState(true);
  const [vIsValidandoApi2, setvIsValidandoApi2] = useState(true);

  const [vIsErrorApi1, setvIsErrorApi1] = useState(false);
  const [vIsErrorApi2, setvIsErrorApi2] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const [vKeyRenderApp, setvKeyRenderApp] = useState(Date.now());

  useEffect(() => {
    Gets.mGetValidarApi1()
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          openNotification(
            "Congruencia en datos del perfil",
            "Se produjo un error al momento de cargar los datos del perfil.",
            "error"
          );
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {})
      .catch((error) => {
        setvIsErrorApi1(true);
        openNotification(
          "Error en los servicios",
          "Uno de nuestros servicios no esta funcionando correctamente, intenta mas tarde",
          "error"
        );
      })
      .finally(() => {
        setvIsValidandoApi1(false);
      });

    Gets.mGetValidarApi2()
      .then((response) => {
        console.log("xd:",response);
        if (!response.ok) {
          openNotification(
            "Congruencia en datos del perfil",
            "Se produjo un error al momento de cargar los datos del perfil.",
            "error"
          );
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {})
      .catch((error) => {
        setvIsErrorApi2(true);
        openNotification(
          "Error en los servicios",
          "Uno de nuestros servicios no esta funcionando correctamente, intenta mas tarde",
          "error"
        );
      })
      .finally(() => {
        setvIsValidandoApi2(false);
      });
  }, []);

  const mCambiarFrame = () => {
    setvKeyRenderApp(Date.now());
  };
  const mSetBusqueda = (vBusquedaNew, vTitulo) => {
    setvKeyCourses(Date.now());
    setvTitulo_Courses(vTitulo);
    setvBusqueda(vBusquedaNew);
  };

  const openNotification = (titulo, contenido, type = "info") => {
    /*
    api[type]({
      message: titulo,
      description: contenido,
      placement: "topLeft",
    });
    */
    switch (type) {
      case "error":
        api[type]({
          message: titulo,
          description: contenido,
          placement: "topLeft",
        });
        break;

      default:
        api[type]({
          message: titulo,
          description: contenido,
          placement: "topLeft",
        });
        break;
    }
  };

  const mErrorValidacionApis = () => {
    return <>
    
    <GlitchVideo/></>;
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Pagination: {
            colorPrimary: "rgba(0, 0, 0, 1)",
            colorPrimaryHover: "rgba(100, 100, 100, 1)",
            colorBgContainer: "rgba(255, 255, 255, 1)",
            colorText: "rgba(255, 255, 255, 1)",
            colorTextDisabled: "rgba(255, 255, 255, 0.4)",
            colorBgTextHover: "rgba(255, 255, 255, 0.5)",
          },
          Empty: {
            colorText: "rgba(255, 255, 255, 1)",
          },
        },
      }}
    >
      {contextHolder}
      {vIsValidandoApi1 || vIsValidandoApi2 ? (
        <Spin />
      ) : vIsErrorApi1 || vIsErrorApi2 ? (
        mErrorValidacionApis()
      ) : (
        <>{localStorage.removeItem
          ('retryCount')}
          <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                key={Date.now()}
                setvFrame={mCambiarFrame}
                mSetBusqueda={mSetBusqueda}
                setvCourse={setvCourse}
              />
            }
          />
          <Route
            path="/alumno"
            element={
              <Alumno key={Date.now()} openNotification={openNotification} />
            }
          />
          <Route
            path="/profesor"
            element={
              <Profesor key={Date.now()} openNotification={openNotification} />
            }
          />
          <Route
            path="/cursos"
            element={
              <Courses
                openNotification={openNotification}
                key={Date.now()}
                mSetBusqueda={mSetBusqueda}
                vTitulo={vTitulo_Courses}
                setvFrame={mCambiarFrame}
                setvCourse={setvCourse}
              />
            }
          />
          <Route
            path="/cursos/c/:id?"
            element={
              <Courses
                openNotification={openNotification}
                key={Date.now()}
                mSetBusqueda={mSetBusqueda}
                vTitulo={vTitulo_Courses}
                setvFrame={mCambiarFrame}
                setvCourse={setvCourse}
              />
            }
          />
          <Route
            path="/curso/:id?"
            element={
              <PublicCourseNotPurchased
                openNotification={openNotification}
                key={vKeyRenderApp}
                setvFrame={mCambiarFrame}
                vCourse={vCourse}
              />
            }
          />
          <Route
            path="/nosotros"
            element={
              <AboutUs
                openNotification={openNotification}
                key={Date.now()}
                setvFrame={mCambiarFrame}
              />
            }
          />
        </Routes>
      </Router></>
      )}
    </ConfigProvider>
  );
}

export default App;
