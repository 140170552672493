import React, { useState } from "react";

export default function InfoGeneral(props) {
  const { BotonesCurso, nombreCurso, vCurso, windowSize } = props;

  const mRenderAprenderas = (_) =>
    vCurso?.objetivos_especificos.split("\n-").map((vObjetivo) => (
      <div class="div-cnc-puntos-aprenderas">
        <img
          src="../images/Vectors-Wrapper_23.svg"
          loading="lazy"
          width="11.25"
          height="15"
          alt=""
          class="icon-cnc-aprenderas"
        />
        <div class="text-cnc-aprenderas">{vObjetivo} </div>
      </div>
    ));
  const mRenderRequerimientos = (_) =>
    vCurso?.requisitos
      .split("\n-")
      .map((vObjetivo) => <li class="text-cnc-aprenderas">{vObjetivo} </li>);

  const mRenderContenedor = () => {
    return (
      <div class="container-curso-profesor">
        <div class="text-133">Información del curso</div>
        <div class="container-info-general-body">
          <div class="div-contenido">
            <div class="section-info-general-aprenderas">
              <div class="div-cnc-subtitulo">
                <div class="title-info-general-section">Lo que aprenderás </div>
              </div>
              <div class="div-info-general-puntos-aprendizaje">
                {mRenderAprenderas()}
              </div>
            </div>
            <div class="requerimientos">
              <div class="title-info-general-section">Requerimientos</div>
              <ul class="div-block-26">{mRenderRequerimientos()}</ul>
            </div>
          </div>
          <div class="separador-info-general"></div>
          <div class="div-contenido">
            <div className="descripcion">
              <div className="title-info-general-section">Descripción</div>
              <div className="error-fd47e368-1448-67c0-bfda-a1db6f9e2066">
                <div className="error-fd47e368-1448-67c0-bfda-a1db6f9e2066 error-fd47e368-1448-67c0-bfda-a1db6f9e2066">
                  <span className="bienvenidos-al-curso-fundamentos-de-derivados-futuros-forwards-opciones-y-swaps-este-curso-es-perfecto-para-aquellos-que-desean-adquirir-habilidades-de-derivados-financieros-aprender-s-a-navegar-los-mercados-regulados-y-no-regulados-y-a-utilizar-los-forward-para-planificar-tu-estrategia-de-inversi-n-a-largo-plazo-profundizaremos-en-los-futuros-financieros-y-entender-s-c-mo-funcionan-los-swaps-y-c-mo-pueden-ayudarte-a-diversificar-tus-inversiones-y-reducir-el-riesgo-0">
                    ¡Bienvenidos al curso {vCurso?.nombre}! <br />
                    <br />‍
                  </span>
                  <span className="bienvenidos-al-curso-fundamentos-de-derivados-futuros-forwards-opciones-y-swaps-este-curso-es-perfecto-para-aquellos-que-desean-adquirir-habilidades-de-derivados-financieros-aprender-s-a-navegar-los-mercados-regulados-y-no-regulados-y-a-utilizar-los-forward-para-planificar-tu-estrategia-de-inversi-n-a-largo-plazo-profundizaremos-en-los-futuros-financieros-y-entender-s-c-mo-funcionan-los-swaps-y-c-mo-pueden-ayudarte-a-diversificar-tus-inversiones-y-reducir-el-riesgo-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: vCurso?.objetivo_general.replace(
                          /\n/g,
                          "<br/>"
                        ),
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      class="conteniido-curso-profesor"
      style={{ height: `${windowSize.height - 80}px` }}
    >
      <div class="text-title-curso">{nombreCurso}</div>
      <BotonesCurso {...props} />
      {mRenderContenedor()}
    </div>
  );
}
