import React, { useEffect, useState } from "react";
import * as Metodos from "../../utils/Global/Metodos";
import * as Gets from "../../utils/Rest apis/Gets";
import "./style.css";
import { Empty, Spin } from "antd";
import Certificado from "../modals/certificado";

export default function CursoInfoRecursos(props) {
  const {
    vIdCurso,
    vCurso,
    vProfesor,
    vIsLoadingRecursos,
    vRecursos,
    vDataLeccion,
    mRenderTemario,
    mRenderForo,
  } = props;
  const [vSectionSelected, setVSectionSelected] = useState("info");
  const [vIsDownloading, setVIsDownloading] = useState(false);


  const mRenderAprenderas = (_) =>
    vCurso?.objetivos_especificos
      .split(/-|\n-/)
      .filter(Boolean)
      .map((vObjetivo,index) => (
        <div key={index+Date.now()} className="curso-alumno-alumno-check">
          <div data-figma-id="I1028:3613;498:2151" class="check">
            <img src="../images/check.svg" data-figma-id="485:2088" />
          </div>
          <div className="no-comprado-alumno-texto-check-1 truncated-text">{vObjetivo} </div>
        </div>
      ));

  const mDescargarRecurso = (file, vNombreArchvio) => {
    setVIsDownloading(true);
    Gets.mDescargarRecursoA(file, vIdCurso)
      .then((response) => response.blob())
      .then((blob) => {
        // Crea un enlace y lo descarga automáticamente
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // Especifica el nombre del archivo
        a.download = vNombreArchvio;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => console.error("Error al descargar el archivo:", err))
      .finally(() => {
        setVIsDownloading(false);
      });
  };

  const mRenderRecurso = (vRecurso) => {
    console.table(vRecurso);
    const vTectoBtn = vRecurso.nombre.replace(/[\n\t]/g, "").trim();
    switch (vRecurso.tipo) {
      case 0:
        const CBtnVideoRecurso = () => {
          return (
            <div
              onClick={() => {
                if (!vIsDownloading) {
                  mDescargarRecurso(
                    vRecurso.archivo.data.attributes.url,
                    vTectoBtn
                  );
                }
              }}
              className="curso-alumno-boton-video-2"
            >
              <img src="../images/videoo.svg" data-figma-id="710:3129" />

              <div
                data-figma-id="I827:3035;775:3358"
                class="curso-alumno-texto-video truncated-text1"
              >
                {vTectoBtn}
              </div>

              {vIsDownloading ? (
                <Spin />
              ) : (
                <img
                  src="../images/dowload-azul.svg"
                  data-figma-id="825:2970"
                />
              )}
            </div>
          );
        };
        return <CBtnVideoRecurso />;
      case 1:
        const CBtnInfografiaRecurso = () => {
          return (
            <div
              onClick={() => {
                if (!vIsDownloading) {
                  mDescargarRecurso(
                    vRecurso.archivo.data.attributes.url,
                    vTectoBtn
                  );
                }
              }}
              className="curso-alumno-boton-infografia-2"
            >
              <img src="../images/infografia.svg" data-figma-id="710:3179" />
              <div
                data-figma-id="I827:3041;775:3362"
                class="curso-alumno-texto-infografia"
              >
                {vTectoBtn}
              </div>
              {vIsDownloading ? (
                <Spin />
              ) : (
                <img
                  src="../images/dowload-rosa.svg"
                  data-figma-id="826:3000"
                />
              )}
            </div>
          );
        };
        return <CBtnInfografiaRecurso />;
      case 2:
        const CBtnPrecentacionRecurso = () => {
          return (
            <div
              onClick={() => {
                if (!vIsDownloading) {
                  mDescargarRecurso(
                    vRecurso.archivo.data.attributes.url,
                    vTectoBtn
                  );
                }
              }}
              className="curso-alumno-boton-presentacion-2"
            >
              <img src="../images/presentacion.svg" data-figma-id="710:3325" />
              <div
                data-figma-id="I827:3047;775:3366"
                class="curso-alumno-texto-presentacion"
              >
                {vTectoBtn}
              </div>
              {vIsDownloading ? (
                <Spin />
              ) : (
                <img
                  src="../images/dowload-verde.svg"
                  data-figma-id="826:3007"
                />
              )}
            </div>
          );
        };
        return <CBtnPrecentacionRecurso />;

      case 3:
        const CBtnExcelRecurso = () => {
          return (
            <div
              onClick={() => {
                if (!vIsDownloading) {
                  mDescargarRecurso(
                    vRecurso.archivo.data.attributes.url,
                    vTectoBtn
                  );
                }
              }}
              className="curso-alumno-boton-plantilla-2"
            >
              <img
                src="../images/plantilla-excel.svg"
                data-figma-id="710:3335"
              />
              <div
                data-figma-id="I827:3053;775:3370"
                class="curso-alumno-texto-plantilla"
              >
                {vTectoBtn}
              </div>

              {vIsDownloading ? (
                <Spin />
              ) : (
                <img
                  src="../images/dowload-turquesa.svg"
                  data-figma-id="826:3014"
                />
              )}
            </div>
          );
        };
        return <CBtnExcelRecurso />;

      default:
        break;
    }
  };

  const mRenderSection = () => {

    console.table(vDataLeccion)
    switch (vSectionSelected) {
      case "recursos":
        return (
          <>
            <div
              data-figma-id="782:2859"
              class="curso-alumno-recursos-glosario"
            >
              <div data-figma-id="775:3339" class="curso-alumno-texto-glosario">
                Glosario gráfico
              </div>
              <div
                data-figma-id="775:3340"
                class="curso-alumno-recursos-botones-1"
              >
                <div data-figma-id="775:3341" class="curso-alumno-boton-video">
                  <img src="../images/videoo.svg" data-figma-id="710:3129" />
                  <div
                    data-figma-id="775:3343"
                    class="curso-alumno-texto-video"
                  >
                    Video
                  </div>
                </div>
                <div
                  data-figma-id="775:3344"
                  class="curso-alumno-boton-infografia"
                >
                  <img
                    src="../images/infografia.svg"
                    data-figma-id="710:3179"
                  />
                  <div
                    data-figma-id="775:3346"
                    class="curso-alumno-texto-infografia"
                  >
                    Infografía
                  </div>
                </div>
                <div
                  data-figma-id="775:3347"
                  class="curso-alumno-boton-presentacion"
                >
                  <img
                    src="../images/presentacion.svg"
                    data-figma-id="710:3325"
                  />
                  <div
                    data-figma-id="775:3349"
                    class="curso-alumno-texto-presentacion"
                  >
                    Presentación General
                  </div>
                </div>
                <div
                  data-figma-id="775:3350"
                  class="curso-alumno-boton-plantilla"
                >
                  <img
                    src="../images/plantilla-excel.svg"
                    data-figma-id="710:3335"
                  />
                  <div
                    data-figma-id="775:3352"
                    class="curso-alumno-texto-plantilla"
                  >
                    Plantilla de Excel
                  </div>
                </div>
              </div>
            </div>
            <div className="curso-alumno-recursos1">
              <div
                data-figma-id="775:3354"
                class="curso-alumno-texto-recursos1"
              >
                Recursos
              </div>
              {vIsLoadingRecursos ? (
                <Spin />
              ) : (
                <div className="curso-alumno-recursos-botones-2">
                  {vRecursos.length > 0 ? (
                    vRecursos.map((vRecurso) => mRenderRecurso(vRecurso))
                  ) : (
                    <Empty description={<span>No existen recursos.</span>} />
                  )}
                </div>
              )}
            </div>
          </>
        );

      case "temario":
        return <div className="curso-alumno-tem-2">{mRenderTemario}</div>;

      case "foro":
        return <div className="curso-alumno-foro-1">{mRenderForo}</div>;
      default:
        return (
          <>
            <div className="curso-alumno-aprenderas-certificado">
              <div
                data-figma-id="779:3399"
                class="curso-alumno-lo-que-aprenderas"
              >
                <div data-figma-id="775:3010" class="curso-alumno-titulo-2">
                  <img src="../images/rocket.svg" data-figma-id="485:1912" />
                  <div
                    data-figma-id="775:3012"
                    class="curso-alumno-texto-titulo"
                  >
                    Lo que aprenderás
                  </div>
                </div>
                <div
                  data-figma-id="1028:3518"
                  class="curso-alumno-texto-info-lo-que-aprenderas"
                >
                  {mRenderAprenderas()}
                </div>
              </div>
              
              <Certificado {...props} id_curso={vIdCurso} ComponenteBoton={<ProgresoCertificado {...props} />} />
            </div>
            <div data-figma-id="1030:3636" class="curso-alumno-descripcion">
          <div data-figma-id="712:3100" class="curso-alumno-titulo-descripcion"><img src="../images/descripcion.svg" data-figma-id="640:6537"/>
            <div data-figma-id="712:3102" class="curso-alumno-texto-descripcion">Descripción</div>
          </div>
          <div data-figma-id="712:3103" class="error-04ead157-6964-f106-2034-64d1c7735092" 
          dangerouslySetInnerHTML={{
            __html: vCurso?.objetivo_general.replace(
              /\n/g,
              "<br/>"
            ),
          }}
          />
            </div>
            <div data-figma-id="843:2701" class="no-comprado-alumno-info-1">
              <div data-figma-id="602:2426" class="no-comprado-alumno-calificacion">
                <div data-figma-id="1:30" src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663ba7c7d634fa76d14027b1_star.svg" class="div-60"><img src="../images/star_1.svg" data-figma-id="I133:490;1:29"/></div>
                <div data-figma-id="602:2428" class="no-comprado-alumno-texto-calificacion">{vCurso?.calificacion}</div>
              </div>
              <div data-figma-id="602:2429" class="no-comprado-alumno-graduados">
                <div data-figma-id="4:4"><img src="../images/school.svg" data-figma-id="I602:2430;4:3"/></div>
                <div data-figma-id="602:2431" class="no-comprado-alumno-texto-graduados">{vCurso?.total_graduados} graduados</div>
              </div>
              <div data-figma-id="602:2432" class="no-comprado-alumno-idioma">
                <div data-figma-id="112:342" src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c1143426d44c43bf2_idioma.svg" class="div-63"><img src="../images/idioma.svg" data-figma-id="I165:2522;112:336"/></div>
                <div data-figma-id="602:2434" class="no-comprado-alumno-texto-idioma">{vCurso?.lenguaje}</div>
              </div>
            </div>
            <div data-figma-id="1030:3644" class="curso-alumno-requerimientos">
              <div data-figma-id="640:6527" class="curso-alumno-requerimientos-titulo">
                <div data-figma-id="499:2209"><img src="../images/requerimientos.svg" data-figma-id="I602:2472;499:2208"/></div>
                <div data-figma-id="640:6529" class="curso-alumno-titulo-requerimientos">Conocimientos / Requerimientos</div>
              </div>
                <div data-figma-id="615:5569" class="curso-alumno-texto-requerimiento"
                dangerouslySetInnerHTML={{
                  __html: vCurso?.requisitos.replace(/\n/g, "<br/>"),
                }}
                />
            </div>
            <div data-figma-id="1030:3645" class="curso-alumno-perfil-egreso">
              <div data-figma-id="640:6496" class="curso-alumno-perfil-egreso-titulo">
                <div data-figma-id="499:2191"><img src="../images/perfil-egreso.svg" data-figma-id="I602:2451;499:2190"/></div>
                <div data-figma-id="640:6498" class="curso-alumno-titulo-texto-perfil-egreso">Perfil de egreso</div>
              </div>
              <div data-figma-id="640:6499" class="curso-alumno-perfil-egreso-texto"
              
              dangerouslySetInnerHTML={{
                __html: vCurso?.perfil_graduacion.replace(/\n/g, "<br/>"),
              }}
              />
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div data-figma-id="1030:3646" class="curso-alumno-info-recursos">
        <div
          data-figma-id="726:2319"
          class="curso-alumno-info"
          style={
            vSectionSelected === "info"
              ? { borderBottom: "5px solid #fff" }
              : {}
          }
          onClick={() => setVSectionSelected("info")}
        >
          <div data-figma-id="726:2320" class="curso-alumno-info-texto">
            Información del curso
          </div>
        </div>
        <div
          data-figma-id="726:2321"
          class="curso-alumno-recursos"
          style={
            vSectionSelected === "recursos"
              ? { borderBottom: "5px solid #fff" }
              : {}
          }
          onClick={() => setVSectionSelected("recursos")}
        >
          <div data-figma-id="726:2322" class="curso-alumno-recursos-texto">
            Recursos
          </div>
        </div>
        <div
          data-figma-id="726:2594"
          class="curso-alumno-temario"
          style={
            vSectionSelected === "temario"
              ? { borderBottom: "5px solid #fff" }
              : {}
          }
          onClick={() => setVSectionSelected("temario")}
        >
          <div data-figma-id="726:2595" class="curso-alumno-temario-texto">
            Temario
          </div>
        </div>
        <div
          data-figma-id="726:2596"
          class="curso-alumno-foro"
          style={
            vSectionSelected === "foro"
              ? { borderBottom: "5px solid #fff" }
              : {}
          }
          onClick={() => setVSectionSelected("foro")}
        >
          <div data-figma-id="726:2597" class="curso-alumno-foro-texto">
            Foro
          </div>
        </div>
      </div>
      {mRenderSection()}
    </>
  );
}

function ProgresoCertificado(props){

  const {vDataLeccion,showModal}=props
  

  return vDataLeccion.isLeccionesTerminadas === true?<div data-figma-id="827:3127" class="tarjeta-certificado-obtenido" onClick={showModal}>
  <div data-figma-id="640:6459" class="tarjeta-certificado-contenido">
    <div data-figma-id="640:6460" class="tarjeta-certificado-icono"><img src="../images/certificado-3-verde.svg" data-figma-id="640:6472"/></div>
    <div data-figma-id="640:6462" class="tarjeta-certificado-titulos">
      <div data-figma-id="1082:4293" class="tarjeta-certificado-titulo-1">Certificado</div>
      <div data-figma-id="640:6464" class="tarjeta-certificado-titulo-2-obtenido">Ver certificado</div>
    </div>
  </div>
  <div data-figma-id="640:6475" class="tarjeta-certificado-barra-progreso-completado">
    <div data-figma-id="640:6476" class="tarjeta-certificado-progreso-completado" style={{width:`100%`}}></div>
  </div>
</div>:<div data-figma-id="779:3400" class="tarjeta-certificado-en-progreso">
  <div data-figma-id="775:3023" class="tarjeta-certificado-contenido">
    <div data-figma-id="775:3024" class="tarjeta-certificado-icono"><img src="../images/certificado-3.svg" data-figma-id="640:6417"/></div>
    <div data-figma-id="775:3026" class="tarjeta-certificado-titulos">
      <div data-figma-id="775:3027" class="tarjeta-certificado-titulo-1">Certificado</div>
      <div data-figma-id="775:3028" class="tarjeta-certificado-titulo-2">En Progreso</div>
    </div>
  </div>
  <div data-figma-id="775:3029" class="tarjeta-certificado-barra-progreso">
    <div data-figma-id="775:3030" class="tarjeta-certificado-progreso" style={{width:`${(vDataLeccion.numLeccionCurrent/vDataLeccion.totalLecciones)*100}%`}}></div>
  </div>
</div>
}

