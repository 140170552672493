import React, { useState, useEffect } from "react";
import {
  Modal,
  ConfigProvider,
  message,
  Spin,
} from "antd";

import * as Gets from "../../utils/Rest apis/Gets";
import * as Variables from "../../utils/Global/Variables";

export default function Certificado(props) {

  const {openNotification,ComponenteBoton=null,id_curso}=props
  
  const [vIsDisable, setvIsDisable] = useState(false);
  const [messageApiError, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vInfoCertificado, setvInfoCertificado] = useState({});
  const [vLoadingInfo, setvLoadingInfo] = useState(true);

  useEffect(() => {}, []);

  const showModal = () => {
    setIsModalOpen(true);
    mObtenerCertificadoCurso()
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const mObtenerCertificadoCurso=()=>{
    setvLoadingInfo(true)
    Gets.mGetCertificadoCurso(id_curso)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Respuesta de red no fue exitosa");
      }
      return response.json();
    })
    .then((result) => {
      if (
        result?.Error==="No se encontró certificado"
      ) {
        setIsModalOpen(false);
        openNotification("Error de certificado", "Actualmente no cuenta con el certificado", "error")
      } else {
        setvInfoCertificado(result)
      }
      setvLoadingInfo(false)
    })
      .catch();
  }

  var vBtn=null

  if(ComponenteBoton !== null){
    vBtn=React.cloneElement(ComponenteBoton, { showModal });
  }


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: "20px",
            contentBg: "#ffffff00",
            paddingContentHorizontalLG: "0px",
          },
        },
      }}
    >
      {ComponenteBoton!==null?vBtn:<div
        data-figma-id="217:1187"
        class="tarjeta-4-curso-certificado"
        onClick={showModal}
      >
        <div data-figma-id="217:1316" src="">
          <img
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662beb3768064e1c700690ae_certificado.svg"
            data-figma-id="I464:2070;217:1315"
          />
        </div>
        <div class="tarjeta-4-curso-texto-certificado" data-figma-id="217:1189">
          Ver certificado
        </div>
      </div>}
      
      {contextHolder}
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closeIcon={null}
        centered={true}
        destroyOnClose={true}
      >


        {vLoadingInfo?<Spin/>:<div data-figma-id="479:5462" class="certificado"><img
            src={`${Variables.V_URL_API}${vInfoCertificado?.url_badge_img}`}
            data-figma-id="432:9204"
            width={191}
          />
          <div data-figma-id="432:9195" class="certificado-contenido">
            <div data-figma-id="432:9193" class="certificado-titulos">
              <div class="certificado-titulo" data-figma-id="432:9182">
                CERTIFICADO
              </div>
              <div class="certificado-subtitulo" data-figma-id="432:9183">
                {vInfoCertificado?.nombre_curso}
              </div>
            </div>
            <div data-figma-id="432:9194" class="certificado-info">
              <div class="certificado-info-1" data-figma-id="432:9184">
                No. certificado: {vInfoCertificado?.certificado_id}
              </div>
              <div class="certificado-info-2" data-figma-id="432:9185">
                Obtenido el: {vInfoCertificado?.fecha_larga}
              </div>
              <a class="certificado-info-3" data-figma-id="432:9186" href={vInfoCertificado?.url} target="_blank" >
                Ver certificado
              </a>
            </div>
            <div
              data-figma-id="432:9196"
              class="certificado-boton"
              onClick={handleCancelModal}
            >
              <div data-figma-id="1:445" class="boton-1">
                <div class="boton-1-texto" data-figma-id="I432:9191;1:444">
                  Cerrar
                </div>
              </div>
            </div>
          </div></div>}
          
        
      </Modal>
    </ConfigProvider>
  );
}
