import React, { useEffect, useRef } from "react";
import "./style.css";
import { useState } from "react";

import { Spin, Tooltip } from "antd";
import * as Metodos from "../../utils/Global/Metodos";
import { Link, useNavigate } from "react-router-dom";

export default function Temario(props) {
  const [vSeleccion, setvSeleccion] = useState("temario");
  const {
    mRenderTemario,
    mRenderForo,
  } = props;
  

  const mRenderTemario1 = () => {
    return <div className="curso-alumno-frame-modulos">{mRenderTemario}</div>;
  };


  const mRenderForo1 = () => {return <div className="curso-alumno-foro-contenido-principal">{mRenderForo}</div>};
  return (
    <div className="curso-alumno-contenido-2">
      <div className="curso-alumno-botones-temario-foro">
        <div
          onClick={() => setvSeleccion("temario")}
          className={
            vSeleccion === "temario"
              ? "curso-alumno-boton-temario  "
              : "curso-alumno-boton-foro"
          }
        >
          <img src="../images/book-boton.svg" data-figma-id="768:3385" />
          <div data-figma-id="712:3149" class="curso-alumno-texto-temario">
            Temario
          </div>
        </div>
        <div
          onClick={() => setvSeleccion("foro")}
          className={
            vSeleccion === "foro"
              ? "curso-alumno-boton-temario  "
              : "curso-alumno-boton-foro"
          }
        >
          <img src="../images/coment-pregunta.svg" data-figma-id="616:5623" />
          <div data-figma-id="712:3152" class="curso-alumno-texto-foro">
            Foro
          </div>
        </div>
      </div>

      <div className="curso-alumno-tem-1">
        {vSeleccion === "temario"
          ? mRenderTemario1()
          : mRenderForo1()}
      </div>
    </div>
  );
}
