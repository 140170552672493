import React, { useState, useEffect } from "react";
import { Button, Modal, Skeleton, ConfigProvider, message } from "antd";
import "./style.css";
import Login from "./login";
import Singup from "./singup";
import Btn from "../../botones/Boton";
import * as Variables from "../../../utils/Global/Variables";

import * as Gets from "./../../../utils/Rest apis/Gets";

export default function MainLogin(props) {
  const { setvFrame } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vQuote, setvQuote] = useState({ quote: "", author: "" });
  const [vIsCargandoQuote, setvIsCargandoQuote] = useState(true);
  const [vTipo, setVTipo] = useState(Variables.vFrames.modal_login);
  const [messageApi, contextHolder] = message.useMessage();

  const showModal = (vTipo) => {
    setVTipo(vTipo);
    setIsModalOpen(true);
  };
  const loadQuote = (statusModal) => {
    setvIsCargandoQuote(true);
    if (statusModal) {
      Gets.mGetQuote(setvQuote, setvIsCargandoQuote);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const mCambiarTipo = (vTipo) => {
    loadQuote(true);
    setVTipo(vTipo);
  };

  const mMostrarTipo = () => {
    switch (vTipo) {
      case Variables.vFrames.modal_login:
        return (
          <Login
            setvFrame={setvFrame}
            messageApi={messageApi}
            handleCancel={handleCancel}
            mCambiarTipo={() => mCambiarTipo(Variables.vFrames.modal_singup)}
          />
        );
      case Variables.vFrames.modal_singup:
        return (
          <Singup
            handleCancel={handleCancel}
            mCambiarTipo={() => mCambiarTipo(Variables.vFrames.modal_login)}
            messageApi={messageApi}
          />
        );

      default:
        break;
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Skeleton: {
            gradientFromColor: "#fff6",
          },
          Modal: {
            borderRadiusLG: "20px",
            contentBg: "#ffffff00",
            paddingContentHorizontalLG: "0px",
          },
        },
      }}
    >
      <>
        {contextHolder}
        <Btn
          vClass="btn1"
          vClassText="btn1-text"
          vText="Iniciar sesión"
          vActionBtn={() => showModal(Variables.vFrames.modal_login)}
        />
        <Btn
          vClass="btn2"
          vClassText="btn2-text"
          vText="Solicitar cuenta"
          vActionBtn={() => showModal(Variables.vFrames.modal_singup)}
        />
      </>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        centered={true}
        className={
          vTipo === Variables.vFrames.modal_login ? "md-login" : "md-signup"
        }
        destroyOnClose={true}
        afterOpenChange={loadQuote}
      >
        <div
          className={
            vTipo === Variables.vFrames.modal_login ? "md-login" : "md-signup"
          }
        >
          <div
            className={
              vTipo === Variables.vFrames.modal_login
                ? "div-md-login-izq"
                : "div-md-signup-izq"
            }
          >
            {mMostrarTipo()}
          </div>
          <>
            <div
              className={
                vTipo === Variables.vFrames.modal_login
                  ? "div-md-login-der"
                  : "div-md-signup-der"
              }
            >
              <div className="cita-md">
                {!vIsCargandoQuote && (
                  <>
                    <p className="text-cita-md">{vQuote.quote}</p>
                    <p className="autor-cita-md">- {vQuote.author}</p>
                  </>
                )}
                {vIsCargandoQuote && <Skeleton active />}
              </div>
            </div>
          </>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
