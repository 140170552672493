import React from "react";
import Header from "../components/header/Header";
import Particles from "../components/particles/particles";
import * as Variables from "../utils/Global/Variables";
import Contactanos from "../components/modals/contactanos";

import { WhatsAppOutlined } from "@ant-design/icons";
import { message } from "antd";
import Footer from "../components/footer_publico/footer";

export default function AboutUs(props) {
  const { setvFrame } = props;
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div class="section-about-us">
      {contextHolder}
      <Particles className="section-header-nosotros">
        <Header setvFrame={setvFrame} vSelected={Variables.vFrames.about_us} />
        <div class="frase-2">
          <div class="text-31">
            &quot;Si un hombre vacía su monedero en su cabeza, nadie se lo podrá
            quitar.{" "}
            <strong>
              La inversión en el conocimiento siempre paga el mejor interés.
            </strong>
            &quot;
          </div>
          <div class="text-31">Benjamin Franklin</div>
        </div>
      </Particles>
      <div class="section-group">
        <div class="frame-21">
          <div class="text-32">¿Quiénes somos?</div>
          <wrapper class="vectors-wrapper-46"></wrapper>
          <div class="frame-22">
            <div class="text-33">
              En MHM Group somos líderes en Capacitación Financiera
              Especializada, la innovación, la especialización, la calidad y el
              compromiso en todo lo que hacemos son nuestros pilares
              fundamentales.
            </div>
            <wrapper class="vectors-wrapper-45"></wrapper>
            <div class="error-219fdc8b-a845-1607-b55a-8184c0292da2">
              e-lisa es una Plataforma 100% propietaria y hecha a la medida para
              ti y del Sector Financiero,{" "}
              <b>
                es un sistema innovador que se adapta a tu ritmo de vida
                personal y profesional.
              </b>
              <br />
              Porque los Mexicanos somos únicos, somos especiales.
            </div>
          </div>
        </div>
        <img
          src={`${Variables.V_URL_API}/uploads/Pilar_sociedad_f0a150fc68.png`}
          loading="lazy"
          alt=""
          class="div-pilar-sociedad"
        />
        <div class="vemos-nosotros-mismos">
          <div class="frame-23">
            <div class="frame-24">
              <img
                src={`${window.location.origin}/images/camaraderia-1.png`}
                loading="lazy"
                width="135"
                height="90"
                alt=""
                class="camaraderia-1"
              />
              <div class="text-35">
                Somos los aliados estratégicos de nuestros clientes en
                capacitación y formación continua.
              </div>
            </div>
            <div class="frame-25">
              <img
                src={`${window.location.origin}/images/idea-1.png`}
                loading="lazy"
                width="117"
                height="117"
                alt=""
                class="camaraderia-1"
              />
              <div class="text-35">
                Somos especialistas en dar solución a necesidades educativas
                complejas en diversos temas y proyectos.
              </div>
            </div>
          </div>
          <div class="en-palabras-simples-somos-los-mejores-en-lo-que-hacemos">
            <div class="en-palabras-simples-somos-los-mejores-en-lo-que-hacemos en-palabras-simples-somos-los-mejores-en-lo-que-hacemos">
              <span class="en-palabras-simples-somos-los-mejores-en-lo-que-hacemos-0">
                En palabras simples,
              </span>
              <span class="en-palabras-simples-somos-los-mejores-en-lo-que-hacemos-1">
                {" "}
                somos los mejores en lo que hacemos
              </span>
            </div>
          </div>
        </div>
        <div class="section-objetivo">
          <img
            src={`${Variables.V_URL_API}/uploads/Razon_de_ser_4b6f2152ed.png`}
            loading="lazy"
            alt=""
            class="image-10"
          />
          <img
            src={`${Variables.V_URL_API}/uploads/nosotros_texto_a59a114b08.png`}
            loading="lazy"
            alt=""
            class="image-9"
          />
        </div>
        <div class="frame-29">
          <div class="text-37">
            Nuestro equipo de expertos expositores crece día a día y junto con
            ellos nos sentimos orgullosos de apoyar tu desarrollo profesional.
            <br />
            Te agradecemos deposites en nosotros tu confianza en la gran
            responsabilidad que es enseñar.
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
