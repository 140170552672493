import * as Variables from "./Variables";

export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

export const mTimeSince = (date) => {
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 0) {
    return `Hace un momento`;
  } else if (seconds < 60) {
    return `${seconds} segundos`;
  } else if (minutes < 60) {
    return `${minutes} minutos`;
  } else if (hours < 24) {
    return `${hours} horas`;
  } else if (days < 7) {
    return `${days} días`;
  } else if (weeks < 4) {
    return `${weeks} semanas`;
  } else if (months < 12) {
    return `${months} meses`;
  } else {
    return `${years} años`;
  }
};

export const calcularPorcentaje = (numerador, denominador) => {
  const resultado = (numerador / denominador) * 100;
  return resultado.toFixed(2); // Redondear a dos decimales
};

export const formatNumber = (num) => {
  var formatter = Intl.NumberFormat("en", {
    notation: "compact",
    maximumFractionDigits: 2,
  });
  return formatter.format(num);
};

export const formatNumberComillas = (num) => {
  var formatter = Intl.NumberFormat("en", {
    notation: "standard",
    maximumFractionDigits: 2,
  });
  return formatter.format(num);
};

export const convertirYDenominar = (segundos) => {
  // Convertir a horas completas
  const horas = Math.floor(segundos / 3600);
  // Convertir el resto a minutos completos
  const minutos = Math.floor((segundos % 3600) / 60);
  // Convertir el resto a segundos completos
  const segundosRestantes = Math.floor(segundos % 60);

  let resultado = [];
  if (horas > 0) {
    resultado.push(horas);
    resultado.push(horas > 1 || horas === 0 ? "horas" : "hora");
  } else if (minutos > 0) {
    resultado.push(minutos);
    resultado.push(minutos > 1 || minutos === 0 ? "minutos" : "minuto");
  } else {
    resultado.push(segundosRestantes);
    resultado.push(
      segundosRestantes > 1 || segundosRestantes === 0 ? "segundos" : "segundo"
    );
  }

  return resultado;
};

export const convertirYDenominarHoras = (horas_restantes) => {
  // Convertir todo a horas incluyendo fracciones decimales
  const horas = horas_restantes;
  let resultado;

  if (horas === 1) {
    resultado = '1 hr rest.';
  } else if (horas === 0) {
    resultado = '0 hrs rest.';
  } else if (Number.isInteger(horas)) {
    resultado = `${horas} hrs rest.`;
  } else {
    resultado = `${horas} hrs rest.`;
  }

  return resultado;
};


export async function testDownloadSpeed(fileUrl, fileSizeInBytes) {
  const startTime = new Date().getTime();
  const response = await fetch(fileUrl);
  await response.blob(); // Asegurarse de que la respuesta completa ha sido descargada
  const endTime = new Date().getTime();

  const duration = (endTime - startTime) / 1000; // Duración en segundos
  const bitsLoaded = fileSizeInBytes * 8;
  const speedBps = bitsLoaded / duration;
  const speedMbps = speedBps / 1024 / 1024;

  return speedMbps;
}

export function calculateImageQuality(speedMbps) {
  // Ajusta estos rangos para asignar una mayor calidad de imagen a velocidades de conexión más altas
  if (speedMbps > 30) {
    return 100; // Calidad máxima para velocidades muy altas (por ejemplo, > 30 Mbps)
  } else if (speedMbps > 15) {
    return 90; // Calidad casi máxima para velocidades altas (por ejemplo, 15-30 Mbps)
  } else if (speedMbps > 10) {
    return 80; // Calidad alta (por ejemplo, 10-15 Mbps)
  } else if (speedMbps > 5) {
    return 70; // Calidad media-alta (por ejemplo, 5-10 Mbps)
  } else if (speedMbps > 3) {
    return 60; // Calidad media (por ejemplo, 3-5 Mbps)
  } else if (speedMbps > 1) {
    return 50; // Calidad media-baja (por ejemplo, 1-3 Mbps)
  } else {
    return 30; // Calidad baja para velocidades menores de 1 Mbps
  }
}

export function ocultarCorreo(email) {
  return email.replace(/^(.+)@(.+)$/, function (match, p1, p2) {
    // Oculta parte de la parte local
    let parteLocalOculta = "*".repeat(p1.length - 2) + p1.substr(-2);
    // Oculta parte del dominio
    let lastDotIndex = p2.lastIndexOf(".");
    let dominioOculto = "*".repeat(lastDotIndex) + p2.substr(lastDotIndex);
    return parteLocalOculta + "@" + dominioOculto;
  });
}
