import * as Variables from "../Global/Variables";

export async function mUserMe(vDatos) {
  var formdata = new FormData();
  formdata.append("nombre", vDatos["nombre"]);
  formdata.append("apellidos", vDatos["apellidos"]);
  formdata.append("color", vDatos["color"]);
  formdata.append("telefono", vDatos["telefono"]);

  return await fetch(Variables.V_URL_API + "/api2/perfil", {
    mode: "cors",
    method: "PUT",
    body: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
export async function mUserMeDetallesPerfil(vDetallesPerfil) {
  var formdata = new FormData();
  formdata.append("detalles_perfil", JSON.stringify(vDetallesPerfil));

  return await fetch(Variables.V_URL_API + "/api2/detalles-perfil", {
    mode: "cors",
    method: "PUT",
    body: formdata,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
  });
}
