import React, { useEffect, useState } from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import * as Gets from "../../utils/Rest apis/Gets";
import { Spin } from "antd";

export default function Footer(props) {
  const [vInfoContacto, setvInfoContacto] = useState({});
  const [vLoadingInfo, setvLoadingInfo] = useState(true);

  useEffect(() => {
    Gets.mGetContactoInfo()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {
        setvInfoContacto(response.data.attributes);
      })
      .catch((error) => {
        ("Error");
      })
      .finally(() => setvLoadingInfo(false));
  }, []);

  return (
    <section key={"footer-home"} className="footer">
      {!vLoadingInfo ? (
        <>
          <div class="section-text-footer1">
            ¿No encuentras el tema que buscabas?
          </div>
          <div class="section-text-footer">
            Contáctanos y lo desarrollamos para ti.
          </div>
          <div class="sect-footer-datos">
            <div class="contacto-md">
              {" "}
              <WhatsAppOutlined style={{ fontSize: "30px", color: "#fff" }} />
              <div
                class="text-contacto-md-footer"
                dangerouslySetInnerHTML={{
                  __html: vInfoContacto.telefono.replace(/\n/g, "<br/>"),
                }}
              />
            </div>
            <div class="contacto-md">
              <img
                src={`${window.location.origin}/images/Vectors-Wrapper_38.svg`}
                loading="lazy"
                width="30"
                height="30"
                alt=""
                class="icon-contacto-md"
              />
              <div class="text-contacto-md-footer">{vInfoContacto.correo}</div>
            </div>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </section>
  );
}
