import React, { useEffect, useState } from "react";

import APregunta from "./APregunta";
import * as Gets from "../../utils/Rest apis/Gets";
import { useNavigate } from "react-router-dom";
import Certificado from "../modals/certificado";

export default function AEvaluacion(props) {
  const {
    vIdCurso,
    vEvaluacion,
    setVIsVisibleBtnSiguiente,
    vDataLeccion,
    setvIsLoadingEvaluacion,
    sendInfoByEvaluacion,
  } = props;
  const [vNumFrame, setvNumFrame] = useState(
    vDataLeccion.isContinuar || vDataLeccion.isLeccionesTerminadas ? 2 : 0
  );

  const [startTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(null);
  const [vRespuestasEvaluacion, setvRespuestasEvaluacion] = useState([]);
  const [vCalificacion, setvCalificacion] = useState(
    vDataLeccion.isContinuar || vDataLeccion.isLeccionesTerminadas
      ? vEvaluacion?.resultados
      : {}
  );

  const [vIsCalificando, setVIsCalificando] = useState(
    vDataLeccion.isContinuar || vDataLeccion.isLeccionesTerminadas
      ? false
      : true
  );
  const [vIsError, setVIsError] = useState(false);

  const navigate = useNavigate();

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let formattedTime = "";
    if (hours > 0) {
      formattedTime += String(hours).padStart(2, "0") + ":";
    }
    formattedTime += String(minutes).padStart(2, "0") + ":";
    formattedTime += String(seconds).padStart(2, "0");

    return formattedTime;
  };

  const handleButtonClick = () => {
    setElapsedTime(formatTime(Date.now() - startTime));
    setvNumFrame(vNumFrame + 1);
    mEnviarRespuestas();
  };

  const mEnviarRespuestas = () => {
    setVIsError(false);
    setVIsCalificando(true);
    setvIsLoadingEvaluacion(true);
    Gets.mGetEnviarRespuestasEvaluacion(
      vDataLeccion.idLeccionActual,
      vIdCurso,
      vRespuestasEvaluacion
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        if (result?.Error) {
          setVIsError(true);
        }
        if (result?.Correcto) {
          setvCalificacion(result);
          setVIsVisibleBtnSiguiente(false);
        }
      })
      .catch(() => {
        setVIsError(true);
      })
      .finally(() => {
        sendInfoByEvaluacion();
        setVIsCalificando(false);
        setvIsLoadingEvaluacion(false);
      });
  };

  const mRenderEvaluacion = () => {
    switch (vNumFrame) {
      case 0:
        return (
          <APregunta
            vEvaluacion={vEvaluacion}
            setvNumFrame={setvNumFrame}
            setvRespuestasEvaluacion={setvRespuestasEvaluacion}
            vRespuestas={vRespuestasEvaluacion}
          />
        );
      case 1:
        return (
          <div class="curso-alumno-video">
            <div class="cuestionario-contenido">
              <div class="cuestionario-info">
                <div class="text-block-5">¿Listo?</div>
                <div class="text-block-6">
                  Una vez que des click en <strong>Finalizar</strong>, no podrás
                  cambiar tus respuestas.
                </div>
                <div class="div-block-27">
                  <div
                    data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
                    class="btn2"
                    onClick={() => setvNumFrame(vNumFrame - 1)}
                  >
                    <div class="btn2-text">Regresar</div>
                  </div>
                  <figure
                    data-w-id="6994115d-5798-f44f-1625-f1b84cb7dcbd"
                    class="btn1"
                    onClick={handleButtonClick}
                  >
                    <div class="btn1-text">Finalizar</div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div class="curso-alumno-video">
            <div class="cuestionario-contenido">
              <div class="cuestionario-info">
                <div class="text-block-5">
                  {vDataLeccion.isContinuar
                    ? "¡Enhorabuena!"
                    : vIsCalificando
                    ? "Se ha enviado tu examen"
                    : vIsError
                    ? "Error!!"
                    : "¡Enhorabuena!"}
                </div>
                <div class="text-block-6">
                  {vDataLeccion.isContinuar ||
                  vDataLeccion.isLeccionesTerminadas
                    ? "Ya habías contestado esta evaluación"
                    : vIsCalificando
                    ? "Estamos calificando tu examen, espera un momento."
                    : vIsError
                    ? "Error al enviar la información, vuelve a intentar"
                    : "Tus resultados se muestran a continuación!!!"}
                </div>
                <div class="div-block-28">
                  {!vIsCalificando && !vIsError && (
                    <>
                      <div class="cuestionario-final-caja">
                        <div class="cuestionario-caja-text">Aciertos</div>
                        <div class="cuestionario-caja-text-num">
                          {vCalificacion.aciertos}/
                          {vEvaluacion.meta.pagination.total}
                        </div>
                      </div>
                      <div class="cuestionario-final-caja">
                        <div class="cuestionario-caja-text">Calificación</div>
                        <div class="cuestionario-caja-text-num">
                          {parseFloat(vCalificacion.Calificacion.toFixed(2))}
                        </div>
                      </div>
                    </>
                  )}
                  {!vDataLeccion.isContinuar &&
                    !vDataLeccion.isLeccionesTerminadas && (
                      <div class="cuestionario-final-caja">
                        <div class="cuestionario-caja-text">Tiempo</div>
                        <div class="cuestionario-caja-text-num">
                          {elapsedTime}
                        </div>
                      </div>
                    )}
                </div>
                {vIsError ? (
                  <div class="div-block-27">
                    <figure
                      data-w-id="6994115d-5798-f44f-1625-f1b84cb7dcbd"
                      class="btn1"
                      onClick={mEnviarRespuestas}
                    >
                      <div class="btn1-text">Enviar de nuevo</div>
                    </figure>
                  </div>
                ) : !vIsCalificando ? (
                  !vDataLeccion.isLeccionesTerminadas &&
                  vDataLeccion.idLeccionSiguiente !== null ? (
                    <div class="div-block-27">
                      <figure
                        data-w-id="6994115d-5798-f44f-1625-f1b84cb7dcbd"
                        class="btn1"
                        onClick={() => {
                          navigate(`/alumno?tipo=mio&cId=${vIdCurso}`);
                          window.location.reload();
                        }}
                      >
                        <div class="btn1-text">
                          {vDataLeccion.isContinuar
                            ? `Continuar con la lección ${vDataLeccion.numLeccionSiguiente}`
                            : "Siguiente lección"}
                        </div>
                      </figure>
                    </div>
                  ) : (
                    <div data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce" class="btn2" style="background-color: rgba(0, 0, 0, 0); border-color: rgb(255, 255, 255); color: rgb(255, 255, 255);"
                    onClick={() => {
                      navigate(`/alumno?tipo=mio`);
                      window.location.reload();
                    }}
                    >
                    <div class="btn2-text">Salir del curso</div>
                  </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return mRenderEvaluacion();
}

function BotonCrearCertificado(props) {

  const {showModal}=props

  return (
    <div class="div-block-27">
      <figure
        data-w-id="6994115d-5798-f44f-1625-f1b84cb7dcbd"
        class="btn1"
        onClick={showModal}
      >
        <div class="btn1-text">Crear certificado</div>
      </figure>
    </div>
  );
}
