import { ConfigProvider, Empty, Progress, Spin } from "antd";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import * as Gets from "../../utils/Rest apis/Gets";
import * as Metodos from "../../utils/Global/Metodos";
import * as Variables from "../../utils/Global/Variables";

import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Home(props) {
  const {
    windowSize,
    setVHorasStudio,
    vJWT_Access,
    vCalidadImagen,
    vIsLoadingDetallesPerfil,
    vUserMeDetallesPerfil,
    mRenderHeader,
  } = props;
  const navigate = useNavigate();

  const [vTiempoEstudio, setvTiempoEstudio] = useState({});
  const [vSummary, setvSummary] = useState({});
  const [vIsLoadingSummary, setvIsLoadingSummary] = useState(true);

  const [vCursosVencerA, setvCursosVencerA] = useState({});
  const [vIsLoadingCursosVencerA, setvIsLoadingCursosVencerA] = useState(true);

  useEffect(() => {
    Gets.mGetCursosVencerA()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvCursosVencerA(result);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingCursosVencerA(false);
      });
    Gets.mGetSummary()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvSummary(result);
        setvTiempoEstudio(Metodos.convertirYDenominar(result.segundos_estudio));
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingSummary(false);
      });
  }, [navigate, setVHorasStudio]);

  const getRandomInRange = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const generateRandomColor = () => {
    var minRGB = 40;
    var maxRGB = 255;
    var minOpacity = 0.7;
    var maxOpacity = 1;
    // Asegúrate de que los límites sean números y estén en el rango correcto
    minRGB = Math.max(0, Math.min(minRGB, 255));
    maxRGB = Math.max(minRGB, Math.min(maxRGB, 255));
    minOpacity = Math.max(0, Math.min(minOpacity, 1));
    maxOpacity = Math.max(minOpacity, Math.min(maxOpacity, 1));

    // Generar valores aleatorios para RGB dentro del rango especificado
    const red = getRandomInRange(minRGB, maxRGB);
    const green = getRandomInRange(minRGB, maxRGB);
    const blue = getRandomInRange(minRGB, maxRGB);
    // Generar valor aleatorio para la opacidad dentro del rango especificado
    const alpha = (
      Math.random() * (maxOpacity - minOpacity) +
      minOpacity
    ).toFixed(2);

    // Crear el string RGBA
    const color = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return color;
  };

  const CProgresCurso = (props) => {
    const { color, porcentaje } = props;

    return (
      <ConfigProvider
        theme={{
          components: {
            Progress: {
              colorText: color,
            },
          },
        }}
      >
        <Progress
          strokeColor={color}
          type="circle"
          percent={porcentaje}
          size={80}
        />
      </ConfigProvider>
    );
  };

  const formatTime = (seconds) => {
    // Calculamos las horas, minutos y segundos
    let hours = Math.floor(seconds / 3600);
    seconds %= 3600; // Resto después de calcular las horas
    let minutes = Math.floor(seconds / 60);
    seconds %= 60; // Resto después de calcular los minutos

    // Devolvemos el tiempo en formato HH:mm:ss
    // Usamos padStart para asegurarnos de que cada unidad tenga al menos dos dígitos
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toFixed(0).padStart(2, "0")}`;
  };

  const mRenderMiProgreso = () => {
    if (vIsLoadingDetallesPerfil) {
      return <Spin />;
    }

    if (vUserMeDetallesPerfil.length > 0) {
      return (
        <>
          {vUserMeDetallesPerfil.map((vItem) => (
            <Link
              to={`/alumno?tipo=mio&cId=${vItem.id_curso}`}
              className="link-style tam"
              data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
            >
              <div class="tarjeta-7">
                <CProgresCurso
                  porcentaje={
                    vItem.porcentaje === 100
                      ? vItem.porcentaje - 1
                      : vItem.porcentaje
                  }
                  color={generateRandomColor()}
                />
                <div class="tarjeta-7-contenido">
                  <div class="tarjeta-7-contenedor-titulo">
                    <div
                      data-figma-id="390:1161"
                      class="tarjeta-7-titulo truncated-text"
                    >
                      {vItem.nombre_curso}
                    </div>
                  </div>
                  <div class="tarjeta-7-leccion">Lección {vItem.lecciones}</div>
                  <div class="barra-video">
                    <div
                      data-figma-id="530:2562"
                      class="tarjeta-4-curso-barra-progreso-general"
                    >
                      <div
                        class="tarjeta-4-curso-barra-progreso"
                        style={{
                          width: `${
                            (vItem.progreso_video / vItem.total_tiempo_video) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div class="tarjeta-7-tiempo">
                    <div class="tarjeta-7-tiempo-1">
                      {formatTime(vItem.progreso_video)}
                    </div>
                    <div class="tarjeta-7-tiempo-1">
                      {formatTime(vItem.total_tiempo_video)}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </>
      );
    } else {
      return (
        <Empty
          description={<span>Comienza a ver cursos para tener progreso.</span>}
        />
      );
    }
  };

  const mRenderTiempoEstudio = () => {
    if (vIsLoadingSummary) {
      return <Spin />;
    }
    return (
      <>
        <div class="home-alumno-estadisticas-info-1">
          <div class="div-6" data-figma-id="546:2358">
            <img src="../images/lecc.svg" data-figma-id="I546:2359;546:2357" />
          </div>
          <div class="home-alumno-estadisticas-numero-lecciones">
            {vSummary.lecciones_terminadas}
          </div>
          <div
            class="home-alumno-estadisticas-lecciones"
            data-figma-id="537:2261"
          >
            lecciones completadas
          </div>
        </div>
        <div class="home-alumno-estadisticas-info-2">
          <div class="div-4" data-figma-id="546:2343">
            <img
              src="../images/horas_lecciones.svg"
              data-figma-id="I546:2344;546:2342"
            />
          </div>
          <div class="home-alumno-estadisticas-numero-horas">
            {vTiempoEstudio[0]}
          </div>
          <div class="home-alumno-estadisticas-horas">
            {vTiempoEstudio[1]} de aprendizaje
          </div>
        </div>
        <div class="home-alumno-estadisticas-info-3">
          <div class="div-5" data-figma-id="546:2350">
            <img
              src="../images/inventory.svg"
              data-figma-id="I546:2351;546:2349"
            />
          </div>
          <div class="home-alumno-estadisticas-numero-test">
            {vSummary.evaluaciones}
          </div>
          <div class="home-alumno-estadisticas-test" data-figma-id="537:2269">
            test completados
          </div>
        </div>
      </>
    );
  };

  const mRenderCursosVencer = () => {
    if (vIsLoadingCursosVencerA) {
      return <Spin />;
    }

    function CCard(props) {
      const { vCurso, vEtiqueta } = props;

      const mRenderVigenciaColor = () => {
        switch (vEtiqueta) {
          case "verde":
            return (
              <div class="tarjeta-5-curso-vigencia">
                <div data-figma-id="634:6353">
                  <img
                    src="../images/vigencia-azul.svg"
                    data-figma-id="I640:6372;634:6352"
                  />
                </div>
                <div class="tarjeta-5-curso-vigencia-azul">
                  Vigencia {vCurso.vigencia} días
                </div>
              </div>
            );
          case "amarillo":
            return (
              <div class="tarjeta-5-curso-vigencia">
                <div data-figma-id="634:6363">
                  <img
                    src="../images/vigencia-amarillo.svg"
                    data-figma-id="I640:6374;634:6362"
                  />
                </div>
                <div class="tarjeta-5-curso-vigencia-amarillo">
                  Vigencia {vCurso.vigencia} días
                </div>
              </div>
            );

          default:
            return (
              <div class="tarjeta-5-curso-vigencia">
                <div data-figma-id="634:6358">
                  <img
                    src="../images/vigencia-rojo.svg"
                    data-figma-id="I640:6412;634:6357"
                  />
                </div>
                <div class="tarjeta-5-curso-vigencia-rojo">
                  Vigencia {vCurso.vigencia} días
                </div>
              </div>
            );
        }
      };
      const mRenderEtiqueta = () => {
        switch (vEtiqueta) {
          case "verde":
            return (
              <div
                data-figma-id="634:6277"
                class="tarjeta-5-curso-linea-azul"
              ></div>
            );
          case "amarillo":
            return (
              <div
                data-figma-id="634:6313"
                class="tarjeta-5-curso-linea-amarilla"
              ></div>
            );

          default:
            return (
              <div
                data-figma-id="640:6378"
                class="tarjeta-5-curso-linea-roja"
              ></div>
            );
        }
      };
      return (
        <Link
          to={`/alumno?tipo=mio&cId=${vCurso.id}`}
          className="link-style "
          style={{ width: "100%" }}
        >
          <div class="tarjeta-5-curso-azul">
            <div class="tarjeta-5-curso-imagen-linea-azul">
              {mRenderEtiqueta()}
              <img
                src={
                  Variables.V_URL_API +
                  `/api2/img?id_curso=${vCurso.id}&img=${
                    vCurso.url_image
                  }&ath_accss=${
                    "Bearer " + vJWT_Access
                  }&quality=${vCalidadImagen}`
                }
                loading="lazy"
                alt=""
                class="tarjeta-5-curso-imagen"
              />
            </div>
            <div class="tarjeta-5-curso-contenido">
              <div class="tarjeta-5-curso-titulos">
                <div class="tarjeta-5-curso-texto-titulo truncated-text1">
                  {vCurso.nombre_curso}
                </div>
                <div class="tarjeta-5-curso-texto-nivel">
                  Nivel Introductorio
                </div>
              </div>
              <div class="tarjeta-5-curso-info">
                <div class="tarjeta-5-curso-info-1">
                  <div class="tarjeta-5-curso-horas">
                    <div
                      data-figma-id="112:341"
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c6e39b13570222e30_clock.svg"
                      class="div-61"
                    >
                      <img
                        src="../images/clock.svg"
                        data-figma-id="I165:2524;112:334"
                      />
                    </div>
                    <div
                      data-figma-id="634:6287"
                      class="tarjeta-5-curso-texto-horas"
                    >
                      {Metodos.convertirYDenominar(vCurso.duracion)[0] +
                        " " +
                        Metodos.convertirYDenominar(vCurso.duracion)[1]}
                    </div>
                  </div>
                  <div class="tarjeta-5-curso-idioma">
                    <div
                      data-figma-id="112:342"
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c1143426d44c43bf2_idioma.svg"
                      class="div-63"
                    >
                      <img
                        src="../images/idioma.svg"
                        data-figma-id="I165:2522;112:336"
                      />
                    </div>
                    <div class="tarjeta-5-curso-texto-idioma">
                      {vCurso.idioma}
                    </div>
                  </div>
                </div>
                <div class="tarjeta-5-curso-info-2">
                  <div class="tarjeta-5-curso-lecciones">
                    <div
                      data-figma-id="112:343"
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be7283c998c8de10836dc_book.svg"
                      class="div-62"
                    >
                      <img
                        src="../images/book_1.svg"
                        data-figma-id="I165:2526;112:338"
                      />
                    </div>
                    <div class="tarjeta-5-curso-texto-lecciones">
                      {vCurso.lecciones} lecciones
                    </div>
                  </div>
                  <div class="tarjeta-5-curso-horas-restantes">
                    <div
                      data-figma-id="531:3624"
                      src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663ba7352055d60fcf3f6b30_horas.svg"
                      class="div-69"
                    >
                      <img
                        src="../images/reloj-de-arena_2.svg"
                        data-figma-id="I634:6296;531:3623"
                      />
                    </div>
                    <div
                      data-figma-id="634:6297"
                      class="tarjeta-5-curso-texto-horas-restantes"
                    >
                      
                      {Metodos.convertirYDenominarHoras(vCurso.horas_restantes)}
                    </div>
                  </div>
                </div>

                {mRenderVigenciaColor()}
              </div>
            </div>
            <div class="tarjeta-5-curso-progreso">
              <Progress
                strokeColor="rgba(50, 144, 255,1)"
                trailColor="rgba(33, 36, 46, 1)"
                percent={vCurso.porcentaje}
                showInfo={false}
                style={{ width: "100%" }}
                size="small"
              />
              <div class="tarjeta-5-curso-texto-progreso">
                {vCurso.porcentaje} %
              </div>
            </div>
          </div>
        </Link>
      );
    }

    const mRenderRojo = (vCursos) => {
      if (vCursos !== null) {
        return vCursos.map((vCurso) => (
          <CCard vCurso={vCurso} vEtiqueta="rojo" />
        ));
      }
    };
    const mRenderAmarillo = (vCursos) => {
      if (vCursos !== null) {
        return vCursos.map((vCurso) => (
          <CCard vCurso={vCurso} vEtiqueta="amarillo" />
        ));
      }
    };
    const mRenderVerde = (vCursos) => {
      if (vCursos !== null) {
        return vCursos.map((vCurso) => (
          <CCard vCurso={vCurso} vEtiqueta="verde" />
        ));
      }
    };

    if (
      Object.keys(vCursosVencerA).length > 0 &&
      (vCursosVencerA.rojo !== null ||
        vCursosVencerA.amarillo !== null ||
        vCursosVencerA.verde !== null)
    ) {
      return (
        <>
          {mRenderRojo(vCursosVencerA.rojo)}
          {mRenderAmarillo(vCursosVencerA.amarillo)}
          {mRenderVerde(vCursosVencerA.verde)}
        </>
      );
    } else {
      return (
        <Empty description={<span>Aún no hay cursos proximos a vencer</span>} />
      );
    }
  };
  const mContCursosPorVencer = () => {
    var vNumAmarillo =
      vCursosVencerA.amarillo?.length !== undefined
        ? vCursosVencerA.amarillo?.length
        : 0;
    var vNumRojo =
      vCursosVencerA.rojo?.length !== undefined
        ? vCursosVencerA.rojo?.length
        : 0;
    var vNumVerde =
      vCursosVencerA.verde?.length !== undefined
        ? vCursosVencerA.verde?.length
        : 0;

    return vNumVerde + vNumRojo + vNumAmarillo;
  };

  return (
    <>
      {mRenderHeader(
        "Mi Progreso",
        vIsLoadingDetallesPerfil ? (
          <Spin size="small" />
        ) : (
          vUserMeDetallesPerfil?.length
        )
      )}
      <div class="home-alumno-contenido-principal">
        <div class="home-alumno-contenido-1">
          <div class="home-alumno-progreso">
            <div class="home-alumno-cursos-progreso">{mRenderMiProgreso()}</div>
          </div>
          <div class="home-alumno-titulo-2">
            <div class="home-alumno-texto-titulo">Cursos próximos a vencer</div>
            <div class="conteo-icon-2">
              <div class="conteo-icon-texto-2">
                {vIsLoadingCursosVencerA ? <Spin /> : mContCursosPorVencer()}
              </div>
            </div>
          </div>

          <div
            class="home-alumno-cursos"
            style={{ height: windowSize.height - 80 }}
          >
            <div
              class="home-alumno-tarjetas-cursos"
              style={{ height: windowSize.height - 525 }}
            >
              {mRenderCursosVencer()}
            </div>
          </div>
          <div class="home-alumno-texto-titulo">Esta semana...</div>

          <div class="home-alumno-estadisticas">{mRenderTiempoEstudio()}</div>
        </div>
        <div class="home-alumno-linea-divisora" />
        <CExplorarCursosNoComrpados {...props} />
      </div>
    </>
  );
}

function CExplorarCursosNoComrpados(props) {
  const { socket, vCalidadImagen, vJWT_Access, windowSize } = props;

  const [vCursosBusqueda, setvCursosBusqueda] = useState([]);
  const [vPagina, setPagina] = useState(1);
  const [vTotalPages, setvTotalPages] = useState(1);

  const [vValueSearch, setvValueSearch] = useState("");

  const [hasMore, setHasMore] = useState(true);
  const [vIsLoadingBusqueda, setvIsLoadingBusqueda] = useState(true);

  const [vIdBusquedaActual, setvIdBusquedaActual] = useState(0)

  const mSearchBusquedaTiempoReal = (query, page) => {
    setvIsLoadingBusqueda(true);
    var vIDBusqueda=Date.now()
    setvIdBusquedaActual(vIDBusqueda)
    socket.emit("search_cnc_time_real", { query: query, page: page,vIDBusqueda:vIDBusqueda });
  };

  const infiniteScrollRef = useRef(null); // Referencia al componente InfiniteScroll

  useEffect(() => {
    mSearchBusquedaTiempoReal(vValueSearch, vPagina);
  }, [vValueSearch, vPagina]);
  useEffect(() => {}, [vCursosBusqueda]);

  useEffect(() => {
    socket.on("result_search_cnc_time_real", function (data) {

      if (data.vIDBusqueda===vIdBusquedaActual && vIsLoadingBusqueda) {
        setvCursosBusqueda((prevCursosBusqueda) => {
          // Crear un nuevo array que contenga solo los cursos nuevos únicos
          const cursosUnicos = data.cursos.filter((cursoNuevo) => {
            // Comprobar si el cursoNuevo.id ya existe en prevCursosBusqueda
            return !prevCursosBusqueda.some(
              (cursoExistente) => cursoExistente.id === cursoNuevo.id
            );
          });

          // Devolver el array previo concatenado con los nuevos cursos únicos
          return [...prevCursosBusqueda, ...cursosUnicos];
        });
        setvIsLoadingBusqueda(false);
        setvTotalPages(data.total_pages);
      }
    });

    // Limpieza al desmontar
    return () => {
      socket.off("result_search_cnc_time_real");
    };
  }, [vIsLoadingBusqueda,vIdBusquedaActual]);

  const mOnChangeValueSearch = (e) => {
    setHasMore(true);
    setPagina(1);
    setvValueSearch(e.target.value);
    setvCursosBusqueda([]);
  };

  // Efecto para comprobar si es necesario cargar más datos después de la actualización del componente
  useEffect(() => {
    const checkScroll = () => {
      // Verifica si el contenedor tiene desplazamiento disponible. Si no lo tiene, intenta cargar más datos.
      if (
        infiniteScrollRef.current &&
        infiniteScrollRef.current.clientHeight ===
          infiniteScrollRef.current.scrollHeight
      ) {
        loadMoreCursosNoComprados();
      }
    };

    checkScroll();
  }, [vCursosBusqueda]); // Este efecto se ejecuta cada vez que 'items' cambia.

  const loadMoreCursosNoComprados = () => {
    if (vPagina === vTotalPages) {
      setHasMore(false);
      return;
    }
    if (vPagina < vTotalPages) {
      //mSearchBusquedaTiempoReal(vValueSearch, vPagina + 1);
      setPagina((prevPageIndex) => prevPageIndex + 1);
    }
  };

  const CursosBusqueda = ({ key, vCurso }) => {
    return (
      <Link
        key={key}
        to={`/alumno?tipo=cnc&cId=${vCurso.id}`}
        className="link-style"
        data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
      >
        <div class="tarjeta-3-curso-normal">
          <img
            src={
              Variables.V_URL_API +
              `/api2/img?id_curso=${vCurso.id}&img=${
                vCurso?.img?.img_explora
              }&ath_accss=${"Bearer " + vJWT_Access}&quality=${vCalidadImagen}`
            }
            loading="lazy"
            class="img-5"
          />
          <div data-figma-id="635:2709" class="tarjeta-3-curso-contenido">
            <div data-figma-id="635:2710" class="tarjeta-3-curso-header">
              <div class="tarjeta-3-curso-texto--contenedor-titulo">
                <div
                  data-figma-id="635:2711"
                  class="tarjeta-3-curso-texto-titulo truncated-text"
                >
                  {vCurso.nombre}
                </div>
              </div>

              {Date.now() >= new Date(vCurso.fecha_disponibilidad) ? (
                <div
                  class="tarjeta-3-curso-texto-estatus"
                  data-figma-id="635:2712"
                >
                  Disponible ahora
                </div>
              ) : (
                <div
                  class="tarjeta-3-curso-texto-estatus-azul"
                  data-figma-id="685:2102"
                >
                  Disponible próximamente
                </div>
              )}
            </div>
            <div data-figma-id="635:2713" class="tarjeta-3-curso-info">
              <div
                data-figma-id="635:2714"
                class="tarjeta-3-curso-calificacion"
              >
                <div
                  data-figma-id="1:30"
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663ba7c7d634fa76d14027b1_star.svg"
                  class="div-60"
                >
                  <img
                    src="../images/star_1.svg"
                    data-figma-id="I133:490;1:29"
                  />
                </div>
                <div
                  class="tarjeta-3-curso-texto-calificacion"
                  data-figma-id="635:2716"
                >
                  {vCurso.calificacion}
                </div>
              </div>
              <div data-figma-id="635:2717" class="tarjeta-3-curso-lecciones">
              <div data-figma-id="4:6" src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663ba7c702b92c3e3378cd44_menu_book.svg" class="div-72"><img src="../images/menu_book.svg" data-figma-id="I133:494;4:5"/></div>
                <div
                  class="tarjeta-3-curso-texto-lecciones"
                  data-figma-id="635:2719"
                >
                  {vCurso.total_lecciones} lecciones
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const mRenderCursoNoComrpados = () => {
    if (vIsLoadingBusqueda) {
      return <Spin />;
    } else {
      if (vCursosBusqueda.length === 0) {
        return (
          <Empty
            description={
              <span>No se encontrol coincidencia para "{vValueSearch}".</span>
            }
          />
        );
      }
      return vCursosBusqueda.map((vCurso) => (
        <CursosBusqueda key={new Date().now} vCurso={vCurso} />
      ));
    }
  };

  return (
    <div class="home-alumno-contenido-2">
      <div class="home-alumno-explora">Explora</div>

      <div data-figma-id="404:1861" class="barra-buscar">
        <div data-figma-id="404:1852">
          <img
            src={`${window.location.origin}/images/search.svg`}
            data-figma-id="I404:1853;404:1851"
          />
        </div>
        <input
          class="barra-buscar-texto"
          value={vValueSearch}
          onChange={(e) => mOnChangeValueSearch(e)}
        />
      </div>
      <>
        <InfiniteScroll
          ref={infiniteScrollRef}
          dataLength={vCursosBusqueda.length}
          next={loadMoreCursosNoComprados}
          hasMore={hasMore}
          loader={vCursosBusqueda.length > 0 && <Spin />}
          className="home-alumno-tarjeta-cursos-explora"
        >
          {mRenderCursoNoComrpados()}
        </InfiniteScroll>
      </>
    </div>
  );
}
