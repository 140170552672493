import React, { useEffect, useRef, useState } from "react";

import * as Variables from "../../utils/Global/Variables";
import * as Metodos from "../../utils/Global/Metodos";
import Tabla from "../Tabla/tabla";
import Contactanos from "../modals/contactanos";
import * as Gets from "../../utils/Rest apis/Gets";
import { Rate, Spin, message } from "antd";

export default function CourseNotPurchased(props) {
  const {
    vId,
    key,
    vIsA = false,
    vJWT_Access = null,
    vCalidadImagen = 75,
    mRenderHeader,
  } = props;
  const [vLoadingCurso, setvLoadingCurso] = useState(true);
  const [vCurso, setvCurso] = useState({});
  const [vSecciones, setvSecciones] = useState({});
  const [vComentarios, setvComentarios] = useState({});
  const [vProfesor, setvProfesor] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  

  useEffect(() => {
    Gets.mGetCourse(vId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((vDataCurso) => {
        setvCurso(vDataCurso.data[0].attributes);
        setvSecciones(vDataCurso.data[0].attributes.secciones.data);
        setvComentarios(vDataCurso.data[0].attributes.comentarios_curso.data);
        setvProfesor(vDataCurso.data[0].attributes.profesor.data.attributes);
      })
      .catch((error) => {
        // Manejar el error (mostrar un mensaje al usuario, por ejemplo)
      })
      .finally(() => {
        setvLoadingCurso(false);
      });
  }, []);

  const mRenderAprenderas = (_) =>
    vCurso?.objetivos_especificos
      .split(/-|\n-/)
      .filter(Boolean)
      .map((vObjetivo) => (
        <div data-figma-id="498:2150" class="no-comprado-alumno-check">
          <div data-figma-id="I910:4217;498:2151" class="check">
            <img src="../images/check.svg" data-figma-id="485:2088" />
          </div>
          <div
            data-figma-id="I910:4217;498:2148"
            class="no-comprado-alumno-texto-check-1 truncated-text"
          >
            {vObjetivo}
          </div>
        </div>
      ));

  const mRenderEstrellarComentario = (vNumCalificacion) => {
    var vEstrellas = [];

    for (let vIndex = 1; vIndex <= 5; vIndex++) {
      if (vIndex <= vNumCalificacion) {
        vEstrellas.push(
          <img
            src={`${window.location.origin}/images/Vectors-Wrapper_35.svg`}
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      } else {
        vEstrellas.push(
          <img
            src={`${window.location.origin}/images/Vectors-Wrapper_31.svg`}
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      }
    }
    return vEstrellas;
  };

  const mRenderComentarios = () => {
    return vComentarios.map((vComentario) => {
      return (
        <div className="comentario-cnc">
          <div className="text-comentario-nombre">
            {vComentario.attributes.alumno.data.attributes.nombre +
              " " +
              vComentario.attributes.alumno.data.attributes.apellidos}
          </div>
          <div className="valoracion-comentario-cnc">
            <div className="calificacion-comentario-cnc">
              {mRenderEstrellarComentario(vComentario.attributes.calificacion)}
            </div>
            <div className="tiempo-comentario-cnc">
              Hace{" "}
              {Metodos.mTimeSince(new Date(vComentario.attributes.fechaHora))}
            </div>
          </div>
          <div className="text-comentario">
            {vComentario.attributes.comentario}
          </div>
        </div>
      );
    });
  };

  const mRenderSectionNoCompradoContenido = () => {
    return (
      <>
        <div className="no-comprado-alumno-contenido-1 invisible-scroll-container">
        {!vIsA&& <div class="mis-cursos-alumno-texto-titulo " data-figma-id="635:3112">
            {vCurso?.nombre}
          </div>}
          <div
            class="no-comprado-alumno-descripcion-curso"
            dangerouslySetInnerHTML={{
              __html: vCurso?.objetivo_general.replace(/\n/g, "<br/>"),
            }}
          />
          <div data-figma-id="843:2701" class="no-comprado-alumno-info-1">
            <div
              data-figma-id="602:2426"
              class="no-comprado-alumno-calificacion"
            >
              <div
                data-figma-id="1:30"
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663ba7c7d634fa76d14027b1_star.svg"
                class="div-60"
              >
                <img src="../images/star_1.svg" data-figma-id="I133:490;1:29" />
              </div>
              <div
                class="no-comprado-alumno-texto-calificacion"
                data-figma-id="602:2428"
              >
                {vCurso?.calificacion}
              </div>
            </div>
            <div data-figma-id="602:2429" class="no-comprado-alumno-graduados">
              <div data-figma-id="4:4">
                <img src="../images/school.svg" data-figma-id="I602:2430;4:3" />
              </div>
              <div
                class="no-comprado-alumno-texto-graduados"
                data-figma-id="602:2431"
              >
                {Metodos.formatNumber(vCurso.total_graduados)} graduados
              </div>
            </div>
            <div data-figma-id="602:2432" class="no-comprado-alumno-idioma">
              <div
                data-figma-id="112:342"
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c1143426d44c43bf2_idioma.svg"
              >
                <img
                  src="../images/idioma.svg"
                  data-figma-id="I165:2522;112:336"
                />
              </div>
              <div
                class="no-comprado-alumno-texto-idioma"
                data-figma-id="602:2434"
              >
                {vCurso?.lenguaje}
              </div>
            </div>
          </div>
          <div data-figma-id="896:3045" class="no-comprado-alumno-info-2">
            <div
              data-figma-id="896:3007"
              class="no-comprado-alumno-lo-que-aprenderas"
            >
              <div data-figma-id="910:4071" class="no-comprado-alumno-titulo-1">
                <img src="../images/rocket.svg" data-figma-id="485:1912" />
                <div
                  data-figma-id="910:4073"
                  class="no-comprado-alumno-texto-titulo"
                >
                  Lo que aprenderás
                </div>
              </div>
              <div data-figma-id="896:3011" class="frame-184">
                {mRenderAprenderas()}
              </div>
            </div>
          </div>
          <div data-figma-id="843:2699" class="no-comprado-alumno-info-3">
            <div data-figma-id="602:2450" class="no-comprado-alumno-titulo-2">
              <div data-figma-id="499:2191">
                <img
                  src="../images/perfil-egreso.svg"
                  data-figma-id="I602:2451;499:2190"
                />
              </div>
              <div
                data-figma-id="602:2452"
                class="no-comprado-alumno-texto-info-3"
              >
                Perfil de egreso
              </div>
            </div>
            <div
              data-figma-id="602:2453"
              class="no-comprado-alumno-info"
              dangerouslySetInnerHTML={{
                __html: vCurso?.perfil_graduacion.replace(/\n/g, "<br/>"),
              }}
            />
          </div>

          <div data-figma-id="843:2698" class="no-comprado-alumno-info-4">
            <div data-figma-id="602:2471" class="no-comprado-alumno-titulo-3">
              <div data-figma-id="499:2209">
                <img
                  src="../images/requerimientos.svg"
                  data-figma-id="I602:2472;499:2208"
                />
              </div>
              <div
                data-figma-id="602:2473"
                class="no-comprado-alumno-texto-titulo"
              >
                Conocimientos / Requerimientos
              </div>
            </div>
            <div
              data-figma-id="602:2474"
              class="no-comprado-alumno-requerimentos"
            >
              <div
                data-figma-id="602:2474"
                class="no-comprado-alumno-requerimento-texto"
                dangerouslySetInnerHTML={{
                  __html: vCurso?.requisitos.replace(/\n/g, "<br/>"),
                }}
              />
            </div>
          </div>
          <div data-figma-id="896:3115" class="no-comprado-alumno-info-5">
            <div
              data-figma-id="896:3086"
              class="no-comprado-alumno-contenido-titulo-info-5"
            >
              <img src="../images/libro-2.svg" data-figma-id="527:1979" />
              <div
                data-figma-id="896:3088"
                class="no-comprado-alumno-contenidp-texto-titulo"
              >
                Contenido
              </div>
            </div>
            <div className="no-comprado-alumno-tabla-contenido">
              <Secciones vSecciones={vSecciones} />
            </div>
          </div>
        </div>
        <div data-figma-id="695:2279" class="tarjeta-6-curso-disponible-ahora">
          {vIsA?<img
            class="img-6"
            src={
              Variables.V_URL_API +
              `/api2/img?id_curso=${vCurso.id}&img=${
                vCurso?.img_no_comrpado?.data?.attributes?.url
              }&ath_accss=${"Bearer " + vJWT_Access}&quality=${vCalidadImagen}`
            }
            data-figma-id="695:2255"
          />:<img
          class="img-6"
          src={
            Variables.V_URL_API +
            `${vCurso?.img_no_comrpado?.data?.attributes?.url}`
          }
          data-figma-id="695:2255"
        />}
          <div data-figma-id="695:2256" class="tarjeta-6-curso-contenido">
            {Date.now() >= new Date(vCurso?.fecha_disponibilidad) ? (
              <div data-figma-id="695:2257" class="tarjeta-1-curso-disponible">
                <div
                  class="tarjeta-1-curso-texto-disponible"
                  data-figma-id="695:2258"
                >
                  Disponible ahora
                </div>
              </div>
            ) : (
              <div
                data-figma-id="695:2283"
                class="tarjeta-1-curso-disponible-prox"
              >
                <div
                  class="tarjeta-1-curso-texto-disponible-prox"
                  data-figma-id="695:2284"
                >
                  Disponible próximamente
                </div>
              </div>
            )}

            <div data-figma-id="695:2259" class="tarjeta-6-curso-info">
              <div data-figma-id="695:2260" class="tarjeta-6-curso-horas">
                <div data-figma-id="695:2346">
                  <img
                    src="../images/clock_3.svg"
                    data-figma-id="I695:2350;695:2345"
                  />
                </div>
                <div
                  class="tarjeta-6-curso-texto-horas"
                  data-figma-id="695:2262"
                >
                  {Metodos.convertirYDenominar(vCurso.duracion)[0] +
                    " " +
                    Metodos.convertirYDenominar(vCurso.duracion)[1] +
                    " "}
                  de curso
                </div>
              </div>
              <div data-figma-id="695:2263" class="tarjeta-6-curso-recursos">
                <div
                  data-figma-id="241:266"
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662d55160297fa99c7514958_download.svg"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662d55160297fa99c7514958_download.svg"
                    data-figma-id="I241:496;241:265"
                  />
                </div>
                <div
                  class="tarjeta-6-curso-texto-recursos"
                  data-figma-id="695:2265"
                >
                  Recursos descargables
                </div>
              </div>
              <div data-figma-id="695:2266" class="tarjeta-6-curso-dias">
                <div
                  data-figma-id="241:273"
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662d5517c79747e767191ddd_timer.svg"
                >
                  <img
                    src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662d5517c79747e767191ddd_timer.svg"
                    data-figma-id="I241:499;241:272"
                  />
                </div>
                <div
                  class="tarjeta-6-curso-texto-dias"
                  data-figma-id="695:2268"
                >
                  Acceso por{" "}
                  {vCurso.duracion_acceso_dias +
                    (vCurso.duracion_acceso_dias > 1 ||
                    vCurso.duracion_acceso_dias === 0
                      ? " días"
                      : " dia")}
                </div>
              </div>
              <div data-figma-id="695:2269" class="tarjeta-6-curso-certificado">
                <div data-figma-id="241:280">
                  <img
                    src="../images/certificado-pequeño.svg"
                    data-figma-id="I695:2270;241:279"
                  />
                </div>
                <div
                  class="tarjeta-6-curso-texto-certificado"
                  data-figma-id="695:2271"
                >
                  Certificado y Badge
                </div>
              </div>
            </div>
            <div data-figma-id="695:2272" class="botones-tarjeta-6-curso">
              <Contactanos
                messageApi={messageApi}
                vBtnText="Más info"
                {...props}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  if (vLoadingCurso) {
    return <Spin tip="Loading " />;
  }
  return (
    <>
      {mRenderHeader !== undefined && mRenderHeader(vCurso?.nombre, "")}
      {contextHolder}
      {vIsA?<div className="no-comprado-alumno-contenido">{mRenderSectionNoCompradoContenido()}</div>:<div className="section-cnc">{mRenderSectionNoCompradoContenido()}</div>}
    </>
  );
}

const Secciones = ({ vSecciones }) => {
  return (
    <>
      {vSecciones.map((vSeccion, index) => {
        let className = "";

        if (index === 0) {
          className = "no-comprado-alumno-tabla-contenido-1";
        } else if (index === vSecciones.length - 1) {
          className = "no-comprado-alumno-tabla-contenido-3";
        } else {
          className = "no-comprado-alumno-tabla-contenido-2";
        }

        return (
          <div key={index} className={className}>
            <div
              data-figma-id="896:3091"
              class="no-comprado-alumno-tabla-contenido-titulo "
            >
              <img src="../images/arrow-right.svg" data-figma-id="527:2090" />
              <div
                data-figma-id="896:3093"
                class="no-comprado-alumno-tabla-contenido-titulo-texto truncated-text1"
              >
                {vSeccion.attributes.nombre}
              </div>
            </div>
            <div
              data-figma-id="896:3094"
              class="no-comprado-alumno-tabla-contenido-lecciones"
            >
              {vSeccion.attributes.lecciones.data.length} lecciones //{" "}
              {vSeccion.attributes.duracion_total} minutos
            </div>
          </div>
        );
      })}
    </>
  );
};
