import { Button, Flex, Modal, Progress, Spin } from "antd";
import React, { useEffect, useState } from "react";

import * as Gets from "../../utils/Rest apis/Gets";
import * as Metodos from "../../utils/Global/Metodos";
import * as Variables from "../../utils/Global/Variables";

import * as Icons from "react-icons/ai"; // Esta línea podría necesitar ajustes
import { useNavigate } from "react-router-dom";

const CRandomIcon = () => {
  const allIcons = Object.keys(Icons);
  const [randomIconName, setRandomIconName] = useState("");

  useEffect(() => {
    generateRandomIcon();
  }, []);

  const generateRandomIcon = () => {
    const randomIndex = Math.floor(Math.random() * allIcons.length);
    setRandomIconName(allIcons[randomIndex]);
  };

  const IconComponent = Icons[randomIconName];

  return (
    <>
      {IconComponent && (
        <IconComponent
          color={randomIconName.includes("Twotone") ? "#6e7680" : "#b1bdcd"}
          size={90}
        />
      )}
    </>
  );
};

const CTarjetaCurso = (props) => {
  const { vCurso, vIdCursoSeleccionado, mSeleccionarCurso } = props;
  const [vClassSeleccionado, setvClassSeleccionado] = useState("normal"); //"-contenedor"
  const navigate = useNavigate();
  return (
    <div
      class={`${
        vIdCursoSeleccionado === vCurso.id
          ? "tcm-seleccionado-profesor"
          : `tcm-${vClassSeleccionado}-profesor`
      }`}
      onMouseEnter={() => setvClassSeleccionado("seleccionado")}
      onMouseLeave={() => setvClassSeleccionado("normal")}
      onClick={() => {
        mSeleccionarCurso(vCurso.id, vCurso);
      }}
      onDoubleClick={() =>
        navigate(`/profesor?tipo=mio&cId=${vCurso.id}`, { replace: true })
      }
    >
      <CRandomIcon />
      <div class={`card-info-seleccionado-profesor`}>
        <div class="titulos">
          <div class="tmc-titulo">{vCurso.attributes.nombre}</div>
        </div>
        <div class="tcm-sub-datos-contenedor-profesor">
          <>
            <div class="tmc-sub-datos-profesor">
              <img
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65989dcea85e5b977953ac5e_Vectors-Wrapper.svg"
                loading="lazy"
                width="19"
                height="19"
                alt=""
                class="vectors-wrapper-161"
              />
              <div class="horas-2">
                {`${
                  Metodos.convertirYDenominar(vCurso.attributes.duracion)[0]
                } ${
                  Metodos.convertirYDenominar(vCurso.attributes.duracion)[1]
                }`}
              </div>
            </div>
            <div class="tmc-sub-datos-profesor">
              <img
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65989dcfabd7aa238fcc0547_Vectors-Wrapper.svg"
                loading="lazy"
                width="20"
                height="19"
                alt=""
                class="vectors-wrapper-162"
              />
              <div class="num-de-lecciones">
                {vCurso.attributes.total_lecciones} lecciones
              </div>
            </div>
            <div class="tmc-sub-datos-profesor">
              <img
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65989dd0b2c7bce7d7bca792_Vectors-Wrapper.svg"
                loading="lazy"
                width="19"
                height="19"
                alt=""
                class="vectors-wrapper-161"
              />
              <div class="text-120">{vCurso.attributes.lenguaje}</div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

function CInformacionCurso(props) {
  const { vCurso, vCalidadImagen, vJWT_Access } = props;

  const [vIsLoadingInfoAlumnos, setvIsLoadingInfoAlumnos] = useState(true);
  const [vConteoAlumnosCursoProfesor, setvConteoAlumnosCursoProfesor] =
    useState(null);

  const mRenderEstrellasCalificacion = (vNumCalificacion) => {
    var vEstrellas = [];

    for (let vIndex = 1; vIndex <= 5; vIndex++) {
      if (vIndex <= vNumCalificacion) {
        vEstrellas.push(
          <img
            src="../images/Vectors-Wrapper_35.svg"
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      } else {
        vEstrellas.push(
          <img
            src="../images/Vectors-Wrapper_31.svg"
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      }
    }
    return vEstrellas;
  };

  useEffect(() => {
    if (vCurso !== null) {
      setvIsLoadingInfoAlumnos(true);
      Gets.mGetConteoAlumnosCursoProfesor(vCurso.id)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        })
        .then((result) => {
          setvConteoAlumnosCursoProfesor(result);
        })
        .catch(() => {})
        .finally(() => {
          setvIsLoadingInfoAlumnos(false);
        });
    }
  }, [vCurso]);

  return vCurso !== null ? (
    <div class="div-block-37">
      <img
        src={`${Variables.V_URL_API}/api2/img?id_curso=${vCurso.id}&img=${
          vCurso.attributes.image.data.attributes.url
        }&ath_accss=${"Bearer " + vJWT_Access}&quality=${vCalidadImagen}`}
        loading="lazy"
        alt=""
        class="image-15"
      />
      <div class="div-block-39">
        <div class="div-block-40">
          <div class="div-block-44">
            {mRenderEstrellasCalificacion(vCurso.attributes.calificacion)}
          </div>
          <div class="text-block-11">{vCurso.attributes.calificacion}</div>
        </div>
        <div class="div-block-41">
          <img src="../images/Vectors-Wrapper_43.svg" loading="lazy" alt="" />
          <div class="text-block-12">
            {Metodos.formatNumber(vCurso.attributes.total_graduados)} graduados
          </div>
        </div>
      </div>
      <div class="div-block-38">
        <div class="frame-156">
          <div class="frame-157">
            <div class="text-121">{vCurso.attributes.total_secciones}</div>
            <div class="text-122">secciones</div>
          </div>
          <wrapper class="vectors-wrapper-173"></wrapper>
          <div class="frame-157">
            <div class="text-121">{vCurso.attributes.total_lecciones}</div>
            <div class="text-122">lecciones</div>
          </div>
          <wrapper class="vectors-wrapper-173"></wrapper>
          <div class="frame-157">
            <div class="text-121">{vCurso.attributes.total_lecciones}</div>
            <div class="text-122">evaluaciones</div>
          </div>
        </div>
        {vIsLoadingInfoAlumnos ? (
          <Spin />
        ) : (
          <>
            <div class="div-block-30">
              <div class="text-titulo-mis-cursos">Alumnos</div>
              <div class="circulo-num-mis-cursos-profesor">
                <div class="text-num-mis-cursos">
                  {vConteoAlumnosCursoProfesor.total_activos +
                    vConteoAlumnosCursoProfesor.total_inactivos}
                </div>
              </div>
            </div>
            <div class="contenido-btns-a-profesor">
              <div class="frame-152">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/6598aa1c99ab934be1e589b6_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="11.666666030883789"
                  height="11.666666030883789"
                  alt=""
                  class="vectors-wrapper-175"
                />
                <div class="text-124">
                  {vConteoAlumnosCursoProfesor.total_activos} activos
                </div>
              </div>
              <div class="frame-151">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/6598a9cd9fd46e4c9a5338ca_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="14"
                  height="14"
                  alt=""
                  class="vectors-wrapper-174"
                />
                <div class="text-123">
                  {vConteoAlumnosCursoProfesor.total_inactivos0} inactivos
                </div>
              </div>
            </div>
          </>
        )}
        <div class="text-titulo-mis-cursos">Foro</div>
        <div class="frame-153">
          <img
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/6598aa63d7bd04ccda9b505a_Vectors-Wrapper.svg"
            loading="lazy"
            width="10.5"
            height="10.888888359069824"
            alt=""
            class="vectors-wrapper-176"
          />
          <div class="text-125">? mensajes sin leer</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default function MisCursos(props) {
  const { windowSize } = props;

  const [vCursos, setvCursos] = useState([]);
  const [vNumCursos, setvNumCursos] = useState(0);
  const [vCursoSeleccionado, setvCursoSeleccionado] = useState(null);

  const [vIsLoadingCursos, setvIsLoadingCursos] = useState(true);

  useEffect(() => {
    Gets.mGetMisCursosProfesor()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvCursos(result.data);
        setvNumCursos(result.meta.pagination.total);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingCursos(false);
      });
  }, []);

  const mSeleccionarCurso = (vIdCS, vCurso) => {
    setvCursoSeleccionado(vCurso);
  };
  const mRenderCursos = () => {
    return vCursos.map((vCurso) => (
      <CTarjetaCurso
        vIdCursoSeleccionado={vCursoSeleccionado?.id}
        mSeleccionarCurso={mSeleccionarCurso}
        vCurso={vCurso}
      />
    ));
  };

  return (
    <div
      class="conteniido-mis-cursos-profesor"
      style={{ height: windowSize.height - 80 }}
    >
      <div class="cursos-profesor">
        <div class="div-block-30">
          <div class="text-titulo-mis-cursos">Mis cursos</div>
          <div class="circulo-num-mis-cursos">
            <div class="text-num-mis-cursos">{vNumCursos}</div>
          </div>
        </div>
        {vIsLoadingCursos ? (
          <Spin />
        ) : (
          <div class="tcm-contenedor">{mRenderCursos()}</div>
        )}
      </div>
      <div class="info-cruso-profesor">
        {<CInformacionCurso {...props} vCurso={vCursoSeleccionado} />}
      </div>
    </div>
  );
}
