import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Gets from "../../utils/Rest apis/Gets";
import * as Metodos from "../../utils/Global/Metodos";
import * as Variables from "../../utils/Global/Variables";
import Certificado from "../../components/modals/certificado";
import { Progress, Spin, message } from "antd";

const CTarjetaCursoComrpado = (props) => {
  const { vCurso, vIdCursoSeleccionado, mSeleccionarCurso } = props;
  const [vClassSeleccionado, setvClassSeleccionado] =
    useState("-no-seleccionado"); //"-contenedor"
  return (
    <div
      class={`${
        vIdCursoSeleccionado === vCurso.attributes.curso.data.id
          ? "tarjeta-4-curso-default-seleccionado"
          : `tarjeta-4-curso-default${vClassSeleccionado}`
      }`}
      onMouseEnter={() => setvClassSeleccionado("-seleccionado")}
      onMouseLeave={() => setvClassSeleccionado("-no-seleccionado")}
      onClick={() => {
        mSeleccionarCurso(
          vCurso.id,
          vCurso.attributes.curso.data,
          vCurso.attributes.tiempo_restante[1]
        );
      }}
    >
      <div data-figma-id="165:2510" class="tarjeta-4-curso-header">
        <div class="tarjeta-4-curso--contenedor-titulo">
          <div
            data-figma-id="165:2482"
            class="tarjeta-4-curso-titulo truncated-text"
          >
            {vCurso.attributes.curso.data.attributes.nombre}
          </div>
        </div>
        <div class="tarjeta-4-curso-nivel" data-figma-id="165:2506">
          Nivel Introductorio
        </div>
      </div>
      <div class="tarjeta-4-curso-contenido">
        <div data-figma-id="165:2589" class="tarjeta-4-curso-info-1">
          <div data-figma-id="165:2590" class="tarjeta-4-curso-vigencia">
          <div data-figma-id="112:339" src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72b4b2985d3b9f0dafe_calendar.svg" class="div-64">
              <img
                src={
                  vCurso.attributes.tiempo_restante[1] === "con_acceso" ||
                  vCurso.attributes.tiempo_restante[1] ===
                    "con_acceso_certificado"
                    ? `https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72b4b2985d3b9f0dafe_calendar.svg`
                    : vCurso.attributes.tiempo_restante[1] ===
                      "sin_acceso_certificado"
                    ? `../images/check-circulo-verde.svg`
                    : `../images/no-accesss.svg`
                }
                data-figma-id="I165:2512;112:330"
              />
            </div>
            <div
              class={
                vCurso.attributes.tiempo_restante[1] === "con_acceso" ||
                vCurso.attributes.tiempo_restante[1] ===
                  "con_acceso_certificado" ||
                vCurso.attributes.tiempo_restante[1] ===
                  "sin_acceso_certificado"
                  ? "tarjeta-4-curso-texto-vigencia truncated-text1"
                  : "tarjeta-4-curso-texto-sin-acceso"
              }
              data-figma-id="165:2592"
            >
              {vCurso.attributes.tiempo_restante[0]}
            </div>
          </div>
          {vCurso.attributes.tiempo_restante[1] === "con_acceso_certificado" ||
          vCurso.attributes.tiempo_restante[1] === "sin_acceso_certificado" ? (
            <Certificado
              {...props}
              id_curso={vCurso.attributes.curso.data.id}
            />
          ) : (
            <div data-figma-id="165:2593" class="tarjeta-4-curso-horas">
              
              <div
                data-figma-id="112:340"
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72bf7642118aef1a451_reloj-de-arena.svg"
              >
                <img
                  src="../images/reloj-de-arena.svg"
                  data-figma-id="I165:2516;112:332"
                />
              </div>
              <div class="tarjeta-4-curso-texto-horas" data-figma-id="165:2595">
                {vCurso.attributes.horas_restantes} horas restantes
              </div>
              
            </div>
          )}
        </div>
        <div data-figma-id="165:2596" class="tarjeta-4-curso-info-2">
          <div data-figma-id="165:2597" class="tarjeta-4-curso-tiempo">
            <div
              data-figma-id="112:341"
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c6e39b13570222e30_clock.svg"
            >
              <img
                src="../images/clock.svg"
                data-figma-id="I165:2524;112:334"
              />
            </div>
            <div class="tarjeta-4-curso-texto-tiempo" data-figma-id="165:2599">
              {`${
                Metodos.convertirYDenominar(
                  vCurso.attributes.curso.data.attributes.duracion
                )[0]
              } ${
                Metodos.convertirYDenominar(
                  vCurso.attributes.curso.data.attributes.duracion
                )[1]
              }`}
            </div>
          </div>
          <div data-figma-id="165:2600" class="tarjeta-4-curso-lecciones">
            <div
              data-figma-id="112:343"
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be7283c998c8de10836dc_book.svg"
            >
              <img
                src="../images/book_1.svg"
                data-figma-id="I165:2526;112:338"
              />
            </div>
            <div
              class="tarjeta-4-curso-texto-lecciones"
              data-figma-id="165:2602"
            >
              {vCurso.attributes.lecciones_progreso} lecciones
            </div>
          </div>
          <div data-figma-id="165:2603" class="tarjeta-4-curso-idioma">
            <div
              data-figma-id="112:342"
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/662be72c1143426d44c43bf2_idioma.svg"
            >
              <img
                src="../images/idioma.svg"
                data-figma-id="I165:2522;112:336"
              />
            </div>
            <div class="tarjeta-4-curso-texto-idioma" data-figma-id="165:2605">
              {vCurso.attributes.curso.data.attributes.lenguaje}
            </div>
          </div>
        </div>
      </div>
      <div class="tarjeta-4-curso-progreso">
        <div
          data-figma-id="530:2562"
          class="tarjeta-4-curso-barra-progreso-general"
        >
          <div
            class="tarjeta-4-curso-barra-progreso"
            style={{
              width: `${
                vCurso.attributes.tiempo_restante[1] ===
                  "con_acceso_certificado" ||
                vCurso.attributes.tiempo_restante[1] ===
                  "sin_acceso_certificado"
                  ? 100
                  : vCurso.attributes.porcentaje_completado
              }%`,
            }}
          ></div>
        </div>
        <div class="tarjeta-4-curso-texto-porcentaje">
          {vCurso.attributes.tiempo_restante[1] ===
                  "con_acceso_certificado" ||
                vCurso.attributes.tiempo_restante[1] ===
                  "sin_acceso_certificado"
                  ? 100
                  : vCurso.attributes.porcentaje_completado} % completado
        </div>
      </div>
    </div>
  );
};

const CRenderInfoCursoSeleccionado = (props) => {
  const { vCurso, vIdCursoSeleccionado, vCalidadImagen } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const [vInfoLeccion, setvInfoLeccion] = useState(null);
  const [vProximasLecciones, setvProximasLecciones] = useState([]);
  const [vIsLoadingLeccion, setvIsLoadingLeccion] = useState(true);
  const [imagenSrc, setImagenSrc] = useState("");

  useEffect(() => {
    if (vIdCursoSeleccionado !== -1) {
      setvIsLoadingLeccion(true);
      setImagenSrc("");
      Gets.mGetLeccionesMisCursos(vCurso.id, 3)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        })
        .then((result) => {
          var vInfoLeccionTmp = result.info_leccion;
          var JWT_AccessTmp = result.JWT_Access;
          setvInfoLeccion(result.info_leccion);

          setvProximasLecciones(result.proximas_lecciones);
          Gets.cargarImagen(
            `${Variables.V_URL_API}/api2/img?id_leccion=${
              vInfoLeccionTmp.id_leccion_actual
            }&img=${vInfoLeccionTmp.url_img_leccion_actual}&ath_accss=${
              "Bearer " + JWT_AccessTmp
            }&quality=${vCalidadImagen}`
          )
            .then((dataBase64) => {
              setImagenSrc(dataBase64);
            })
            .catch()
            .finally(() => {
              setvIsLoadingLeccion(false);
            });
        })
        .catch();
    }
  }, [vCurso, vIdCursoSeleccionado, vCalidadImagen]);

  const mRenderProximasLeciones = () => {
    return vProximasLecciones.map((vSeccion) => (
      <>
        <div class="apartado-tarjeta-2-modulo">
          Sección {vSeccion.num}: {vSeccion.seccion}
        </div>
        {vSeccion.lecciones.map((vLeccion) => (
          <div class="apartado-tarjeta-2-contenido-leccion-1">
            <div class="apartado-tarjeta-2-leccion-1">
              <div
                data-figma-id="123:209"
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/663110d03e1938d72ffdc8c2_lock-01.svg"
              >
                <img
                  src={`${window.location.origin}/images/Icon_1.svg`}
                  data-figma-id="I496:2067;1007:8884"
                />
              </div>
              <div class="apartado-tarjeta-2-texto-leccion-1 truncated-text1">
                Lección {vLeccion.lugar_lista}: {vLeccion.nombre}
              </div>
            </div>
            <div class="apartado-tarjeta-2-texto-tiempo">
              {vLeccion.duracion} min
            </div>
          </div>
        ))}
      </>
    ));
  };

  const mRenderEstrellarCalificaciion = (vNumCalificacion) => {
    var vEstrellas = [];

    for (let vIndex = 1; vIndex <= 5; vIndex++) {
      if (vIndex <= vNumCalificacion) {
        vEstrellas.push(
          <img
            src={`${window.location.origin}/images/star.svg`}
            loading="lazy"
            alt=""
          />
        );
      } else {
        vEstrellas.push(
          <img
            src={`${window.location.origin}/images/Vectors-Wrapper_29.svg`}
            loading="lazy"
            alt=""
          />
        );
      }
    }
    return vEstrellas;
  };
  if (vIdCursoSeleccionado !== -1) {
    return (
      <div class="mis-cursos-alumno-info">
        {vIsLoadingLeccion ? (
          <Spin />
        ) : (
          <>
            {vInfoLeccion !== null && (
              <>
                <div class="tarjeta-2-curso">
                  <img src={imagenSrc} loading="lazy" alt="" class="img-4" />
                  <div class="tarjeta-2-curso-contenido">
                    <div class="tarjeta-2-curso-calificacion">
                      <div class="tarjeta-2-curso-estrellas">
                        {mRenderEstrellarCalificaciion(
                          vCurso.attributes.calificacion
                        )}
                      </div>
                      <div class="tarjeta-2-curso-texto-calificacion">
                        {vCurso.attributes.calificacion}
                      </div>
                    </div>
                    <div class="tarjeta-2-curso-titulos">
                      <div class="tarjeta-2-curso-titulo-contenedor-leccion">
                        <div
                          data-figma-id="117:158"
                          class="tarjeta-2-curso-titulo-leccion truncated-text"
                        >
                          {`Lección ${vInfoLeccion.lugar_lista_leccion_actual}: ${vInfoLeccion.nombre_leccion_actual}`}
                        </div>
                      </div>

                      <div class="tarjeta-2-curso-titulo-curso truncate-text1">
                        {vCurso.attributes.nombre}
                      </div>
                    </div>
                    <div data-figma-id="117:164" class="tarjeta-2-curso-info">
                      <div class="tarjeta-2-curso-contenedor-descripcion">
                        <div
                          data-figma-id="117:160"
                          class="tarjeta-2-curso-descripcion truncated-text3"
                          dangerouslySetInnerHTML={{
                            __html:
                              vCurso?.attributes?.objetivo_general.replace(
                                /\n/g,
                                "<br/>"
                              ),
                          }}
                        />
                      </div>
                      <div class="tarjeta-2-curso-tiempo">
                        Duración: {vInfoLeccion.duracion_leccion_actual} min
                      </div>
                    </div>
                    <div class="tarjeta-2-curso-boton">
                      <div data-figma-id="1:445" class="tarjeta-2-boton">
                        <Link
                          to={`/alumno?tipo=mio&cId=${vCurso.id}`}
                          className="link-style "
                          data-w-id="c9245e6b-e9db-9323-b195-efbc64f2ebce"
                        >
                          <div class="tarjeta-2-texto-boton">continuar</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {vProximasLecciones.length > 0 && (
                  <div class="apartado-tarjeta-2-lecciones">
                    <div class="apartado-tarjeta-2-titulo">
                      Próximas lecciones
                    </div>
                    {mRenderProximasLeciones()}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default function MisCrusos(props) {
  const { windowSize, openNotification, mRenderHeader } = props;
  const [vCursos, setvCursos] = useState([]);
  const [vNumCursos, setvNumCursos] = useState(0);
  const [vIdCursoSeleccionado, setvIdCursoSeleccionado] = useState(-1);
  const [vCursoSeleccionado, setvCursoSeleccionado] = useState(null);

  const [vIsLoadingCursos, setvIsLoadingCursos] = useState(true);

  useEffect(() => {
    Gets.mGetCursosAlumno()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvCursos(result.data);
        setvNumCursos(result.meta.pagination.total);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingCursos(false);
      });
  }, []);

  const mSeleccionarCurso = (vIdCS, vCurso, vEstado) => {
    console.log(vEstado)
    switch (vEstado) {
      case "sin_acceso_certificado":
      case "sin_acceso":
        /*
        openNotification(
          "Error al acceder al curso.",
          "Ya no puede acceder a este curso, su tiempo a expirado...",
          "warning"
        );
        */
        setvIdCursoSeleccionado(-1);
        setvCursoSeleccionado(null);
        break;

      default:
        setvIdCursoSeleccionado(vCurso.id);
        setvCursoSeleccionado(vCurso);
        break;
    }
  };

  const mRenderCursos = () => {
    return vCursos.map((vCurso) => (
      <CTarjetaCursoComrpado
        mSeleccionarCurso={mSeleccionarCurso}
        vIdCursoSeleccionado={vIdCursoSeleccionado}
        vCurso={vCurso}
      />
    ));
  };

  return (
    <>
      {mRenderHeader(
        "Mis  cursos",
        vIsLoadingCursos ? <Spin size="small" /> : vNumCursos
      )}

      <div
        class="mis-cursos-alumno-contenido"
        style={{ height: windowSize.height - 80 }}
      >
        <div
          class="mis-cursos-alumno-cursos"
          style={{ width: vIdCursoSeleccionado === -1 ? "100%" : "70%" }}
        >
          {vIsLoadingCursos ? <Spin /> : mRenderCursos()}
        </div>
        <CRenderInfoCursoSeleccionado
          vIdCursoSeleccionado={vIdCursoSeleccionado}
          vCurso={vCursoSeleccionado}
          {...props}
        />
      </div>
    </>
  );
}
