import React from "react";
import { useNavigate } from "react-router-dom";

import { AppstoreOutlined, SmileOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Spin } from "antd";

export default function Header_busqueda(props) {
  const { categories, mSetBusqueda, vIsLoading } = props;
  const navigate = useNavigate();
  const renderItem = (title, count) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {title}
        <span>
          <AppstoreOutlined /> {count}
        </span>
      </div>
    ),
  });
  const renderItemLoading = () => ({
    value: "Loading",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin tip="Loading " size="small" />
      </div>
    ),
  });
  const renderNotContent = () => ({
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center", color: "gray" }}>
          <SmileOutlined style={{ fontSize: 20 }} />
          <p>No hay categorías</p>
        </div>
      </div>
    ),
  });

  const mOnSelect = (value) => {
    if (categories !== undefined && !vIsLoading) {
      const categoria = categories.find(
        (entry) => entry.attributes.nombre === value
      );
      mSetBusqueda(
        {
          filter_type: "category",
          filter_value: categoria.id,
        },
        value
      );
      navigate(`/cursos/c/${categoria.id}`, { replace: true });
    }
  };

  const mRenderOptions = () => {
    if (vIsLoading) {
      return [renderItemLoading()];
    } else if (categories !== undefined) {
      return categories.map((item) =>
        renderItem(item.attributes.nombre, item.num_cursos)
      );
    } else {
      return [renderNotContent()];
    }
  };
  return (
    <AutoComplete
      options={mRenderOptions()}
      dropdownMatchSelectWidth={"100%"}
      style={{ width: "100%" }}
      onSelect={mOnSelect}
    >
      <Input.Search size="large" placeholder="Buscar" />
    </AutoComplete>
  );
}
