import React, { useEffect, useState } from "react";
import * as Gets from "../../utils/Rest apis/Gets";
import * as Variables from "../../utils/Global/Variables";
import * as Metodos from "../../utils/Global/Metodos";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import UIProfesor from "../../components/UIProfesor/UIProfesor";
import io from "socket.io-client";
import Perfil from "./Perfil";
import MisCursos from "./MisCursos";
import Home from "./Home";
import Curso from "./curso/Curso";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Profesor(props) {
  let query = useQuery();
  let vTipo = query.get("tipo");
  let vId = query.get("cId");

  const navigate = useNavigate();
  const location = useLocation();

  const [socket, setSocket] = useState(null);
  const [vJWT_Access, setvJWT_Access] = useState(null);
  const [vDatosPerfil, setvDatosPerfil] = useState(null);
  const [vIsLoadingDatosPerfil, setvIsLoadingDatosPerfil] = useState(true);

  const [vIsValidando, setvIsValidando] = useState(true);

  const [vNumCursos, setvNumCursos] = useState(0);
  const [vCalidadImagen, setVCalidadImagen] = useState(1);

  useEffect(() => {
    Gets.mValidarToken()
      .then((response) => response.json()) // 2
      .then((response) => {
        switch (response.estado) {
          case "token valido":
            if (response.role === "profesor") {
              setvIsValidando(false);
            } else {
              navigate(`/${response.role}`, { replace: true });
            }
            break;
          case "token invalido":
            break;

          default:
            navigate("/", { replace: true });
            break;
        }
      })
      .catch((error) => {
        navigate("/", { replace: true });
      })
      .finally(() => {
        // Crear y configurar el socket
        const newSocket = io(Variables.V_URL_API, {
          query: { token: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setSocket(newSocket);

        newSocket.on("connect", () => {});
        newSocket.on("disconnect", (reason) => {});
        newSocket.on("JWT_Access", function (data) {
          setvJWT_Access(data.JWT_Access);

          // Ejemplo de uso con el tamaño de tu imagen (2468861 bytes)
          const imageUrl =
            Variables.V_URL_API +
            `/api2/img?test=1&img=testVelocidad.png&ath_accss=${
              "Bearer " + data.JWT_Access
            }`;

          Metodos.testDownloadSpeed(imageUrl, 2468861)
            .then((speedMbps) => {
              const quality = Metodos.calculateImageQuality(speedMbps);

              setVCalidadImagen(quality);
            })
            .catch((error) => {
              console.error("Error al realizar la prueba de velocidad", error);
            });
        });
      });
  }, [navigate, location]);

  // Acceder /profesor
  let mvFrameHome = (windowSize) => {
    return <Home />;
  };
  // Acceder /profesor?tipo=perfil
  const mvFramePerfil = (windowSize) => {
    if (vIsLoadingDatosPerfil) {
      return <Spin />;
    }

    return (
      <Perfil
        vDatosPerfil={vDatosPerfil}
        vIsLoadingDatosPerfil={vIsLoadingDatosPerfil}
        setvDatosPerfil={setvDatosPerfil}
        {...props}
      />
    );
  };

  // Acceder /profesor?tipo=mio
  let mvFrameMisCursos = (windowSize) => {
    switch (vId) {
      case "":
        navigate("/profesor?tipo=mio", { replace: true });
        break;
      case null:
        return (
          <MisCursos
            vJWT_Access={vJWT_Access}
            vCalidadImagen={vCalidadImagen}
            windowSize={windowSize}
            {...props}
          />
        );

      default: // Acceder /profesor?tipo=mio&cId=:id
        if (/^\d+$/.test(vId)) {
          return (
            <Curso
              vIdCurso={vId}
              vCalidadImagen={vCalidadImagen}
              windowSize={windowSize}
              {...props}
            />
          );
        } else {
          navigate("/profesor?tipo=mio", { replace: true });
        }
    }
  };

  const mBody = (windowSize) => {
    switch (vTipo) {
      case Variables.vFrames.profesor.mis_cursos:
        return mvFrameMisCursos(windowSize);

      case Variables.vFrames.profesor.perfil:
        return mvFramePerfil(windowSize);

      default:
        return mvFrameHome(windowSize);
    }
  };

  if (vIsValidando) {
    return <Spin tip="Loading " />;
  }

  return (
    <UIProfesor
      location={location}
      vNumCursos={vNumCursos}
      {...props}
      vIdCurso={vId}
      vTipo={vTipo}
      vBodyContent={mBody}
      setvDatosPerfil={setvDatosPerfil}
      setvIsLoadingDatosPerfil={setvIsLoadingDatosPerfil}
    />
  );
}
