import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";

export default function Temario(props) {
  const { BotonesCurso, nombreCurso, vSecciones, windowSize } = props;
  const [vIsCargandoData, setVIsCargandoData] = useState(true);
  const [vDataPares, setVDataPares] = useState([]);
  const [vDataImpares, setVDataImpares] = useState([]);

  const miPromesa = (arr) => {
    function generarColorAleatorio() {
      // Generar un 'hue' (tono) aleatorio entre 0 y 360
      const hue = Math.floor(Math.random() * 360);

      // Establecer un rango de 'saturation' (saturación) para evitar colores muy saturados
      const saturation = Math.floor(Math.random() * 50) + 30; // entre 30% y 80%

      // Establecer un rango de 'lightness' (luminosidad) para evitar colores muy oscuros
      const lightness = Math.floor(Math.random() * 30) + 50; // entre 50% y 80%

      // Retornar el color en formato HSL
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

    return new Promise((resolve, reject) => {
      // Rechazar la promesa si arr no es un arreglo o está vacío
      if (!Array.isArray(arr) || arr.length === 0) {
        reject(
          new Error("El argumento proporcionado debe ser un arreglo no vacío.")
        );
        return; // Es importante retornar aquí para evitar que se ejecute el resto del código
      }

      const pares = [];
      const impares = [];

      arr.forEach((elemento, indice) => {
        elemento["color"] = generarColorAleatorio();
        if (indice % 2 === 0) {
          pares.push(elemento);
        } else {
          impares.push(elemento);
        }
      });

      resolve({ pares, impares });
    });
  };

  useEffect(() => {
    miPromesa(vSecciones)
      .then(({ pares, impares }) => {
        setVDataPares(pares);
        setVDataImpares(impares);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      })
      .finally(() => {
        setVIsCargandoData(false);
      });
  }, [vSecciones]);

  const mRenderSeccion = (vSeccion) => {
    return (
      <div class="div-seccion-curso-temario">
        <div>
          <div class="div-block-66">
            <div class="text-block-26" style={{ color: vSeccion.color }}>
              Seccion
            </div>
            <div
              class="text-block-26-1"
              style={{ WebkitTextStrokeColor: vSeccion.color }}
            >
              {vSeccion.num_seccion}
            </div>
          </div>
          <div class="text-block-27" style={{ color: vSeccion.color }}>
            {vSeccion.attributes.nombre}
          </div>
        </div>
        <div class="div-block-67">
          {vSeccion.attributes.lecciones.data.map((vLeccion) => (
            <div class="lecc-curso-prof">
              <div class="text-block-28">Leaccion {vLeccion.num_leccion}:</div>
              <div class="text-block-29">
                {vLeccion.attributes.nombre != null
                  ? vLeccion.attributes.nombre
                  : "-"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      class="conteniido-curso-profesor"
      style={{ height: `${windowSize.height - 80}px` }}
    >
      <div class="text-title-curso">{nombreCurso}</div>
      <BotonesCurso {...props} />

      <div class="contenedor-buscador">
        <div class="barra-de-busqueda-perfil">
          <img
            src="../images/Vectors-Wrapper_3.svg"
            loading="lazy"
            width="18"
            height="18"
            alt=""
            class="vectors-wrapper-115"
          />
          <input class="input-busqueda" />
        </div>
        {/*<div class="btn-add-modulo"><img src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65b3fa4f55a28fec7b3d97a1_Vectors-Wrapper.svg" loading="lazy" width="18" height="18" alt="" class="vectors-wrapper-186"/>
            <div class="text-132">Módulo</div>
          </div> */}
      </div>
      <div class="container-curso-profesor">
        <div class="text-133">Temario</div>
        {vIsCargandoData ? (
          <Spin />
        ) : (
          <>
            <div class="container-curso-profesor-body">
              <div class="div-contenido">
                {vDataPares.map((vSeccion) => mRenderSeccion(vSeccion))}
              </div>
              <div class="div-contenido">
                {vDataImpares.map((vSeccion) => mRenderSeccion(vSeccion))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
