/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { Input as InputAnt } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import "./style.css";

export const Inputs = ({
  nombre = "Nombre",
  value,
  tipo,
  onChange,
  placeholder = "",
}) => {
  return (
    <>
      <div className="text-input-md">{nombre}</div>

      {tipo === "normal" && (
        <InputAnt
          placeholder={placeholder}
          className="value-input-md"
          bordered={false}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      {tipo === "textarea" && (
        <InputAnt.TextArea
          placeholder={placeholder}
          className="value-input-md"
          bordered={false}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      )}

      {tipo === "password" && (
        <InputAnt.Password
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          classNames={{
            input: "value-input-md",
          }}
          bordered={false}
          iconRender={(visible) =>
            visible ? (
              <EyeOutlined
                className="value-input-md"
                style={{
                  color: "#ffffff",
                }}
              />
            ) : (
              <EyeInvisibleOutlined
                className="value-input-md"
                style={{
                  color: "#ffffff",
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

export function TextArea({
  id = null,
  nombre = "Nombre",
  placeholder = "",
  required = false,
  messageError = "",
  vIsError = false,
  onChange,
}) {
  const [vValue, setvValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setvValue(value);
    onChange(id, value);
  };
  return (
    <div className="input-md">
      <div className="text-input-md">{nombre + (required ? "*" : "")}</div>
      <div class="input-content-value-md">
        <textarea
          id={id}
          name={id}
          onChange={handleChange}
          placeholder={placeholder}
          className="value-input-md"
        />
      </div>

      <div
        class="text-input-md-error"
        style={{ width: required ? (vIsError ? "100%" : "1px") : "1px" }}
      >
        {messageError}
      </div>
    </div>
  );
}
export function Normal({
  id = null,
  nombre = "Nombre",
  placeholder = "",
  required = false,
  messageError = "",
  vIsError = false,
  onChange,
}) {
  const [vValue, setvValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setvValue(value);
    onChange(id, value);
  };
  return (
    <div className="input-md">
      <div className="text-input-md">{nombre + (required ? "*" : "")}</div>
      <div class="input-content-value-md">
        <input
          id={id}
          name={id}
          onChange={handleChange}
          placeholder={placeholder}
          className="value-input-md"
        />
      </div>

      <div
        class="text-input-md-error"
        style={{ width: required ? (vIsError ? "100%" : "1px") : "1px" }}
      >
        {messageError}
      </div>
    </div>
  );
}
export function Correo({
  id = null,
  nombre = "Nombre",
  placeholder = "",
  required = false,
  messageError = "",
  vIsError = false,
  onChange,
}) {
  const [vValue, setvValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setvValue(value);
    onChange(id, value);
  };
  return (
    <div className="input-md">
      <div className="text-input-md">{nombre + (required ? "*" : "")}</div>
      <div class="input-content-value-md">
        <input
          id={id}
          name={id}
          onChange={handleChange}
          placeholder={placeholder}
          className="value-input-md"
        />
      </div>

      <div
        class="text-input-md-error"
        style={{ width: required ? (vIsError ? "100%" : "1px") : "1px" }}
      >
        {messageError}
      </div>
    </div>
  );
}
export function Contraseña({
  id = null,
  nombre = "Nombre",
  placeholder = "",
  required = false,
  messageError = "",
  vIsError = false,
  onChange,
}) {
  const [realValue, setRealValue] = useState("");
  const [mostrarValue, setmostrarValue] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;

    setRealValue(value);
    onChange(id, value);
  };

  return (
    <div className="input-md">
      <div className="text-input-md">{nombre + (required ? "*" : "")}</div>
      <div class="input-content-value-md">
        {mostrarValue ? (
          <input
            id={id}
            name={id}
            value={realValue}
            onChange={handleChange}
            placeholder={placeholder}
            className="value-input-md"
          />
        ) : (
          <input
            id={id}
            name={id}
            value={realValue}
            onChange={handleChange}
            placeholder={placeholder}
            className="value-input-md"
            type="password"
          />
        )}

        <div
          onMouseDown={() => setmostrarValue(true)}
          onMouseUp={() => setmostrarValue(false)}
        >
          {!mostrarValue ? (
            <EyeOutlined
              onClick={() => setmostrarValue(true)}
              style={{
                color: "#ffffff",
              }}
            />
          ) : (
            <EyeInvisibleOutlined
              onClick={() => setmostrarValue(false)}
              style={{
                color: "#ffffff",
              }}
            />
          )}
        </div>
      </div>

      <div
        class="text-input-md-error"
        style={{ width: required ? (vIsError ? "100%" : "0%") : "0%" }}
      >
        {messageError}
      </div>
    </div>
  );
}
