import React, { useEffect, useRef, useState } from "react";

import * as Metodos from "../../utils/Global/Metodos";

export default function Pregunta_Respuesta(props) {
  const { vContenido, vTemario } = props;

  const [vHeigth, setvHeigth] = useState(0);
  const [vHeigth2, setvHeigth2] = useState(0);

  const refP = useRef(null);
  const ref = useRef(null);
  useEffect(() => {
    if (refP.current) {
      setvHeigth(refP.current.clientHeight);
    }
    if (ref.current) {
      setvHeigth2(ref.current.clientHeight);
    }
  }, []); // El array vacío asegura que esto solo se ejecute después del montaje inicial

  const mLineConnector = (lineConnectorTop) => {
    const dynamicStyle = {
      "--line-connector-top": `${lineConnectorTop}px`,
    };

    return <div className="line-connector" style={dynamicStyle}></div>;
  };

  


  return (
    <div data-figma-id="813:3037" class="curso-alumno-foro-info">
      <div data-figma-id="658:2660" class="perfil-usuario-1" style={{
            backgroundImage: `linear-gradient(${vContenido.attributes.alumno.data.attributes.color}, ${vContenido.attributes.alumno.data.attributes.color})`,
          }}>
        <div data-figma-id="I775:3134;658:2653" class="perfil-usuario-1-texto" style={{
              color: Metodos.getContrastYIQ(
                vContenido.attributes.alumno.data.attributes.color
              ),
            }}>
          {vContenido.attributes.alumno.data.attributes.nombre
              .toUpperCase()
              .charAt(0) +
              vContenido.attributes.alumno.data.attributes.apellidos
                .toUpperCase()
                .charAt(0)}
        </div>
      </div>
      <div
        data-figma-id="775:3135"
        class="curso-alumno-foro-pregunta-respuesta"
      >
        <div data-figma-id="775:3136" class="curso-alumno-foro-pregunta">
          <div
            data-figma-id="775:3137"
            class="curso-alumno-foro-texto-pregunta"
          >
            {vContenido.attributes.pregunta}
          </div>
          <div data-figma-id="775:3138" class="curso-alumno-foro-info-1">
            <div data-figma-id="775:3139" class="curso-alumno-foro-nombre">
            {vContenido.attributes.alumno.data.attributes.nombre +
                " " +
                vContenido.attributes.alumno.data.attributes.apellidos}
            </div>
            <img
              src="../images/circulo-gris-oscuro.svg"
              data-figma-id="793:2919"
            />
            <div data-figma-id="775:3141" class="curso-alumno-foro-clase">
            Lección {vContenido.attributes.num_leccion}
            </div>
            <img
              src="../images/circulo-gris-oscuro.svg"
              data-figma-id="793:2919"
            />
            <div data-figma-id="775:3143" class="curso-alumno-foro-dias">
            {Metodos.mTimeSince(new Date(vContenido.attributes.fecha_envio))}
            </div>
          </div>
          <div
            data-figma-id="775:3144"
            class="curso-alumno-foro--pregunta-contenido"
          >
            {vContenido.attributes.contenido}
          </div>
        </div>
        {vContenido.attributes.respuesta_profesor.length > 0 && (
        <div data-figma-id="775:3145" class="curso-alumno-foro-respuesta">
          <div data-figma-id="658:2671" class="perfil-profesor-1" 
          style={{
                  backgroundImage: `linear-gradient(${vContenido.attributes.respuesta_profesor[0].profesor.data.attributes.color}, ${vContenido.attributes.respuesta_profesor[0].profesor.data.attributes.color})`,
                }}>
            <div
              data-figma-id="I775:3146;658:2670"
              class="perfil-profesor-1-texto"
              style={{
                color: Metodos.getContrastYIQ(
                  vContenido.attributes.respuesta_profesor[0].profesor.data
                    .attributes.color
                ),
              }}
            >
              {vContenido.attributes.respuesta_profesor[0].profesor.data.attributes.nombre
                    .toUpperCase()
                    .charAt(0) +
                    vContenido.attributes.respuesta_profesor[0].profesor.data.attributes.apellidos
                      .toUpperCase()
                      .charAt(0)}
            </div>
          </div>
          <div
            data-figma-id="775:3147"
            class="curso-alumno-foro-respuesta-contenido"
          >
            <div
              data-figma-id="775:3148"
              class="curso-alumno-foro-texto-profesor"
            >
              {vContenido.attributes.respuesta_profesor[0].profesor.data
                    .attributes.nombre +
                    " " +
                    vContenido.attributes.respuesta_profesor[0].profesor.data
                      .attributes.apellidos}{" "}
                  - profesor
            </div>
            <div data-figma-id="775:3149" class="curso-alumno-foro-dias">
            {Metodos.mTimeSince(
                    new Date(
                      vContenido.attributes.respuesta_profesor[0].fecha_envio
                    )
                  )}
            </div>
            <div
              data-figma-id="775:3150"
              class="curso-alumno-foro-respuesta-texto"
            >
             {vContenido.attributes.respuesta_profesor[0].contenido}
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
