import React, { useState, useEffect, useRef } from "react";
import { Button, Form, message } from "antd";
import * as Input from "../../input_login_singup/Input";
import "./style.css";
import "../style.css";

import * as Post from "../../../utils/Rest apis/Posts";

export default function Login(props) {
  const { handleCancel, mCambiarTipo, messageApi } = props;
  const [vIsDisable, setvIsDisable] = useState(false);
  const [messageApiError, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: (
        <>
          <h4>¡Solicitud Enviada con Éxito!</h4>
          <p>
            Gracias por enviar tu solicitud para unirte a MHM Group.
            <br />
            Tu solicitud está siendo revisada por nuestro equipo de profesores.
            Una vez aprobada, recibirás una notificación para que puedas
            disfrutar de todas las funcionalidades que ofrecemos.
            <br />
            Por favor, ten en cuenta que este proceso puede tardar un poco.
            Agradecemos tu paciencia.
            <br />
            ¡Estamos emocionados de tenerte con nosotros pronto!
          </p>
        </>
      ),
      duration: 5,
    });
  };

  const formRef = useRef(null);
  const [formValues, setFormValues] = useState({
    correo: "",
    contrasenia: "",
    validarContrasenia: "",
    nombre: "",
    apellidos: "",
    email: "",
    phone: "",
    username: "",
    institucion: "",
  });

  const [errors, setErrors] = useState({
    correo: "",
    contrasenia: "",
    validarContrasenia: "",
    nombre: "",
    apellidos: "",
    email: "",
    phone: "",
    username: "",
    institucion: "",
  });

  const handleChange = (id, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      correo,
      contrasenia,
      validarContrasenia,
      nombre,
      apellidos,
      email,
      phone,
      username,
      institucion,
    } = formValues;

    let correoError = "";
    if (!correo) {
      correoError = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(correo)) {
      correoError = "Ingresa un correo válido";
    }

    let contraseniaError = "";
    if (!contrasenia) {
      contraseniaError = "La contraseña es requerida";
    }

    let validarContraseniaError = "";
    if (!validarContrasenia) {
      validarContraseniaError = "La confirmación de la contraseña es requerida";
    } else if (validarContrasenia !== contrasenia) {
      validarContraseniaError = "Las contraseñas no coinciden";
    }

    let nombreError = "";
    if (!nombre) {
      nombreError = "El nombre es requerido";
    }

    let apellidosError = "";
    if (!apellidos) {
      apellidosError = "Ingresa tus apellidos";
    }

    let emailError = "";
    if (!email) {
      emailError = "Ingresa correo electrónico";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Correo electrónico no válido";
    }

    let usernameError = "";
    if (!username) {
      usernameError = "Ingresa tu usuario";
    }

    setErrors({
      correo: correoError,
      contrasenia: contraseniaError,
      validarContrasenia: validarContraseniaError,
      nombre: nombreError,
      apellidos: apellidosError,
      email: emailError,
      phone: "",
      username: usernameError,
      institucion: "",
    });

    if (
      !correoError &&
      !contraseniaError &&
      !validarContraseniaError &&
      !nombreError &&
      !apellidosError &&
      !emailError &&
      !usernameError
    ) {
      // Aquí puedes manejar el envío del formulario, por ejemplo, enviando los datos a un servidor
      console.log("Formulario enviado:", {
        correo,
        contrasenia,
        nombre,
        apellidos,
        email,
        phone,
        username,
        institucion,
      });
      onFinish({
        correo,
        contrasenia,
        nombre,
        apellidos,
        email,
        phone,
        username,
        institucion,
      })
    }
  };

  const onFinish = (values) => {
    setvIsDisable(true);
    Post.mSignup(values)
      .then((response) => response.json())
      .then((response) => {
        if (response?.success) {
          success();
          handleCancel();
        } else {
          messageApiError.open({
            type: "error",
            content: response.error,
            duration: 5,
          });
        }
        setvIsDisable(false);
      })
      .catch((err) => setvIsDisable(false));
  };

  return (
    <>
      {contextHolder}
      <div class="div-title-md">
        <div class="subtitle-md">COMIENZA HOY</div>
        <div class="title-md">Solicita una cuenta.</div>
        <div class="div-md-more-text">
          <div class="text1-md">¿Ya tienes una cuenta?</div>
          <div class="text2-md" onClick={mCambiarTipo}>
            Inicia sesión
          </div>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit} className="form-md">
        <div className="inputs-md">
          <div className="div-input-row">
            <div className="input-md input-modal">
              <Input.Normal
                id="nombre"
                nombre="Nombre(s)"
                required={true}
                vIsError={!!errors.nombre}
                messageError={errors.nombre}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
            <div className="input-md input-modal">
              <Input.Normal
                id="apellidos"
                nombre="Apellidos"
                required={true}
                vIsError={!!errors.apellidos}
                messageError={errors.apellidos}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
          </div>
          <div className="div-input-row">
            <div className="input-md input-modal">
              <Input.Correo
                id="email"
                nombre="Correo Electrónico"
                required={true}
                vIsError={!!errors.email}
                messageError={errors.email}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
            <div className="input-md input-modal">
              <Input.Contraseña
                id="contrasenia"
                nombre="Contraseña"
                required={true}
                vIsError={!!errors.contrasenia}
                messageError={errors.contrasenia}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
          </div>
          <div className="div-input-row">
            <div className="input-md input-modal">
              <Input.Contraseña
                id="validarContrasenia"
                nombre="Rep. Contraseña"
                required={true}
                vIsError={!!errors.validarContrasenia}
                messageError={errors.validarContrasenia}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
            <div className="input-md">
              <Input.Normal
                id="phone"
                nombre="Teléfono"
                required={false}
                vIsError={!!errors.phone}
                messageError={errors.phone}
                onChange={handleChange}
                placeholder="Opcional"
              />
            </div>
          </div>
          <div className="div-input-row">
            <div className="input-md input-modal">
              <Input.Normal
                id="username"
                nombre="Usuario"
                required={true}
                vIsError={!!errors.username}
                messageError={errors.username}
                onChange={handleChange}
                placeholder="Campo obligatorio"
              />
            </div>
            <div className="input-md input-modal">
              <Input.Normal
                id="institucion"
                nombre="Institución"
                required={false}
                vIsError={!!errors.institucion}
                messageError={errors.institucion}
                onChange={handleChange}
                placeholder="Opcional"
              />
            </div>
          </div>
        </div>
        <div className="butons-md btn2-md-crr ">
          <div class="btn1-md"  onClick={handleCancel}>
            <div class="text-btn1-md">Cancelar</div>
          </div>
          <button className="btn2-md" type="submit" >
            <div class="text-btn2-md">Solicitar</div>
          </button>
        </div>
      </form>
    </>
  );
}
