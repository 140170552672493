import React, { useEffect, useState } from "react";
import * as Gets from "../../utils/Rest apis/Gets";
import * as Variables from "../../utils/Global/Variables";
import * as Metodos from "../../utils/Global/Metodos";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import UIAlumno from "../../components/UIAlumno/UIAlumno";
import CursoAlumno from "./CursoAlumno";
import MisCrusos from "./MisCrusos";
import Home from "./Home";
import Perfil from "./Perfil";
import CursoAlumnoNoComprado from "./CursoAlumnoNoComprado";
import io from "socket.io-client";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Alumno(props) {
  let query = useQuery();
  let vTipo = query.get("tipo");
  let vId = query.get("cId");

  const navigate = useNavigate();
  const location = useLocation();

  const [socket, setSocket] = useState(null);
  const [vJWT_Access, setvJWT_Access] = useState(null);

  const [vIsValidando, setvIsValidando] = useState(true);

  const [vHorasStudio, setVHorasStudio] = useState(0);
  const [vCalidadImagen, setVCalidadImagen] = useState(1);

  const [vUserMe, setVUserMe] = useState(null);
  const [vUserMeDetallesPerfil, setVUserMeDetallesPerfil] = useState({});
  const [vIsLoadingDetallesPerfil, setvIsLoadingDetallesPerfil] =
    useState(true);

  useEffect(() => {
    Gets.mValidarToken()
      .then((response) => response.json()) // 2
      .then((response) => {
        switch (response.estado) {
          case "token valido":
            setvIsValidando(false);

            break;
          case "token invalido":
            navigate("/", { replace: true });
            break;

          default:
            navigate("/", { replace: true });
            break;
        }
      })
      .catch((error) => {
        navigate("/", { replace: true });
      })
      .finally(() => {
        // Crear y configurar el socket
        const newSocket = io(Variables.V_URL_API, {
          query: { token: `Bearer ${localStorage.getItem("jwt")}` },
        });
        setSocket(newSocket);

        newSocket.on("connect", () => {});
        newSocket.on("disconnect", (reason) => {});
        newSocket.on("JWT_Access", function (data) {
          setvJWT_Access(data.JWT_Access);

          // Ejemplo de uso con el tamaño de tu imagen (2468861 bytes)
          const imageUrl =
            Variables.V_URL_API +
            `/api2/img?test=1&img=testVelocidad.png&ath_accss=${
              "Bearer " + data.JWT_Access
            }`;

          Metodos.testDownloadSpeed(imageUrl, 2468861)
            .then((speedMbps) => {
              const quality = Metodos.calculateImageQuality(speedMbps);
              setVCalidadImagen(quality);
            })
            .catch((error) => {
              console.error("Error al realizar la prueba de velocidad", error);
            });
        });
      });
  }, [location]);
  // Acceder /alumno
  let mvFrameHome = (windowSize,mRenderHeader) => {
    return (
      <Home
        {...props}
        windowSize={windowSize}
        socket={socket}
        vJWT_Access={vJWT_Access}
        vIsLoadingDetallesPerfil={vIsLoadingDetallesPerfil}
        vUserMeDetallesPerfil={vUserMeDetallesPerfil}
        vCalidadImagen={vCalidadImagen}
        mRenderHeader={mRenderHeader}
      />
    );
  };
  // Acceder /alumno?tipo=perfil
  let mvFramePerfil = (windowSize) => {
    return (
      <Perfil
        vIsLoadingDetallesPerfil={vIsLoadingDetallesPerfil}
        vUserMeDetallesPerfil={vUserMeDetallesPerfil}
        {...props}
        windowSize={windowSize}
      />
    );
  };

  // Acceder /alumno?tipo=mio
  let mvFrameMisCursos = (windowSize, mRenderHeader) => {
    switch (vId) {
      case "":
        navigate("/alumno?tipo=mio", { replace: true });
        break;
      case null:
        return (
          <MisCrusos
            {...props}
            mRenderHeader={mRenderHeader}
            vCalidadImagen={vCalidadImagen}
            windowSize={windowSize}
          />
        );

      default: // Acceder /alumno?tipo=mio&cId=:id
        if (/^\d+$/.test(vId)) {
          return (
            <CursoAlumno
              {...props}
              c={mRenderHeader}
              vCalidadImagen={vCalidadImagen}
              socket={socket}
              vId={vId}
              mRenderHeader={mRenderHeader}
              windowSize={windowSize}
            />
          );
        } else {
          navigate("/alumno?tipo=mio", { replace: true });
        }
    }
  };

  // Acceder /alumno?tipo=cnc&cId=:id
  let mvFrameCursoNoComprado = (windowSize,mRenderHeader) => {
    switch (vId) {
      case "":
        navigate("/alumno", { replace: true });
        break;
      case null:
        return mvFrameHome(windowSize);

      default:
        return (
          <CursoAlumnoNoComprado
            {...props}
            mRenderHeader={mRenderHeader}
            vCalidadImagen={vCalidadImagen}
            vId={vId}
            vIsA={true}
            vJWT_Access={vJWT_Access}
            vNombre={vUserMe?.nombre}
            vApellido={vUserMe?.apellidos}
            vCorreo={vUserMe?.email}
            vTelefono={vUserMe?.telefono}
          />
        );
    }
  };

  const mBody = (windowSize, mRenderHeader) => {
    switch (vTipo) {
      case Variables.vFrames.alumno.mis_cursos:
        return mvFrameMisCursos(windowSize, mRenderHeader);

      case Variables.vFrames.alumno.curso_no_comprado:
        return mvFrameCursoNoComprado(windowSize,mRenderHeader);
      case Variables.vFrames.alumno.perfil:
        return mvFramePerfil(windowSize, mRenderHeader);

      default:
        return mvFrameHome(windowSize,mRenderHeader);
    }
  };

  if (vIsValidando) {
    return <Spin tip="Loading " />;
  }
  return (
    <UIAlumno
      {...props}
      vIsLoadingDetallesPerfil={vIsLoadingDetallesPerfil}
      setvIsLoadingDetallesPerfil={setvIsLoadingDetallesPerfil}
      setVUserMeDetallesPerfil={setVUserMeDetallesPerfil}
      setVHorasStudio={setVHorasStudio}
      setVUserMeTmp={setVUserMe}
      vHorasStudio={vHorasStudio}
      {...props}
      vIdCurso={vId}
      vTipo={vTipo}
      vBodyContent={mBody}
      location={location}
    />
  );
}
