import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Estudiantes from "./Estudiantes";
import Temario from "./Temario";
import InfoGeneral from "./Info_General";
import Recursos from "./Recursos";
import Foro from "./Foro";
import { Spin } from "antd";

import * as Gets from "../../../utils/Rest apis/Gets";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Curso(props) {
  const { vIdCurso } = props;

  let query = useQuery();
  let vFrame = query.get("f");

  const navigate = useNavigate();

  const [vCurso, setvCurso] = useState({});
  const [JWT_Access, setJWT_Access] = useState(null);
  const [vIsLoadingCurso, setVIsLoadingCurso] = useState(true);

  useEffect(() => {
    Gets.mGetCourseProfesor(vIdCurso)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        let contadorLeccion = 1; // Comienza el contador en 1

        result.curso.data.attributes.secciones.data.forEach(
          (seccion, indice) => {
            seccion["num_seccion"] = indice + 1;
            seccion.attributes.lecciones.data.forEach((leccion) => {
              leccion.num_leccion = contadorLeccion;
              contadorLeccion++; // Incrementa el contador para la siguiente lección
            });
          }
        );
        setvCurso(result.curso.data.attributes);
        setJWT_Access(result.JWT_Access);
      })
      .catch(() => {})
      .finally(() => {
        setVIsLoadingCurso(false);
      });
  }, []);

  function BotonesCurso(props) {
    function Boton(props) {
      const { ImNormal, ImgSeleccionado, texto, seleccionado, frame } = props;

      return (
        <Link
          to={`/profesor?tipo=mio&cId=${vIdCurso}&f=${frame}`}
          className={"link-style menu-"}
        >
          <div
            class={`btn-curso-profesor${seleccionado ? "-seleccionado" : ""}`}
          >
            {seleccionado ? <ImgSeleccionado /> : <ImNormal />}

            <div class="text-126">{texto}</div>
          </div>
        </Link>
      );
    }

    return (
      <div class="div-block-64">
        <Boton
          ImNormal={() => (
            <img
              src="../images/Vectors-Wrapper_146.svg"
              loading="lazy"
              width="16.5"
              height="15.583333969116211"
              alt=""
              class="vectors-wrapper-180"
            />
          )}
          ImgSeleccionado={() => (
            <img
              src="../images/Vectors-Wrapper_150.svg"
              loading="lazy"
              width="16.5"
              height="15.583333969116211"
              alt=""
              class="vectors-wrapper-180"
            />
          )}
          texto="Estudiantes"
          seleccionado={vFrame === "ese"}
          frame={"ese"}
        />
        <Boton
          ImNormal={() => (
            <img
              src="../images/Vectors-Wrapper_147.svg"
              loading="lazy"
              width="14.666666984558105"
              height="18.333332061767578"
              alt=""
              class="vectors-wrapper-181"
            />
          )}
          ImgSeleccionado={() => (
            <img
              src="../images/Vectors-Wrapper_151.svg"
              loading="lazy"
              width="14.666666984558105"
              height="18.333332061767578"
              alt=""
              class="vectors-wrapper-181"
            />
          )}
          texto="Temario"
          seleccionado={vFrame === "tmo"}
          frame={"tmo"}
        />
        <Boton
          ImNormal={() => (
            <img
              src="../images/Vectors-Wrapper_145.svg"
              loading="lazy"
              width="22"
              height="22"
              alt=""
              class="vectors-wrapper-178"
            />
          )}
          ImgSeleccionado={() => (
            <img
              src="../images/Vectors-Wrapper_152.svg"
              loading="lazy"
              width="18.333332061767578"
              height="14.666666984558105"
              alt=""
              class="vectors-wrapper-182"
            />
          )}
          texto="Info General"
          seleccionado={vFrame === "igl"}
          frame={"igl"}
        />
        <Boton
          ImNormal={() => (
            <img
              src="../images/Vectors-Wrapper_148.svg"
              loading="lazy"
              width="18.333332061767578"
              height="16.5"
              alt=""
              class="vectors-wrapper-183"
            />
          )}
          ImgSeleccionado={() => (
            <img
              src="../images/Vectors-Wrapper_153.svg"
              loading="lazy"
              width="18.333332061767578"
              height="16.5"
              alt=""
              class="vectors-wrapper-183"
            />
          )}
          texto="Recursos"
          seleccionado={vFrame === "rus"}
          frame={"rus"}
        />
        <Boton
          ImNormal={() => (
            <img
              src="../images/Vectors-Wrapper_149.svg"
              loading="lazy"
              width="16.5"
              height="16.9213809967041"
              alt=""
              class="vectors-wrapper-184"
            />
          )}
          ImgSeleccionado={() => (
            <img
              src="../images/Vectors-Wrapper_154.svg"
              loading="lazy"
              width="16.5"
              height="16.9213809967041"
              alt=""
              class="vectors-wrapper-184"
            />
          )}
          texto="Foro"
          seleccionado={vFrame === "fro"}
          frame={"fro"}
        />
      </div>
    );
  }

  if (vIsLoadingCurso) {
    return <Spin />;
  }

  // Acceder /profesor?tipo=mio&cId=:id&f=:frame
  switch (vFrame) {
    case "ese":
      return (
        <Estudiantes
          vIdCurso={vIdCurso}
          BotonesCurso={BotonesCurso}
          nombreCurso={vCurso.nombre}
          {...props}
        />
      );
    case "tmo":
      return (
        <Temario
          vIdCurso={vIdCurso}
          BotonesCurso={BotonesCurso}
          nombreCurso={vCurso.nombre}
          vSecciones={vCurso.secciones.data}
          {...props}
        />
      );

    case "igl":
      return (
        <InfoGeneral
          vIdCurso={vIdCurso}
          BotonesCurso={BotonesCurso}
          nombreCurso={vCurso.nombre}
          vCurso={vCurso}
          {...props}
        />
      );

    case "rus":
      return (
        <Recursos
          vIdCurso={vIdCurso}
          BotonesCurso={BotonesCurso}
          nombreCurso={vCurso.nombre}
          vSecciones={vCurso.secciones.data}
          vJWT_Access={JWT_Access}
          {...props}
        />
      );

    case "fro":
      return (
        <Foro
          vIdCurso={vIdCurso}
          BotonesCurso={BotonesCurso}
          nombreCurso={vCurso.nombre}
          {...props}
        />
      );

    case "":
      navigate(`/profesor?tipo=mio&cId=${vIdCurso}`, { replace: true });
      break;
    case null:
    default:
      navigate(`/profesor?tipo=mio&cId=${vIdCurso}&f=ese`, {
        replace: true,
      });
  }
}
