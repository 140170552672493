import * as Variables from "../Global/Variables";

export async function mGetValidarApi1() {
  return await fetch(Variables.V_URL_API + "/api/categories", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }); // 1
  // 3
}

export async function mGetValidarApi2() {
  return await fetch(Variables.V_URL_API + "/api2/categorias", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }); // 1
  // 3
}

// Función para cargar la imagen y convertirla a base64
export async function cargarImagen(url) {
  try {
    const respuesta = await fetch(url);
    const blobImagen = await respuesta.blob(); // Obtener la imagen como Blob

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Convertir Blob a base64
      reader.onerror = reject;
      reader.readAsDataURL(blobImagen);
    });
  } catch (error) {
    console.error("Error al cargar la imagen:", error);
    return null;
  }
}

export async function mGetQuote(setvQuote, setvIsCargando) {
  await fetch(Variables.V_URL_API + "/api2/random-quote", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }) // 1
    .then((response) => response.json()) // 2
    .then((response) => {
      setvQuote(response);
      setvIsCargando(false);
    })
    .catch((err) => err); // 3
}
export async function mGetPopularCourses() {
  return await fetch(Variables.V_URL_API + "/api2/cursos-populares", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }); // 1
  // 3
}
export async function mGetCategories() {
  return await fetch(Variables.V_URL_API + "/api2/categorias", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }); // 1
  // 3
}
export async function mGetCategoria(vId) {
  return await fetch(Variables.V_URL_API + "/api/categories/" + vId, {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }); // 1
  // 3
}
export async function mGetCourse(vCursoId = 0) {
  return await fetch(
    Variables.V_URL_API + `/api2/obtener-curso-unico?id_curso=${vCursoId}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export async function mGetCourseAlumno(vCursoId = 0) {
  return await fetch(
    Variables.V_URL_API +
      `/api2/obtener-curso-unico-alumno?id_curso=${vCursoId}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
      },
    }
  );
}
export async function mGetCourses(
  filter_type = "all",
  filter_value = " ",
  page = "1"
) {
  if (filter_type === "all") {
    return await fetch(
      Variables.V_URL_API +
        "/api/courses?populate=*&pagination[pageSize]=10&pagination[page]=" +
        page,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } else {
    return await fetch(
      Variables.V_URL_API +
        "/api/courses?filters[categorias][id][$eq]=" +
        filter_value +
        "&populate=*",
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export async function mGetSeccionesPorIdCurso(vCursoId = 0) {
  return await fetch(
    Variables.V_URL_API +
      `/api/sections?populate=lecciones&filters[curso][id][$eq]=${vCursoId}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export async function mGetComentarioCursoPorIdCurso(vCursoId = 0) {
  return await fetch(
    Variables.V_URL_API +
      `/api/course-comments?populate=*&filters[curso][id][$eq]=${vCursoId}`,
    {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export async function mGetContactoInfo() {
  return await fetch(Variables.V_URL_API + "/api/contacto", {
    mode: "cors",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
export async function mValidarToken() {
  return await fetch(Variables.V_URL_API + "/api2/v-jwt", {
    mode: "cors",
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/json",
    },
  });
}
export async function mUserMe() {
  return await fetch(Variables.V_URL_API + "/api/users/me", {
    mode: "cors",
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/json",
    },
  });
}
export async function mUserMeDetallesPerfil() {
  return await fetch(Variables.V_URL_API + "/api2/detalles-perfil", {
    mode: "cors",
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      "Content-Type": "application/json",
    },
  });
}
export async function mLeccionActual(vIdCurso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API + `/api2/obtener-leccion-actual?id_curso=${vIdCurso}`,
    requestOptions
  );
}
export async function mGetEvaluacionLeccion(
  vIdLeccion,
  vIdLeccionStudentProgresses
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API +
      `/api2/evaluacion-leccion?id_leccion=${vIdLeccion}&idleccion_student_progresses=${vIdLeccionStudentProgresses}`,
    requestOptions
  );
}

export async function mGetForoCurso(vIdCurso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API + `/api2/doubts-curse?id_curso=${vIdCurso}`,
    requestOptions
  );
}
export async function mGetRecursosLeccionA(id_curso, id_leccion) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API +
      `/api2/obtener-recursos-leccion?id_leccion=${id_leccion}&id_curso=${id_curso}`,
    requestOptions
  );
}

export async function mGetCursosAlumno() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API + `/api2/course-students`,
    requestOptions
  );
}

export async function mDescargarRecursoA(file, id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API +
      `/api2/descargar-recurso?file=${file}&id_curso=${id_curso}`,
    requestOptions
  );
}
export async function mGetEnviarRespuestasEvaluacion(
  id_leccion,
  id_curso,
  respuestas
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };
  const jsonStr = JSON.stringify(respuestas);
  const queryString = `json=${encodeURIComponent(jsonStr)}`;

  return await fetch(
    Variables.V_URL_API +
      `/api2/califica-evaluacion?id_leccion=${id_leccion}&id_curso=${id_curso}&${queryString}`,
    requestOptions
  );
}
export async function mGetPerfil() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(Variables.V_URL_API + `/api2/perfil`, requestOptions);
}
export async function mGetLeccionesMisCursos(vIdCurso, numero_lecciones) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API +
      `/api2/lecciones-mis-cursos?id_curso=${vIdCurso}&numero_lecciones=${numero_lecciones}`,
    requestOptions
  );
}

export async function mGetSummary() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(Variables.V_URL_API + `/api2/summary`, requestOptions);
}

export async function mGetCursosVencerA() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API + `/api2/cursos-por-vencer`,
    requestOptions
  );
}
export async function mGetMisCursosProfesor() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    Variables.V_URL_API + `/api2/mis-cursos-profesor`,
    requestOptions
  );
}
export async function mGetConteoAlumnosCursoProfesor(id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/cursos-profesor-conteo-alumnos?id_curso=${id_curso}`,
    requestOptions
  );
}
export async function mGetEstudiantesCursoProfesor(id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/resumem-por-curso?id_curso=${id_curso}`,
    requestOptions
  );
}

export async function mGetEstudianteCursoProfesor(id_curso, vIdEstudiante) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/resumen-curso?id_curso=${id_curso}&id_estudiante=${vIdEstudiante}`,
    requestOptions
  );
}

export async function mGetCourseProfesor(id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/curso-profesor?id_curso=${id_curso}`,
    requestOptions
  );
}
export async function mGetPreguntasForoProfesor(id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/home-profesor-foro?id_curso=${id_curso}`,
    requestOptions
  );
}
export async function mGetHomeProfesor() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/home-profesor`,
    requestOptions
  );
}
export async function mGetCertificadoCurso(id_curso) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    mode: "cors",
  };

  return await fetch(
    `${Variables.V_URL_API}/api2/certificado-modal?id_curso=${id_curso}`,
    requestOptions
  );
}
