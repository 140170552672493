import React, { useEffect } from "react";
import "./style.css";

const getRandom = (min, max) => Math.random() * (max - min) + min;

const Particles = ({ children, className }) => {
  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      createParticle();
    }
  }, []);

  const createParticle = () => {
    const particle = document.createElement("div");
    particle.classList.add("particle");
    document.querySelector(".particle-container").appendChild(particle);

    const size = getRandom(1, 7);
    const startX = getRandom(0, window.innerWidth - size);
    const duration = getRandom(5, 10);

    particle.style.width = `${size}px`;
    particle.style.height = `${size}px`;
    particle.style.left = `${startX}px`;
    particle.style.bottom = `-${size}px`;
    particle.style.background = `rgba(255, 255, 255, ${getRandom(0.1, 0.6)})`;
    particle.style.animationDuration = `${duration}s`;

    particle.addEventListener("animationend", () => {
      particle.remove();
      createParticle();
    });
  };

  return (
    <section className={`particle-container ${className ? className : ""}`}>
      {children}
    </section>
  );
};

export default Particles;
