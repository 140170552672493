import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as Metodos from "../../utils/Global/Metodos";
import * as Gets from "../../utils/Rest apis/Gets";
import { Badge, Popover, Tooltip } from "antd";

import "./style.css";

function CMenuProfesor(props) {
  const { vNum, vTipo, vIdCurso } = props;

  return (
    <div class="menu-3">
      <img
        src="../images/image-1_1.png"
        loading="lazy"
        width="150"
        height="37.5"
        alt=""
        class="image-1"
      />
      <div class="menu-lateral">
        <Link
          to="/profesor"
          className={`link-style menu-item${
            vTipo === null ? "-seleccionado" : ""
          }`}
        >
          <img
            src="../images/Vectors-Wrapper_41.svg"
            loading="lazy"
            width="25"
            height="25"
            alt=""
            class="vectors-wrapper-47"
          />
          <div class="item">Home</div>
        </Link>
        <Link
          to="/profesor?tipo=mio"
          className={`link-style menu-item${
            vTipo === "mio" && vIdCurso === null ? "-seleccionado" : ""
          }`}
        >
          <img
            src="../images/Vectors-Wrapper_42.svg"
            loading="lazy"
            width="25"
            height="25"
            alt=""
            class="vectors-wrapper-47"
          />
          <div class="item">Mis Cursos</div>
        </Link>
        <Link
          to="/profesor?tipo=perfil"
          className={`link-style menu-item${
            vTipo === "perfil" ? "-seleccionado" : ""
          }`}
        >
          <img
            src="../images/Vectors-Wrapper_43.svg"
            loading="lazy"
            width="25"
            height="25"
            alt=""
            class="vectors-wrapper-47"
          />
          <div class="item">Perfil</div>
        </Link>
      </div>
      <div class="frame-14">
        <div class="cuadro-imagen">
          <img
            src="../images/Vectors-Wrapper_44.svg"
            loading="lazy"
            width="223"
            height="317"
            alt=""
            class="vectors-wrapper-48"
          />
          <img
            src="../images/imagen_4.png"
            loading="lazy"
            width="199"
            height="206"
            alt=""
            class="imagen"
          />
          <div class="texto">
            <div class="text-38">{vNum}</div>
            <div class="div-block-9">
              <div class="text-hora-estudio">
                {vNum % 2 === 0 ? "cursos" : "curso"} impartidos
              </div>
              <div class="text-hora-estudio-2">¡Sigue asi!</div>
            </div>
          </div>
        </div>
      </div>
      <Link
        to="/"
        onClick={() => {
          localStorage.clear();
        }}
        className="link-style menu-item"
      >
        <img
          src="../images/Vectors-Wrapper_45.svg"
          loading="lazy"
          width="25"
          height="25"
          alt=""
          class="vectors-wrapper-47"
        />
        <div class="item">Log Out</div>
      </Link>
    </div>
  );
}

export default function UIAlumno(props) {
  const {
    vBodyContent,
    vTipo,
    openNotification,
    setvDatosPerfil,
    setvIsLoadingDatosPerfil,
    location,
  } = props;
  const navigate = useNavigate();

  const [vUserMe, setVUserMe] = useState({});

  const [vIsLoading, setVIsLoading] = useState(true);
  const [vNumNotificaciones, setvNumNotificaciones] = useState(0);
  const [vNumCursos, setvNumCursos] = useState(0);

  const [open, setOpen] = useState(false);

  // Estado para almacenar las dimensiones actuales de la ventana
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const mGetDetallesPerfil = (response) => {
    Gets.mUserMeDetallesPerfil()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        response["detalles_perfil"] = result[0];
        setvDatosPerfil(response);
        setvNumCursos(response.detalles_perfil.total_cursos);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingDatosPerfil(false);
      });
  };

  useEffect(() => {
    Gets.mUserMe()
      .then((response) => {
        if (!response.ok) {
          openNotification(
            "Congruencia en datos del perfil",
            "Se produjo un error al momento de cargar los datos del perfil.",
            "error"
          );
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {
        localStorage.setItem("id_profesor", response?.id);
        localStorage.setItem("nombre_p", response?.nombre);
        localStorage.setItem("apellidos_p", response?.apellidos);
        localStorage.setItem("color_p", response?.color);
        setVUserMe(response);

        mGetDetallesPerfil(response);
      })
      .catch((error) => {
        navigate("/", { replace: true });
      })
      .finally(() => {
        setVIsLoading(false);
      });

    // Función manejadora del cambio de tamaño
    function handleResize() {
      // Establece las nuevas dimensiones en el estado
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    document.body.style.overflow = "hidden";

    // Agrega el event listener
    window.addEventListener("resize", handleResize);

    // Función de limpieza para eliminar el event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [navigate, openNotification, location]); // El array vacío asegura que el efecto se ejecute una sola vez

  const mRednerContenidoDatosUser = (hide) => {
    return (
      <div class="menu-perfil---confirmado">
        <img
          src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f879b928bf50fce6815_Vectors-Wrapper.svg"
          loading="lazy"
          width="20"
          height="20"
          alt=""
          class="vectors-wrapper-190"
          onClick={hide}
        />
        <div class="text-138">{`${vUserMe.nombre} ${vUserMe.apellidos}`}</div>
        <div class="frame-167">
          <div class="text-139">Correo</div>
          <div class="text-140">{`${Metodos.ocultarCorreo(
            vUserMe.email
          )}`}</div>
        </div>
        <div class="frame-167">
          <div class="text-139">Teléfono</div>
          <div class="text-140">{`${
            vUserMe.telefono === null ? "----" : vUserMe.telefono
          }`}</div>
        </div>
        <div class="div-block-73"></div>
        <div class="frame-167">
          <div class="text-139">Usuario</div>
          <div class="text-140">{`${vUserMe.username}`}</div>
        </div>
        <div class="div-block-73"></div>
        <div class="frame-167">
          <div class="text-139">Contraseña</div>
          <div class="frame-244">
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88379efa56d844303c_Vectors-Wrapper.svg"
              loading="lazy"
              width="10"
              height="12"
              alt=""
              class="vectors-wrapper-192"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
              loading="lazy"
              width="7"
              height="7"
              alt=""
              class="vectors-wrapper-193"
            />
          </div>
        </div>
        <Link
          to="/profesor?tipo=perfil"
          onClick={hide}
          className={`link-style`}
        >
          <div class="frame-248">
            <div class="text-141">Editar</div>
          </div>
        </Link>
      </div>
    );
  };

  const mRenderAvatar = () => {
    const hide = () => {
      setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };

    if (vIsLoading) {
      return (
        <div
          class="div-block-10"
          style={{
            backgroundImage: `linear-gradient(#fff, #fff)`,
          }}
        >
          <div
            class="text-perfil"
            style={{ color: Metodos.getContrastYIQ("#ffffff") }}
          >
            LG
          </div>
        </div>
      );
    } else {
      return (
        <Popover
          content={mRednerContenidoDatosUser(hide)}
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          arrow={false}
          placement="bottomRight"
          style={{ background: "red" }}
        >
          <Tooltip
            title={
              vNumNotificaciones > 99
                ? "Tienes muchas notificaciones"
                : `Tienes ${vNumNotificaciones} ` +
                  (vNumNotificaciones > 1 || vNumNotificaciones === 0
                    ? "notificaciones"
                    : "notificación")
            }
          >
            <Badge count={vNumNotificaciones}>
              <div
                onClick={() => {
                  setOpen(true);
                }}
                class="div-block-10"
                style={{
                  backgroundImage: `linear-gradient(${localStorage.getItem(
                    "color_p"
                  )}, ${localStorage.getItem("color_p")})`,
                }}
              >
                <div
                  class="text-perfil"
                  style={{
                    color: Metodos.getContrastYIQ(
                      localStorage.getItem("color_p")
                    ),
                  }}
                >
                  {localStorage.getItem("nombre_p").toUpperCase().charAt(0) +
                    localStorage.getItem("apellidos_p").toUpperCase().charAt(0)}
                </div>
              </div>
            </Badge>
          </Tooltip>
        </Popover>
      );
    }
  };

  return (
    <body class="body-3" style={{ height: windowSize.height }}>
      <CMenuProfesor {...props} vNum={vIsLoading ? "0" : vNumCursos} />
      <div class="body-2" style={{ height: windowSize.height }}>
        {vTipo !== "perfil" && <div class="header-2">{mRenderAvatar()}</div>}
        <div class={vTipo === "perfil" ? "frame-31" : "frame-30"}>
          {vBodyContent(windowSize)}
        </div>
      </div>
    </body>
  );
}
