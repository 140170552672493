import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./style.css";

import * as Variables from "../../utils/Global/Variables";
import * as Metodos from "../../utils/Global/Metodos";

export default function Curso(props) {
  const { setvFrame, setvCourse, vCurso } = props;
  const [vCursoF, setvCursoF] = useState(vCurso);
  const mCabiarFrame = () => {
    setvCourse(vCurso);
    setvFrame(Variables.vFrames.course_not_purchased);
  };
  const [vIsHover, setvIsHover] = useState(false);

  console.log(
    Date.now(),
    new Date(vCursoF.attributes.fecha_disponibilidad),
    Date.now() >= new Date(vCursoF.attributes.fecha_disponibilidad)
  );

  useEffect(() => {
    setvCursoF({
      ...vCurso,
      num_secciones: vCurso.attributes.secciones.data.length,
    });
  }, []);

  return (
    <div className="tarjeta-curso-1 ">
      <div className="card-container" key={Date.now()}>
        <div className={"card-face front-face"}>
          <img
            src={
              Variables.V_URL_API +
              vCursoF.attributes.img_card?.data?.attributes.url
            }
            loading="lazy"
            width="280"
            height="130"
            alt=""
            class="tarjeta-curso-1-imagen"
          />
          <div class="tarjeta-curso-1-contenido">
            <div class="tarjeta-curso-1-header">
              <aside class="tarjeta-curso-1-titulo truncated-text">
                {vCursoF.attributes.nombre}
              </aside>
              <div class="tarjeta-curso-1-profesor">Nivel Introductorio</div>
            </div>
            <div class="tarjeta-curso-1-header">
              <div class="tarjeta-curso-1-info-1">
                <div class="tarjeta-curso-1-calificacion">
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_169.svg`}
                    loading="lazy"
                    width="20"
                    height="19"
                    alt=""
                    class="vectors-wrapper-220"
                  />
                  <div class="text-176">{vCursoF.attributes.calificacion}</div>
                </div>
                <div class="tarjeta-curso-1-calificacion">
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_167.svg`}
                    loading="lazy"
                    width="22"
                    height="18"
                    alt=""
                    class="vectors-wrapper-221"
                  />
                  <div class="graduados-2">
                    {Metodos.formatNumber(vCursoF.attributes.total_graduados)}{" "}
                    graduados
                  </div>
                </div>
              </div>
              <div class="tarjeta-curso-1-info-1">
                <div class="tarjeta-curso-1-horas">
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_166.svg`}
                    loading="lazy"
                    width="16.666667938232422"
                    height="16.666667938232422"
                    alt=""
                    class="vectors-wrapper-222"
                  />
                  <div class="horas-4">
                    {Metodos.convertirYDenominar(vCursoF.attributes.duracion)[0] +
                      " " +
                      Metodos.convertirYDenominar(vCursoF.attributes.duracion)[1]}
                  </div>
                </div>
                <div class="tarjeta-curso-1-lecciones">
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_170.svg`}
                    loading="lazy"
                    width="22"
                    height="16"
                    alt=""
                    class="vectors-wrapper-223"
                  />
                  <div class="horas-4">{vCursoF.num_secciones} lecciones</div>
                </div>
              </div>
            </div>
            <div class="tarjeta-1-curso-estatus">
              {Date.now() >=
              new Date(vCursoF.attributes.fecha_disponibilidad) ? (
                <div class="tarjeta-1-curso-texto-disponible">
                  Disponible ahora
                </div>
              ) : (
                <div class="tarjeta-1-curso-texto-disponible-prox">
                  Disponible próximamente
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={"card-face back-face"}>
          <div class="tarjeta-curso-1-contenido-2">
            <div class="tarjeta-curso-1-header">
              <div class="tarjeta-curso-1-titulo truncated-text">
                {vCursoF.attributes.nombre}
              </div>
              <div class="tarjeta-curso-1-profesor">Nivel Introductorio</div>
            </div>
            <div class="tarjeta-curso-1-descripcion">
              <div
                class="text-177"
                dangerouslySetInnerHTML={{
                  __html: vCursoF?.attributes?.objetivo_general.replace(
                    /\n/g,
                    "<br/>"
                  ),
                }}
              />
            </div>
            <div class="tarjeta-curso-1-precio">
              <div class="botones-4" onClick={() => setvFrame()}>
                <Link to={`/curso/${vCursoF.id}`} className="link-style">
                  <div class="texto-5">Ver Curso</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
