import React, { useEffect, useRef, useState } from "react";
import UIAlumno from "../../components/UIAlumno/UIAlumno";
import TemarioForo from "../../components/temario-foro/TemarioForo";
import AEvaluacion from "../../components/a_evaluacion_leccion/AEvaluacion";
import CursoInfoRecursos from "../../components/a_curso_info_recursos/CursoInfoRecursos";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import * as Metodos from "../../utils/Global/Metodos";
import * as Variables from "../../utils/Global/Variables";
import * as Gets from "../../utils/Rest apis/Gets";
import * as Posts from "../../utils/Rest apis/Posts";
import Pregunta_Respuesta from "../../components/temario-foro/Pregunta_Respuesta";

import "./style.css";
import { Spin, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CursoAlumno(props) {
  const navigate = useNavigate();
  let query = useQuery();
  let vIdLeccion = query.get("lecId");
  const {
    vId,
    windowSize,
    socket,
    vCalidadImagen,
    openNotification,
    mRenderHeader,
  } = props;

  const [vIsVisibleBtnSiguiente, setVIsVisibleBtnSiguiente] = useState(true);

  const [vIsCuestionario, setVIsCuestionario] = useState(false);
  const [vCurso, setvCurso] = useState({});
  const [vLeccionCargar, setvLeccionCargar] = useState(undefined);
  const [vEvaluacion, setvEvaluacion] = useState({});
  const [vTemario, setvTemario] = useState({});
  const [vForo, setvForo] = useState({});
  const [vRecursos, setvRecursos] = useState({});
  const [vProfesor, setVProfesor] = useState({});

  const [vIsLoadingEvaluacion, setvIsLoadingEvaluacion] = useState(true);
  const [vIsLoadingRecursos, setvIsLoadingRecursos] = useState(true);
  const [vIsLoadingCurso, setVIsLoadingCurso] = useState(true);
  const [vIsLoadingTemario, setVIsLoadingTemario] = useState(true);
  const [vIsLoadingForo, setVIsLoadingForo] = useState(true);
  const [vIsLoadingDataLeccion, setVIsLoadingDataLeccion] = useState(true);

  const [vCurrentTimeTmpVideoActual, setvCurrentTimeTmpVideoActual] =
    useState(0);

  const [vDataLeccion, setvDataLeccion] = useState({
    idLeccionActual: 0,
    nombreLeccionActual: "",
    numLeccionActual: 0,
    currentTimeLeccionActual: 0,
    idleccion_student_progresses: 0,
    idLeccionSiguiente: null,
    numLeccionSiguiente: 0,
    totalLecciones: 0,
    isContinuar: false,
  });

  useEffect(() => {
    if (vIdLeccion !== null) {
      if (vIdLeccion.length > 0) {
        setvLeccionCargar(vIdLeccion);
      }
    }
    Gets.mGetCourseAlumno(vId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        if (result.data.length > 0) {
          setvCurso(result.data[0].attributes);
          setVProfesor(result.data[0].attributes.profesor.data.attributes);
          mGetLeccionActual(
            result.data[0].id,
            result.data[0].attributes.secciones.data
          );
        } else {
          openNotification(
            "Upssss!!!, Curso no disponible.",
            "Actualmente el curso no se encuentra disponible para su visualización, regrese mas tarde.",
            "error"
          );
          navigate("/alumno?tipo=mio", { replace: true });
        }
      })
      .catch(() => {})
      .finally(() => {
        setVIsLoadingCurso(false);
      });
    mGetForo();
  }, []);

  const sendInfo = (currentTime, durationTotal) => {
    if (socket) {
      var vMiProgreso = {
        id_curso: vId,
        id_leccion_student: vDataLeccion.idLeccionActual,
        porcentaje: Math.floor(
          ((vDataLeccion.numLeccionActual - 1) / vDataLeccion.totalLecciones) *
            100
        ),
        nombre_curso: vCurso.nombre,
        lecciones: `${vDataLeccion.numLeccionActual}/${vDataLeccion.totalLecciones}`,
        total_tiempo_video: durationTotal,

        progreso_video: currentTime,
      };

      socket.emit("update_progress_video_student", {
        data: {
          idleccion_student_progresses:
            vDataLeccion.idleccion_student_progresses,
          idCurso: vId,
          currentTime: currentTime,
          miProgreso: vMiProgreso,
          isLeccionActual: !vDataLeccion.isContinuar,
        },
      });
      setvCurrentTimeTmpVideoActual(currentTime);
    }
  };
  const sendInfoByEvaluacion = () => {
    if (socket) {
      if (vDataLeccion.idLeccionSiguiente != null) {
        var vMiProgreso = {
          id_curso: vId,
          id_leccion_student: vDataLeccion.idLeccionSiguiente,
          porcentaje: Math.floor(
            (vDataLeccion.numLeccionActual / vDataLeccion.totalLecciones) * 100
          ),
          nombre_curso: vCurso.nombre,
          lecciones: `${vDataLeccion.numLeccionSiguiente}/${vDataLeccion.totalLecciones}`,
          total_tiempo_video: 0,
          progreso_video: 0,
        };

        socket.emit("update_progress_video_student", {
          data: {
            idleccion_student_progresses:
              vDataLeccion.idleccion_student_progresses,
            idCurso: vId,
            currentTime: 0,
            miProgreso: vMiProgreso,
            isLeccionSiguiente: true,
          },
        });
      }
    }
  };

  const mGetForo = () => {
    Gets.mGetForoCurso(vId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvForo(result);
      })
      .catch(() => {})
      .finally(() => {
        setVIsLoadingForo(false);
      });
  };

  const mGetLeccionActual = (vIdCurso, vSecciones) => {
    Gets.mLeccionActual(vIdCurso)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        if (
          (Object.keys(result).includes("Error") &&
            result.Error === "Curso no encontrado en tu lista de cursos.") ||
          result.Error === "Error al asignar la leccion a tu nuevo curso."
        ) {
          openNotification("Error al cargar el curso", result.Error, "error");
          navigate("/alumno?tipo=mio", { replace: true });
          return <></>;
        }
        var vLeccionActual = null;
        var vNumLecciones = 0;
        var vIsCorrectLeccion = false;
        var vIsLeccionesTerminadas = false;

        const nuevoObjeto = vSecciones.map((seccion) => {
          const { id, attributes } = seccion;
          const nombre = attributes.nombre;
          const lecciones = attributes.lecciones.data.map((lecc) => {
            vNumLecciones += 1;

            return {
              id: lecc.id,
              duracion: lecc.attributes.duracion,
              nombre: lecc.attributes.nombre,
              ruta: lecc.attributes.ruta,
              contenido: lecc.attributes.contenido,
              num_leccion: vNumLecciones,
              img: lecc.attributes?.img_portada?.data?.attributes.url,
            };
          });
          // Agregar progreso y terminado si existe una coincidencia
          //Lecciones totales del curso

          vIsLeccionesTerminadas = result.data[0].attributes.terminado;
          //result.data[result.data.length - 1].attributes.terminado;
          lecciones.forEach((lecc) => {
            //Result son las lecciones actuales que lleva cursadas.
            result.data.forEach((item) => {
              const cursoLeccionId = item.attributes.curso_leccion.data.id;

              if (vIdLeccion !== null) {
                if (vIdLeccion.length > 0) {
                  if (cursoLeccionId.toString() === vIdLeccion.toString()) {
                    vIsCorrectLeccion = true;
                    vLeccionActual = vIdLeccion;
                  }
                }
              }
              if (cursoLeccionId === lecc.id) {
                lecc.progreso = item.attributes.progreso;
                lecc.terminado = item.attributes.terminado;
                lecc.currentTime = item.attributes.progreso_video;
                lecc.video =
                  item.attributes.curso_leccion.data.attributes.ruta_video;
                lecc.idleccion_student_progresses = item.id;
                if (
                  (vIdLeccion === null || vIdLeccion === "") &&
                  !item.attributes.terminado
                ) {
                  vIsCorrectLeccion = true;
                  vLeccionActual = lecc.id;
                  setvLeccionCargar(lecc.id);
                }
              }
            });
          });

          return {
            id,
            nombre,
            totalLecciones: lecciones.length,
            lecciones,
          };
        });

        var vUltimaLeccion = null;

        if (vIsLeccionesTerminadas) {
          let vUltimaSeccion = nuevoObjeto[nuevoObjeto.length - 1];
          var vUltimaLeccionTmp =
            vUltimaSeccion.lecciones[vUltimaSeccion.lecciones.length - 1];
          if (vUltimaLeccionTmp.id === vLeccionActual) {
            vUltimaLeccion = vUltimaLeccionTmp;
            vIsCorrectLeccion = true;

            vLeccionActual = vUltimaLeccion.id;
            setvLeccionCargar(vUltimaLeccion.id);
          }
        }
        if (!vIsCorrectLeccion) {
          navigate(`/alumno?tipo=mio&cId=${vId}`, { replace: true });
          window.location.reload();
        }
        if (vIsCorrectLeccion) {
          if (vIdLeccion !== null) {
            if (vIdLeccion.length === 0) {
              navigate(`/alumno?tipo=mio&cId=${vId}&lecId=${vLeccionActual}`, {
                replace: true,
              });
              window.location.reload();
            }
          }
        }

        setvTemario(nuevoObjeto);
        mGetRecursosLeccion(vIdCurso, vLeccionActual);
        mGuardarDataLeccion(
          nuevoObjeto,
          result.JWT_Access,
          vUltimaLeccion,
          vIsLeccionesTerminadas
        );
      })
      .catch(() => {})
      .finally(() => {
        setVIsLoadingTemario(false);
      });
  };

  const mGuardarDataLeccion = (
    nuevoObjeto,
    vJWT_Access,
    vUltimaLeccion,
    vIsLeccionesTerminadas
  ) => {
    var vIdLeccionActual = -1,
      vNombreLeccionActual = "",
      vNumLeccionActual = 0,
      vIdLeccionSiguiente = null,
      vNumLeccionSiguiente = 0,
      vNumLeccionCurrent = 0,
      vTotalLecciones = 0,
      vIsContinuar = false,
      vIdLeccionStudentProgresses = 0,
      vCurrentTimeLeccionActual = 0,
      vImg = "",
      vVideo = "";

    const mSacarDatosLeccion = (vLeccion) => {
      vIdLeccionActual = vLeccion.id;
      vVideo = vLeccion.video;
      vImg = vLeccion.img;
      vNombreLeccionActual = vLeccion.nombre;
      vNumLeccionActual = vLeccion.num_leccion;
      vCurrentTimeLeccionActual = vLeccion.currentTime;
      vIdLeccionStudentProgresses = vLeccion.idleccion_student_progresses;
    };

    var vIsEncontrado = false;
    nuevoObjeto.forEach((vSeccion) => {
      const { lecciones } = vSeccion;
      vTotalLecciones = vTotalLecciones + lecciones.length;
      if (vUltimaLeccion !== null) {
        if (vIdLeccionActual === -1) {
          mSacarDatosLeccion(vUltimaLeccion);
        }
      } else {
        if (vIdLeccionSiguiente === null) {
          for (let vLeccion of lecciones) {
            if (vIdLeccionSiguiente == null) {
              switch (vLeccion?.terminado) {
                case true:
                  if (vIdLeccion !== null) {
                    if (vIdLeccion.length > 0) {
                      if (vLeccion.id.toString() === vIdLeccion.toString()) {
                        vIsEncontrado = true;
                        mSacarDatosLeccion(vLeccion);
                      }
                    }
                  }
                  break;
                case false:
                  if (!vIsEncontrado) {
                    vNumLeccionCurrent = vLeccion.num_leccion;
                    mSacarDatosLeccion(vLeccion);
                  } else {
                    if (vIdLeccionSiguiente == null) {
                      vIdLeccionSiguiente = vLeccion.id;
                      vNumLeccionSiguiente = vLeccion.num_leccion;
                      vNumLeccionCurrent = vLeccion.num_leccion;
                      vIsContinuar = true;
                    }
                  }
                  break;

                default:
                  if (vIdLeccionSiguiente == null && vUltimaLeccion === null) {
                    vIdLeccionSiguiente = vLeccion.id;
                    vNumLeccionSiguiente = vLeccion.num_leccion;
                  }
                  break;
              }
            } else {
              break;
            }
          }
        }
      }
    });
    if (vIdLeccionActual === -1) {
      var vPrimeraLeccion = nuevoObjeto[0].lecciones[0];
      mSacarDatosLeccion(vPrimeraLeccion);
    }
    setvDataLeccion({
      idLeccionActual: vIdLeccionActual,
      nombreLeccionActual: vNombreLeccionActual,
      numLeccionActual: vNumLeccionActual,
      currentTimeLeccionActual: vCurrentTimeLeccionActual,
      idLeccionSiguiente: vIdLeccionSiguiente,
      numLeccionSiguiente: vNumLeccionSiguiente,
      numLeccionCurrent: vNumLeccionCurrent,
      totalLecciones: vTotalLecciones,
      isContinuar: vIsContinuar,
      isLeccionesTerminadas: vIsLeccionesTerminadas,
      idleccion_student_progresses: vIdLeccionStudentProgresses,
      JWT_Access: vJWT_Access,
      video: vVideo,
      img: vImg,
    });
    mGetEvaluacion(vIdLeccionActual, vIdLeccionStudentProgresses);
    setVIsLoadingDataLeccion(false);
  };

  const mGetRecursosLeccion = (vIdCurso, vIdLeccion) => {
    Gets.mGetRecursosLeccionA(vIdCurso, vIdLeccion)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvRecursos(result.data[0].attributes.recursos);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingRecursos(false);
      });
  };
  const mGetEvaluacion = (vIdLeccion, vIdLeccionStudentProgresses) => {
    Gets.mGetEvaluacionLeccion(vIdLeccion, vIdLeccionStudentProgresses)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvEvaluacion(result);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingEvaluacion(false);
      });
  };

  const mRenderCuestioanrio = () => {
    return (
      <AEvaluacion
        vIdCurso={vId}
        vDataLeccion={vDataLeccion}
        setvDataLeccion={setvDataLeccion}
        setVIsVisibleBtnSiguiente={setVIsVisibleBtnSiguiente}
        vEvaluacion={vEvaluacion}
        setVIsCuestionario={setVIsCuestionario}
        setvIsLoadingEvaluacion={setvIsLoadingEvaluacion}
        sendInfoByEvaluacion={sendInfoByEvaluacion}
      />
    );
  };

  const mRenderVideo_Cuesitonario = () => {
    if (vIsCuestionario) {
      return mRenderCuestioanrio();
    } else {
      return (
        <VideoPlayer
          {...props}
          src={
            Variables.V_URL_API +
            `/api2/video_leccion?id_leccion=${
              vDataLeccion.idLeccionActual
            }&video=${vDataLeccion.video}&ath_accss=${
              "Bearer " + vDataLeccion.JWT_Access
            }`
          }
          src_portada={
            Variables.V_URL_API +
            `/api2/img?id_leccion=${vDataLeccion.idLeccionActual}&img=${
              vDataLeccion.img
            }&ath_accss=${
              "Bearer " + vDataLeccion.JWT_Access
            }&quality=${vCalidadImagen}`
          }
          vIsLoadingTemario={vIsLoadingTemario}
          sendInfo={sendInfo}
          vDataLeccion={vDataLeccion}
          setvIsLoadingEvaluacion={setvIsLoadingEvaluacion}
        />
      );
    }
  };

  const CBtnSiguienteLeccion = ({ vDataLeccion }) => {
    const [vIsContinuar, setVIsContinuar] = useState(vDataLeccion.isContinuar);

    if (vIsVisibleBtnSiguiente) {
      return (
        <Tooltip
          title={vIsContinuar ? "" : "Debes de realizar el cuestionario!"}
          className={
            "curso-alumno-leccion " +
            (vIsContinuar ? "cursor-seleccion" : "cursor-tache")
          }
        >
          <div
            className="curso-alumno-leccion"
            onClick={() => {
              if (vIsContinuar) {
                navigate(`/alumno?tipo=mio&cId=${vId}`);
                window.location.reload();
              }
            }}
          >
            <div class="curso-alumno-texto-leccion">
              Lección {vDataLeccion.numLeccionSiguiente}
            </div>
            <img src="../images/play-2.svg" data-figma-id="614:5408" />
          </div>
        </Tooltip>
      );
    }
  };

  if (vIsLoadingCurso || vIsLoadingDataLeccion) {
    return <Spin tip="Loading" />;
  }
  return (
    <>
      {mRenderHeader(vCurso.nombre)}
      <div class="curso-alumno-contenido">
        <div class="curso-alumno-contenido-1">
          <div className="curso-alumno-profesor-video">
            <div class="curso-alumno-profesor-evaluacion">
              <div class="curso-alumno-profesor">
                <div
                  class="perfil-profesor-default"
                  style={{
                    backgroundImage: `linear-gradient(${vProfesor.color}, ${vProfesor.color})`,
                  }}
                >
                  <div
                    class="text"
                    style={{
                      color: Metodos.getContrastYIQ(vProfesor.color),
                    }}
                  >
                    {vProfesor.nombre.charAt(0) + vProfesor.apellidos.charAt(0)}
                  </div>
                </div>
                <div class="curso-alumno-profesor-texto">
                  {vProfesor.nombre + " " + vProfesor.apellidos}
                </div>
              </div>
              {!vIsVisibleBtnSiguiente ? (
                <></>
              ) : !vIsLoadingEvaluacion ? (
                vIsCuestionario ? (
                  <div
                    class="boton-evaluacion-activo"
                    onClick={() => {
                      setvDataLeccion({
                        ...vDataLeccion,
                        currentTimeLeccionActual: vCurrentTimeTmpVideoActual,
                      });
                      setVIsCuestionario(false);
                    }}
                  >
                    <div class="text-55">Regresar al video</div>
                  </div>
                ) : (
                  <div
                    class="boton-evaluacion-activo"
                    onClick={() => {
                      setVIsCuestionario(true);
                    }}
                  >
                    <img
                      src={`${window.location.origin}/images/Vectors-Wrapper_56.svg`}
                      loading="lazy"
                      width="24"
                      height="24"
                      alt=""
                      class="vectors-wrapper-67"
                    />
                    <div class="text-55">
                      Evaluación {vDataLeccion.numLeccionActual}
                    </div>
                  </div>
                )
              ) : (
                <div class="boton-evaluacion">
                  <img
                    src={`${window.location.origin}/images/Vectors-Wrapper_80.svg`}
                    loading="lazy"
                    width="24"
                    height="24"
                    alt=""
                    class="vectors-wrapper-67"
                  />
                  <div class="text-55">Evaluación</div>
                  <Spin size="small" />
                </div>
              )}
            </div>
            {mRenderVideo_Cuesitonario()}
          </div>
          <div data-figma-id="1027:3080" class="curso-alumno-titulo-leccion-1">
            <div data-figma-id="712:3063" class="curso-alumno-titulo-leccion">
              <div data-figma-id="712:3064" class="curso-alumno-titulo-1">
                <img src="../images/libro-verde.svg" data-figma-id="614:5396" />
                {vIsLoadingTemario ? (
                  <div class="curso-alumno-texto-titulo-1">!@#$%^&*</div>
                ) : (
                  <div
                    data-figma-id="712:3066"
                    class="curso-alumno-texto-titulo-1 truncated-text1"
                  >
                    {vDataLeccion.nombreLeccionActual}
                  </div>
                )}
              </div>
              {vIsLoadingTemario ? (
                <div class="curso-alumno-texto-leccion">Lección #/!@</div>
              ) : (
                <div class="curso-alumno-texto-leccion">
                  Lección {vDataLeccion.numLeccionActual}/
                  {vDataLeccion.totalLecciones}
                </div>
              )}
            </div>
            {vIsLoadingTemario || vIsLoadingDataLeccion ? (
              <div data-figma-id="712:3068" class="curso-alumno-leccion">
                <div
                  data-figma-id="712:3069"
                  class="curso-alumno-texto-leccion"
                >
                  Lección %
                </div>
                <img src="../images/play-2.svg" data-figma-id="614:5408" />
              </div>
            ) : (
              <>
                {vDataLeccion.idLeccionSiguiente !== null ? (
                  <CBtnSiguienteLeccion vDataLeccion={vDataLeccion} />
                ) : (
                  <>{!vDataLeccion.isLeccionesTerminadas && <></>}</>
                )}
              </>
            )}
          </div>

          {vLeccionCargar === undefined ? (
            <Spin />
          ) : (
            <CursoInfoRecursos
              vIdCurso={vId}
              vCurso={vCurso}
              vProfesor={vProfesor}
              vIsLoadingRecursos={vIsLoadingRecursos}
              vRecursos={vRecursos}
              vDataLeccion={vDataLeccion}
              mRenderTemario={
                <RenderTemario
                  vIdCurso={vId}
                  vIsLoadingTemario={vIsLoadingTemario}
                  vTemario={vTemario}
                  vDataLeccion={vDataLeccion}
                  vIdLeccion={vIdLeccion}
                />
              }
              mRenderForo={
                <RenderForo
                  vIdCurso={vId}
                  vDataLeccion={vDataLeccion}
                  mGetForo={mGetForo}
                  vIsLoadingForo={vIsLoadingForo}
                  vTemario={vTemario}
                  vForo={vForo}
                  vIsLoadingDataLeccion={vIsLoadingDataLeccion}
                  vIsForoLateral={false}
                />
              }
            />
          )}
        </div>

        <TemarioForo
          mRenderForo={
            <RenderForo
              vIdCurso={vId}
              vDataLeccion={vDataLeccion}
              mGetForo={mGetForo}
              vIsLoadingForo={vIsLoadingForo}
              vTemario={vTemario}
              vForo={vForo}
              vIsLoadingDataLeccion={vIsLoadingDataLeccion}
              vIsForoLateral={true}
            />
          }
          mRenderTemario={
            <RenderTemario
              vIdCurso={vId}
              vIsLoadingTemario={vIsLoadingTemario}
              vTemario={vTemario}
              vDataLeccion={vDataLeccion}
              vIdLeccion={vIdLeccion}
            />
          }
        />
      </div>
    </>
  );
}

function RenderTemario(props) {
  const {
    vIdCurso,
    vIsLoadingTemario,
    vIsLoadingForo,
    vTemario,
    vForo,
    vDataLeccion,
    vIdLeccion,
  } = props;

  const navigate = useNavigate();

  const mRenderRotating = (vId) => {
    if (vIdLeccion !== null) {
      if (vIdLeccion.length > 0) {
        if (vId.toString() === vIdLeccion.toString()) {
          return "rotating";
        } else {
          return "";
        }
      }
    }
    if (vId.toString() === vDataLeccion.idLeccionActual.toString()) {
      return "rotating";
    } else {
      return "";
    }
  };

  const mRenderLeccion = (vleccion) => {
    const mRednerStatusLeccion = (terminado) => {
      switch (terminado) {
        case true:
          return (
            <div
              data-figma-id="767:3238"
              class="curso-alumno-temario-check-cirulo "
            >
              <img
                src="../images/check-circulo-verde.svg"
                data-figma-id="217:1395"
                class={"img-46 " + mRenderRotating(vleccion.id)}
              />
              <div class="curso-alumno-linea-verde"></div>
            </div>
          );
        case false:
          return (
            <div data-figma-id="767:3266" class="curso-alumno-temario-circulo">
              <img
                src="../images/azul.svg"
                data-figma-id="650:6608"
                class={
                  "img-9 img-10 img-11 img-12 img-13 img-14 img-15 img-16 img-17 img-18 img-19 img-20 img-21 img-22 img-23 img-24 img-25 img-26 img-27 img-28 img-29 img-30 img-31 img-32 img-33 img-34 img-35 img-36 img-37 img-38 img-39 img-40 img-41 img-42 img-43 img-44 img-45 img-47 img-48 img-49 img-50 img-51 img-52 " +
                  mRenderRotating(vleccion.id)
                }
              />
              <div class="curso-alumno-linea-azul"></div>
            </div>
          );

        default:
          return (
            <div
              data-figma-id="767:3287"
              class="curso-alumno-temario-play-circulo"
            >
              <img
                src="../images/play-circule.svg"
                data-figma-id="652:6651"
                class="img-8"
              />
              <div class="curso-alumno-linea-gris"></div>
            </div>
          );
      }
    };

    const mRenderContenidoLeccion = (vleccion) => {
      const mRenderItemsLeccion = (vleccion) => {
        console.log(vleccion.contenido);
        return vleccion.contenido === null ||
          vleccion.contenido.length === 0 ||
          !vleccion.contenido ? (
          <div class="curso-alumno-temario-nombre-leccion"></div>
        ) : (
          vleccion.contenido.split("\n").map((item) => (
            <div
              data-figma-id="767:3272"
              class="curso-alumno-temario-nombre-leccion"
            >
              <img src="../images/circulo-gris.svg" data-figma-id="790:419" />
              <div
                data-figma-id="767:3274"
                class="curso-alumno-temario-nombre-texto-leccion"
              >
                {item}
              </div>
            </div>
          ))
        );
      };
      return (
        <div
          key={new Date().now}
          data-figma-id="767:3241"
          class="curso-alumno-temario-lecciones"
        >
          <div
            data-figma-id="767:3242"
            class="curso-alumno-temario-texto-leccion truncated-text1"
          >
            {`${vleccion.num_leccion}. ${vleccion?.nombre}`}
          </div>
          <div
            data-figma-id="767:3271"
            class="curso-alumno-temario-lecciones-3"
          >
            {mRenderItemsLeccion(vleccion)}
          </div>
        </div>
      );
    };

    switch (vleccion?.terminado) {
      case false:
      case true:
        return (
          <div
            key={new Date().now}
            data-figma-id="768:3394"
            class="curso-alumno-temario-leccion cursor-seleccion"
            onClick={() => {
              navigate(`/alumno?tipo=mio&cId=${vIdCurso}&lecId=${vleccion.id}`);
              window.location.reload();
            }}
          >
            {mRednerStatusLeccion(vleccion?.terminado)}
            {mRenderContenidoLeccion(vleccion)}
          </div>
        );

      default:
        return (
          <div
            key={new Date().now}
            data-figma-id="768:3394"
            class="curso-alumno-temario-leccion-4 cursor-tache"
          >
            {mRednerStatusLeccion(vleccion?.terminado)}
            <Tooltip
              title={`Debes terminar la lección ${vleccion.num_leccion - 1}`}
              placement="topLeft"
              class="curso-alumno-temario-lecciones"
            >
              {mRenderContenidoLeccion(vleccion)}
            </Tooltip>
          </div>
        );
    }
  };

  if (vIsLoadingTemario) {
    return <Spin tip="loading" />;
  }
  if (Object.keys(vTemario).length === 0) {
    return (
      <div className="curso-alumno-temario-texto-modulo">
        Esta vació este temario...
      </div>
    );
  }

  var vCountSeccion = 0;
  return vIsLoadingTemario ? (
    <Spin />
  ) : (
    <>
      {vTemario.map((vSeccion) => {
        vCountSeccion += 1;
        return (
          <>
            <div
              data-figma-id="768:3393"
              class="curso-alumno-temario-modulo-contenedor"
            >
              <div
                key={new Date().now}
                data-figma-id="768:3393"
                class="curso-alumno-temario-modulo"
              >
                <div
                  data-figma-id="767:3236"
                  class="curso-alumno-temario-texto-modulo truncated-text1"
                >
                  Sección {vCountSeccion}: {vSeccion.nombre}{" "}
                </div>
              </div>
            </div>
            <>
              {vSeccion.lecciones.map((vleccion) => {
                return <>{mRenderLeccion(vleccion)}</>;
              })}
            </>
          </>
        );
      })}
    </>
  );
}

function RenderForo(props) {
  const {
    vIdCurso,
    vDataLeccion,
    mGetForo,
    vIsLoadingForo,
    vTemario,
    vForo,
    vIsLoadingDataLeccion,
    vIsForoLateral = true,
  } = props;

  const [vIsNuevaPregunta, setvIsNuevaPregunta] = useState(false);

  const [vPregunta, setvPregunta] = useState("");
  const [vContenido, setvContenido] = useState("");
  const mRenderNuevaPregunta = () => {
    return (
      <>
        <div className="preguntas-foro">
          <div className="text-block-20">Nueva pregunta</div>
          <div className="div-block-33">
            <div className="text-block-7">Pregunta</div>
            <div className="div-block-34">
              <input
                type="text"
                value={vPregunta}
                onChange={(evt) => {
                  setvPregunta(evt.target.value);
                }}
                className="text-block-21"
              />
            </div>
          </div>
          <div className="div-block-33">
            <div className="text-block-7">Mensaje</div>
            <div className="div-block-35">
              <textarea
                type="text"
                className="text-block-21"
                value={vContenido}
                onChange={(evt) => {
                  setvContenido(evt.target.value);
                }}
              />
            </div>
          </div>
          <div className="div-block-33">
            <div className="text-block-7">Alumno</div>
            <div className="div-block-48">
              <div className="div-block-47">
                <div className="text-block-18">
                  {localStorage.getItem("nombre_a") +
                    " " +
                    localStorage.getItem("apellidos_a")}
                </div>
              </div>
              <div className="div-block-49">
                <div className="text-block-18">
                  Lección {vDataLeccion.numLeccionActual}
                </div>
              </div>
            </div>
          </div>
          <div className="div-block-50">
            <div>
              <div
                className="text-block-19"
                onClick={() => setvIsNuevaPregunta(false)}
              >
                Cancelar
              </div>
            </div>
            <div
              className="div-block-51"
              onClick={() => {
                mEnviarPregunta();
              }}
            >
              <div className="text-block-19">Enviar</div>
              <img
                src={`${window.location.origin}/images/Vectors-Wrapper_65.svg`}
                loading="lazy"
                width="18.93531608581543"
                height="17.136606216430664"
                alt=""
                className="vectors-wrapper-81"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const mEnviarPregunta = () => {
    if (vPregunta.trim().length > 0 && vContenido.trim().length > 0) {
    } else {
    }
    Posts.mEnviarPreguntaForoA({
      pregunta: vPregunta.trim(),
      mensaje: vContenido.trim(),
      id_curso: vIdCurso,
      numero_leccion: vDataLeccion.numLeccionActual,
      id_leccion: vDataLeccion.idLeccionActual,
      id_user: localStorage.getItem("id_alumno"),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        switch (result?.estado) {
          case "error":
            console.error("Error");

            break;
          case "correcto":
            mGetForo(vIdCurso);
            break;

          default:
            break;
        }
      })
      .catch(() => {})
      .finally(() => {
        setvPregunta("");
        setvContenido("");
        setvIsNuevaPregunta(false);
      });
  };

  if (vIsLoadingForo || vIsLoadingDataLeccion) {
    return <Spin tip="loading" />;
  }
  return vIsNuevaPregunta ? (
    mRenderNuevaPregunta()
  ) : (
    <>
      <div
        className={`curso-alumno-foro-contenido${!vIsForoLateral ? "-1" : ""}`}
      >
        <div data-figma-id="813:3036" class="curso-alumno-foro-titulos">
          <div data-figma-id="775:3131" class="curso-alumno-foro-titulo">
            Todas las preguntas del curso
          </div>
          <div data-figma-id="775:3132" class="curso-alumno-foro-preguntas">
            ({vForo.meta.pagination.total})
          </div>
        </div>

        {[...vForo.data].reverse().map((vContenido, index) => (
          <Pregunta_Respuesta
            key={index}
            vTemario={vTemario}
            vContenido={vContenido}
          />
        ))}
      </div>
      <div
        class="curso-alumno-foro-boton-pregunta"
        onClick={() => setvIsNuevaPregunta(true)}
      >
        <div class="text-block-22">Agregar pregunta</div>
      </div>
    </>
  );
}
