import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WhatsAppOutlined } from "@ant-design/icons";

import Curso from "../components/curso/Curso";
import Header from "../components/header/Header";
import Btn from "../components/botones/Boton";
import Contactanos from "../components/modals/contactanos";

import { Empty, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as Gets from "../utils/Rest apis/Gets";
import * as Variables from "../utils/Global/Variables";
import Header_busqueda from "../components/header_busqueda/Header_busqueda";

import Particles from "../components/particles/particles";

import { ReactComponent as MyIcon } from "../images/Vectors-Wrapper_1.svg";
import Footer from "../components/footer_publico/footer";

const Context = React.createContext();

export const Home = (props) => {
  const [vCategories, setvCategories] = useState(undefined);
  const [vLoadingCategories, setvLoadingCategories] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <div className="section-home">
      <Context.Provider
        value={{
          vCategories,
          setvCategories,
          vLoadingCategories,
          setvLoadingCategories,
        }}
      >
        {contextHolder}
        <CHeader messageApi={messageApi} {...props} />
        <section className="section-group">
          <div className="section-title">
          <img src="images/Vectors-Wrapper_3.svg" loading="lazy" width="30" height="30" alt="" class="vectors-wrapper-6"/>
            <div className="section-text">Cursos recomendados</div>
          </div>
          <Cursos {...props} />
        </section>
        <section className="section-group">
          <div className="section-title">
            <img
              src="images/Vectors-Wrapper.svg"
              loading="lazy"
              width="30"
              height="30"
              alt=""
              className="vectors-wrapper"
            />
            <div className="section-text">Explora cursos por categoría</div>
          </div>
          <Categorias {...props} />
        </section>
      </Context.Provider>
      <Footer />
    </div>
  );
};

function CHeader(props) {
  const { setvFrame, messageApi } = props;
  const { vCategories, vLoadingCategories } = useContext(Context);

  return (
    <Particles key={"header-home"} className="section-header-home">
      <Header {...props} vSelected={Variables.vFrames.home} />
      <div className="bienvenida">
        <div className="eslogan">
          <div className="mega-text">Evoluciona tu Carrera profesional</div>
          <div className="text-3">
            La primera plataforma de e-learning en finanzas especializadas que se adapta a tu formación profesional de una manera flexible y a
            tu ritmo.
          </div>
        </div>
        <div className="botones-inicio">
          <Btn
            vClass="btn1"
            vClassText="btn1-text"
            vText="Ver cursos"
            vActionBtn={() => setvFrame(Variables.vFrames.courses)}
            vIsLink={true}
            vTo="/cursos"
          />
          <Contactanos vBtnText="Contáctanos" messageApi={messageApi} />
        </div>
        <div className="frame-certificacion">
          <img
            src="images/Vectors-Wrapper_2.svg"
            loading="lazy"
            width="60"
            height="60"
            alt=""
            className="vectors-wrapper-3"
          />
          <div className="certificate">
            <div className="text-4">Certificados y Badges</div>
            <div className="text-5">
              Todos nuestros cursos otorgan certificados y badges respaldados
              por tecnología blockchain con valor curricular.
            </div>
          </div>
        </div>
      </div>
      <div className="barra-busqueda">
        <Header_busqueda
          {...props}
          categories={vCategories}
          vIsLoading={vLoadingCategories}
        />
      </div>
    </Particles>
  );
}

function Cursos(props) {
  const [vPopularCourses, setvPopularCourses] = useState(undefined);
  const [vLoadingPopularCourses, setvLoadingPopulateCourses] = useState(true);

  useEffect(() => {
    const fetchPopularCourses = async () => {
      try {
        const response = await Gets.mGetPopularCourses();
        const data = await response.json();
        setvPopularCourses(data.data);
      } catch (error) {
        setvPopularCourses(undefined);
      } finally {
        setvLoadingPopulateCourses(false);
      }
    };
    fetchPopularCourses();
  }, []); // Array de dependencias vacío significa que este useEffect solo se ejecuta una vez

  if (vLoadingPopularCourses) {
    return <Spin tip="Loading" />;
  }
  if (vPopularCourses !== undefined) {
    return (
      <div className="cursos">
        {vPopularCourses.map((course, index) => (
          <Curso {...props} key={index} vCurso={course} />
        ))}
      </div>
    );
  } else {
    return <Empty description={"No hay cursos"} />;
  }
}

function Categorias(props) {
  const { mSetBusqueda } = props;
  const {
    vCategories,
    setvCategories,
    vLoadingCategories,
    setvLoadingCategories,
  } = useContext(Context);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        await Gets.mGetCategories()
          .then((response) => response.json())
          .then((data) => {
            const categoriesWithCounts = data.data.map((category) => ({
              ...category,
              num_cursos: category.attributes.cursos.data.length,
            }));
            setvCategories(categoriesWithCounts);
          })
          .catch((error) => console.error("Error:", error));
      } catch (error) {
        setvCategories(undefined);
      } finally {
        setvLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []); // Array de dependencias vacío significa que este useEffect solo se ejecuta una vez

  if (vLoadingCategories) {
    return <Spin tip="Loading" />;
  }
  if (vCategories !== undefined) {
    return (
      <div className="contenido-categorias">
        {vCategories.map((category, index) => (
          <Link
            key={index}
            to={`/cursos/c/${category.id}`}
            className="link-style"
          >
            <div
              onClick={() =>
                mSetBusqueda(
                  {
                    filter_type: "category",
                    filter_value: category.id,
                  },
                  category.attributes.nombre
                )
              }
              className="categoria"
            >
              <MyIcon style={{ color: "red" }} />
              <div className="div-categoria">
                <div className="categoria-titullo">
                  {category.attributes.nombre}
                </div>
                <div className="categoria-num-cursos">
                  {category.num_cursos} cursos
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  } else {
    return <Empty description={"No hay categorias"} />;
  }
}
