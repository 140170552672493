import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { WhatsAppOutlined } from "@ant-design/icons";

import { Empty, Pagination, Spin, message } from "antd";

import Header from "../components/header/Header";
import * as Gets from "../utils/Rest apis/Gets";
import * as Variables from "../utils/Global/Variables";
import Curso from "../components/curso/Curso";
import Particles from "../components/particles/particles";
import Header_busqueda from "../components/header_busqueda/Header_busqueda";
import Contactanos from "../components/modals/contactanos";
import Footer from "../components/footer_publico/footer";

export default function Courses(props) {
  const { setvFrame, vBusqueda } = props;
  const navigate = useNavigate();

  const [vId, setvId] = useState(useParams()?.id);
  const [vTitulo, setvTitulo] = useState("");
  const [vCourses, setvCourses] = useState(undefined);
  const [vTotal_courses, setvTotal_courses] = useState(0);
  const [vPer_Page, setvPer_Page] = useState(1);

  const [vLoadingCourses, setvLoadingCourses] = useState(true);
  const [vCategories, setvCategories] = useState(undefined);
  const [vLoadingCategories, setvLoadingCategories] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const mCargarCourses = (response, vPagination) => {
    if (response.length > 0) {
      setvCourses(response);
      setvTotal_courses(vPagination ? vPagination.total : response.length);
      setvPer_Page(vPagination ? vPagination.pageSize : response.length);
    } else {
      setvCourses(undefined);
    }
  };

  const mGetCourses = (page = 1) => {
    if (!vId) {
      navigate("/cursos", { replace: true });
    }
    if (vId) {
      Gets.mGetCourses("category", vId, page)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        }) // 2
        .then((response) => {
          mCargarCourses(response.data);
        })
        .catch((error) => {
          setvId(undefined);
          setvTitulo("Todos los cursos");
          navigate("/cursos", { replace: true });
        })
        .finally(() => setvLoadingCourses(false));
    } else {
      setvTitulo("Todos los cursos");
      Gets.mGetCourses("all", "", page)
        .then((response) => response.json()) // 2
        .then((response) => {
          mCargarCourses(response.data, response.meta.pagination);
        })
        .catch((error) => error)
        .finally(() => setvLoadingCourses(false));
    }
  };
  useEffect(() => {
    mGetCourses();
    if (vId) {
      Gets.mGetCategoria(vId)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        }) // 2
        .then((response) => {
          setvTitulo(response.data.attributes.nombre);
        })
        .catch((error) => navigate("/cursos/", { replace: true }))
        .finally(() => setvLoadingCourses(false));
    }
    Gets.mGetCategories()
      .then((response) => response.json()) // 2
      .then((response) => {
        const categoriesWithCounts = response.data.map((category) => ({
          ...category,
          num_cursos: category.attributes.cursos.data.length,
        }));
        setvCategories(categoriesWithCounts);
      })
      .catch((error) => error)
      .finally(() => setvLoadingCategories(false));
  }, []);

  const mRenderCourses = () => {
    if (vLoadingCourses) {
      return <Spin tip="Loading " />;
    }
    if (vCourses !== undefined) {
      return vCourses.map((course, index) => (
        <Curso {...props} vCurso={course} />
      ));
    } else {
      return <Empty description={"No hay cursos"} />;
    }
  };

  const mCambiarPagina = (page) => {
    setvLoadingCourses(true);
    mGetCourses(page);
  };

  return (
    <div className="section-courses">
      {contextHolder}
      <Particles className="section-header-courses">
        <Header setvFrame={setvFrame} vSelected={Variables.vFrames.courses} />
        <address className="barra-busqueda">
          <Header_busqueda
            {...props}
            categories={vCategories}
            vIsLoading={vLoadingCategories}
          />
        </address>
      </Particles>
      <section className="section-group">
        <div className="section-tutle-pagination-courses">
          <div className="section-title">
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_9.svg`}
              loading="lazy"
              width="30"
              height="30"
              alt=""
              className="vectors-wrapper-22"
            />
            <div className="section-text">
              {" "}
              {vId && "Categoria: "} {vTitulo}
            </div>
            <div className="text-22">{vTotal_courses} cursos</div>
          </div>
          {vCourses !== undefined && (
            <Pagination
              className="pagination"
              pageSize={vPer_Page}
              total={vTotal_courses}
              onChange={mCambiarPagina}
            />
          )}
        </div>
        <div className="cursos">{mRenderCourses()}</div>
      </section>

      <Footer />
    </div>
  );
}
