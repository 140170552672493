import React, { useEffect } from "react";

import * as Gets from "../../utils/Rest apis/Gets";

export default function Home() {
  useEffect(() => {
    Gets.mGetHomeProfesor()
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {})
      .catch(() => {})
      .finally(() => {});
  }, []);

  return <div>Home</div>;
}
