import React, {
  useCallback,
  Component,
  useState,
  useEffect,
  useRef,
} from "react";

import * as Gets from "../../../utils/Rest apis/Gets";
import * as Metodos from "../../../utils/Global/Metodos";

import {
  BoldOutlined,
  SendOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { Badge, Button, Empty, Spin } from "antd";
import BundledEditor from "../../../components/EditorTexto/BundledEditor";

function RenderRespuestaProfesor(props) {
  const { vPregunta, vPreguntasAlumno, vIsLoadingPreguntas } = props;

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
    }
  };
  if (vIsLoadingPreguntas) {
    return <Spin />;
  }
  if (vPreguntasAlumno.length === 0) {
    return <Empty description="No hay preguntas por seleccionar" />;
  }
  if (vPregunta.id === undefined) {
    return <Empty description={"No se a seleccionado una pregunta"} />;
  }
  return (
    <>
      <div class="item-pregunta" style={{ height: `100%` }}>
        <div class="text-134">{vPregunta.pregunta}</div>
        <div class="item-pregunta-2">
          <div class="text-135">{vPregunta.curso}</div>
          <div class="more-info-foro-der">
            <div class="text-136">{vPregunta.alumno}</div>
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65ba916a7754517f5f91c736_Vectors-Wrapper.svg"
              loading="lazy"
              width="3"
              height="3"
              alt=""
              class="vectors-wrapper-188"
            />
            <div class="text-136">{vPregunta.leccion}</div>
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65ba916a7754517f5f91c736_Vectors-Wrapper.svg"
              loading="lazy"
              width="3"
              height="3"
              alt=""
              class="vectors-wrapper-188"
            />
            <div class="text-136">
              {Metodos.mTimeSince(new Date(vPregunta.date))}
            </div>
          </div>
        </div>
        <div class="text-block-29">{vPregunta.contenido}</div>
      </div>
      <div class="div-block-69" />
      <div class="div-block-68">
        <div class="div-block-70">
          <BundledEditor
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            init={{
              height: 500,
              menubar: false,
              statusbar: false,
              plugins: ["advlist", "anchor", "lists"],
              toolbar:
                "undo redo  | fontselect fontsizeselect | " +
                "bold italic | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | ",
              font_formats:
                "Poppins,Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Times New Roman=times new roman,times,serif",
              fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
              content_style:
                "body { font-family:Poppins,Arial,sans-serif; font-size:14px; color: white; }",
            }}
          />
        </div>
        <div class="div-block-71">
          <div class="div-block-72">
            {" "}
            <Button
              type="text"
              shape="circle"
              icon={
                <EditOutlined style={{ color: "#B1BDCD", fontSize: "24px" }} />
              }
            />{" "}
            <Button
              type="text"
              shape="circle"
              icon={
                <DeleteOutlined
                  style={{ color: "#B1BDCD", fontSize: "24px" }}
                />
              }
            />
          </div>{" "}
          <Button
            type="text"
            shape="circle"
            icon={
              <SendOutlined style={{ color: "#B1BDCD", fontSize: "24px" }} />
            }
          />
        </div>
      </div>
    </>
  );
}

function RednerItemPreguntaAlumno(props) {
  const {
    vPregunta,
    setvPreguntaAlumnoSeleccionada,
    vPreguntaAlumnoSeleccionada,
  } = props;

  const onHandleClick = () => {
    vPregunta.visto = 1;
    setvPreguntaAlumnoSeleccionada(vPregunta);
  };

  return (
    <div
      onClick={onHandleClick}
      class="contenit-item-pregunta-foro"
      style={
        vPregunta.id === vPreguntaAlumnoSeleccionada.id
          ? { background: "#485769", borderRadius: "5px", padding: "5px" }
          : { padding: "5px" }
      }
    >
      <div class="item-pregunta">
        <div class="text-134">{vPregunta.pregunta}</div>
        <div class="item-pregunta-2">
          <div class="text-135">{vPregunta.curso}</div>
          <div class="more-info-foro-der">
            <div class="text-136">{vPregunta.alumno}</div>
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65ba916a7754517f5f91c736_Vectors-Wrapper.svg"
              loading="lazy"
              width="3"
              height="3"
              alt=""
              class="vectors-wrapper-188"
            />
            <div class="text-136">{vPregunta.leccion}</div>
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65ba916a7754517f5f91c736_Vectors-Wrapper.svg"
              loading="lazy"
              width="3"
              height="3"
              alt=""
              class="vectors-wrapper-188"
            />
            <div class="text-136">
              {Metodos.mTimeSince(new Date(vPregunta.date))}
            </div>
          </div>
        </div>
        <wrapper class="vectors-wrapper-189"></wrapper>
        <div class="div-block-69"></div>
      </div>

      <Badge
        status="processing"
        style={{ visibility: vPregunta.visto === 0 ? "" : "hidden" }}
        size="large"
      />
    </div>
  );
}
export default function Foro(props) {
  const { BotonesCurso, nombreCurso, windowSize, vIdCurso } = props;

  const [vIsLoadingPreguntas, setvIsLoadingPreguntas] = useState(true);
  const [vPreguntasAlumno, setvPreguntasAlumno] = useState([]);
  const [vPreguntaAlumnoSeleccionada, setvPreguntaAlumnoSeleccionada] =
    useState({});

  useEffect(() => {
    Gets.mGetPreguntasForoProfesor(vIdCurso)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        setvPreguntasAlumno(result);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingPreguntas(false);
      });
  }, []);

  const mRenderPreguntas = () => {
    if (vIsLoadingPreguntas) {
      return <Spin />;
    }
    if (vPreguntasAlumno.length === 0) {
      return <Empty description="No hay preguntas por ver" />;
    }

    return vPreguntasAlumno.map((vPregunta) => (
      <RednerItemPreguntaAlumno
        setvPreguntaAlumnoSeleccionada={setvPreguntaAlumnoSeleccionada}
        vPreguntaAlumnoSeleccionada={vPreguntaAlumnoSeleccionada}
        vPregunta={vPregunta.entity}
      />
    ));
  };

  return (
    <div
      class="conteniido-curso-profesor"
      style={{ height: `${windowSize.height - 80}px` }}
    >
      <div class="text-title-curso">{nombreCurso}</div>
      <BotonesCurso {...props} />
      <div class="container-curso-profesor">
        <div class="text-133">Foro</div>
        <div class="container-curso-profesor-body">
          <div
            class="div-contenido-for-1"
            style={{ height: `${windowSize.height - 260}px` }}
          >
            {mRenderPreguntas()}
          </div>
          <div class="separador-info-general" style={{ height: `100%` }} />
          <div
            class="div-contenido-foro-2"
            style={{ height: `${windowSize.height - 260}px` }}
          >
            <RenderRespuestaProfesor
              vIsLoadingPreguntas={vIsLoadingPreguntas}
              vPreguntasAlumno={vPreguntasAlumno}
              vPregunta={vPreguntaAlumnoSeleccionada}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
