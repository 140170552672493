import { ColorPicker, Modal, Popover, Spin, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";

import * as Metodos from "../../utils/Global/Metodos";
import * as Puts from "../../utils/Rest apis/Puts";

import "./style.css";
import { useSearchParams } from "react-router-dom";

export default function Perfil(props) {
  const { openNotification, vDatosPerfil, setvDatosPerfil } = props;
  const [vIsHoverDescripcionProfesor, setvIsHoverDescripcionProfesor] =
    useState(false);
  const [vIsHoverContent5, setvIsHoverContent5] = useState(false);
  const [vIsHoverFotoPerfil, setvIsHoverFotoPerfil] = useState(false);

  const [vColorPerfil, setvColorPerfil] = useState(vDatosPerfil.color);
  const [vCalificacionProfesor, setvCalificacionProfesor] = useState(
    vDatosPerfil.detalles_perfil.calificacion
  );

  const [vIsModificandoNombre, setvIsModificandoNombre] = useState(false);
  const [vNombreProfesor, setvNombreProfesor] = useState(vDatosPerfil.nombre);
  const [vIsErrorNombre, setvIsErrorNombre] = useState(false);
  const [vNombreProfesorTmp, setvNombreProfesorTmp] = useState(vNombreProfesor);

  const [vIsModificandoApellidos, setvIsModificandoApellidos] = useState(false);
  const [vApellidosProfesor, setvApellidosProfesor] = useState(
    vDatosPerfil.apellidos
  );
  const [vIsErrorApellidos, setvIsErrorApellidos] = useState(false);
  const [vApellidosProfesorTmp, setvApellidosProfesorTmp] =
    useState(vApellidosProfesor);

  const [vIsModificandoProfesion, setvIsModificandoProfesion] = useState(false);
  const [vProfesionProfesor, setvProfesionProfesor] = useState(
    vDatosPerfil.detalles_perfil.profesion
  );
  const [vProfesionProfesorTmp, setvProfesionProfesorTmp] =
    useState(vProfesionProfesor);

  const [vIsModificandoDescripcion, setvIsModificandoDescripcion] =
    useState(false);
  const [vDescripcionProfesor, setvDescripcionProfesor] = useState(
    vDatosPerfil.detalles_perfil.descripcion
  );
  const [vDescripcionProfesorTmp, setvDescripcionProfesorTmp] =
    useState(vDescripcionProfesor);

  const [vFocusInput, setvFocusInput] = useState("");
  const [vBlurInput, setvBlurInput] = useState("");

  const textAreaRef = useRef(null);

  let vTextoModificar = "Doble clic para modificar!!";

  const mValidarNombre = (vValue) => {
    var vCorrecciones = [];
    if (vValue.length === 0) {
      vCorrecciones.push("Contener mas de un caracter");
    }
    return vCorrecciones;
  };
  const mValidarApellidos = (vValue) => {
    var vCorrecciones = [];
    if (vValue.length === 0) {
      vCorrecciones.push("Contener mas de un caracter");
    }
    return vCorrecciones;
  };
  const mValidarProfesion = (vValue) => {
    var vCorrecciones = [];
    if (vValue.length === 0) {
      vCorrecciones.push("Contener mas de un caracter");
    }
    return vCorrecciones;
  };
  const mValidarDescripcion = (vValue) => {
    var vCorrecciones = [];
    if (vValue.length === 0) {
      vCorrecciones.push("Contener mas de un caracter");
    }
    return vCorrecciones;
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [vDescripcionProfesorTmp, vIsModificandoDescripcion]); // Este efecto se ejecuta cada vez que textValue cambia

  useEffect(() => {
    switch (vBlurInput) {
      case "nombre":
      case "apellidos":
        if (vFocusInput !== "nombre" && vFocusInput !== "apellidos") {
          handleBlur(vNombreProfesorTmp, "nombre");
          handleBlur(vApellidosProfesorTmp, "apellidos");
        }
        break;
      case "profesion":
        handleBlur(vProfesionProfesorTmp, "profesion");
        break;
      case "descripcion":
        handleBlur(vDescripcionProfesorTmp, "descripcion");
        break;

      default:
        break;
    }
    setvBlurInput("");
  }, [
    vBlurInput,
    vFocusInput,
    vNombreProfesorTmp,
    vApellidosProfesorTmp,
    vProfesionProfesorTmp,
    vDescripcionProfesorTmp,
  ]); // Este efecto se ejecuta cada vez que textValue cambia

  const inputStyleNombre = {
    width: `${Math.max(20, vNombreProfesorTmp.length)}ch`, // Ajusta el tamaño mínimo y crece con la longitud del texto
    transition: "width 0.2s ease-out", // Suaviza la transición de tamaño
  };
  const inputStyleProfesion = {
    width: `${Math.max(25, vProfesionProfesorTmp.length)}ch`, // Ajusta el tamaño mínimo y crece con la longitud del texto
    transition: "width 0.2s ease-out", // Suaviza la transición de tamaño
  };

  const textAreaStyle = {
    overflow: "hidden",
    transition: "height 0.2s ease-out", // Transición suave para la altura
    width: "100%",
  };

  const mEnivarDatosPerfilAll = (vDatos, vInfoNotitificacion) => {
    Puts.mUserMe(vDatos)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        switch (result.estatus) {
          case 200:
            openNotification(
              vInfoNotitificacion[0],
              vInfoNotitificacion[1],
              "success"
            );
            setvNombreProfesor(vDatos.nombre);
            setvIsModificandoNombre(false);
            setvApellidosProfesor(vDatos.apellidos);
            setvIsModificandoNombre(false);

            setvColorPerfil(vDatos.color);

            setvNombreProfesorTmp(vDatos.nombre);
            setvApellidosProfesorTmp(vDatos.apellidos);

            localStorage.setItem("nombre_p", vDatos.nombre);
            localStorage.setItem("apellidos_p", vDatos.apellidos);
            localStorage.setItem("color_p", vDatos.color);

            vDatosPerfil["nombre"] = vDatos.nombre;
            vDatosPerfil["apellidos"] = vDatos.apellidos;
            vDatosPerfil["telefono"] = vDatos.telefono;
            vDatosPerfil["color"] = vDatos.color;
            handleCancel();
            break;

          default:
            openNotification(
              "Error al modificar los datos",
              `Verifique los datos que este correctamente.`,
              "error"
            );
            break;
        }
      })
      .catch(() => {
        openNotification(
          "Error al enviar datos",
          "Se produjo un error del lado de nuestros servidores, intente nuevamente.",
          "error"
        );
      })
      .finally(() => {});
  };
  const mEnivarDatosPerfil = (vKey, vValue, vInfoNotitificacion) => {
    var vDatos = {
      nombre: vNombreProfesor,
      apellidos: vApellidosProfesor,
      color: vColorPerfil,
      telefono: vDatosPerfil.telefono,
    };
    vDatos[vKey] = vValue;
    Puts.mUserMe(vDatos)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        switch (result.estatus) {
          case 200:
            openNotification(
              vInfoNotitificacion[0],
              vInfoNotitificacion[1],
              "success"
            );
            switch (vKey) {
              case "nombre":
                setvNombreProfesor(vValue);
                setvIsModificandoNombre(false);
                localStorage.setItem("nombre_p", vValue);

                break;
              case "apellidos":
                setvApellidosProfesor(vValue);
                setvIsModificandoNombre(false);
                localStorage.setItem("apellidos_p", vValue);

                break;
              case "color":
                localStorage.setItem("color_p", vValue);

                break;

              default:
                break;
            }
            break;

          default:
            openNotification(
              "Error al modificar los datos",
              `Verifique los datos en ${vKey}, que este correctamente.`,
              "error"
            );
            break;
        }
      })
      .catch(() => {
        openNotification(
          "Error al enviar datos",
          "Se produjo un error del lado de nuestros servidores, intente nuevamente.",
          "error"
        );
      })
      .finally(() => {});
  };
  const mEnivarDetallesPerfil = (vKey, vValue, vInfoNotitificacion) => {
    var vDetallesPerfil = vDatosPerfil.detalles_perfil;
    vDetallesPerfil[vKey] = vValue;
    Puts.mUserMeDetallesPerfil(vDetallesPerfil)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        switch (result.estatus) {
          case 200:
            openNotification(
              vInfoNotitificacion[0],
              vInfoNotitificacion[1],
              "success"
            );
            switch (vKey) {
              case "profesion":
                setvProfesionProfesor(vValue);
                setvIsModificandoProfesion(false);

                break;
              case "descripcion":
                setvDescripcionProfesor(vValue);
                setvIsModificandoDescripcion(false);
                break;

              default:
                break;
            }
            break;

          default:
            setvFocusInput(vKey);
            openNotification(
              "Error al modificar los datos",
              `Verifique los datos en ${vKey}, que este correctamente.`,
              "error"
            );
            break;
        }
      })
      .catch(() => {
        openNotification(
          "Error al enviar datos",
          "Se produjo un error del lado de nuestros servidores, intente nuevamente.",
          "error"
        );
      })
      .finally(() => {});
  };

  const mModificarValor = (vValue, vKey) => {
    let vCorrecciones = [];

    setvBlurInput("");
    setvFocusInput("");
    switch (vKey) {
      case "nombre":
        vCorrecciones = mValidarNombre(vValue);
        if (vCorrecciones.length === 0) {
          if (vNombreProfesorTmp !== vNombreProfesor) {
            setvIsErrorNombre(false);
            mEnivarDatosPerfil("nombre", vValue, [
              "Modificación exitosa",
              "Se ha modificado el nombre correctamente.",
            ]);
          } else {
            setvIsModificandoNombre(false);
          }
        } else {
          setvNombreProfesorTmp(vNombreProfesor);
          openNotification(
            "Error al modificar",
            "Debe tener por lo menos 1 caracter en el nombre",
            "error"
          );
          setvIsModificandoNombre(false);
        }
        break;
      case "apellidos":
        vCorrecciones = mValidarApellidos(vValue);
        if (vCorrecciones.length === 0) {
          if (vApellidosProfesorTmp !== vApellidosProfesor) {
            setvApellidosProfesor(vValue);
            setvIsErrorApellidos(false);
            mEnivarDatosPerfil("apellidos", vValue, [
              "Modificación exitosa",
              "Se han modificado los apellidos correctamente.",
            ]);
          } else {
            setvIsModificandoNombre(false);
          }
        } else {
          setvApellidosProfesorTmp(vApellidosProfesor);
          openNotification(
            "Error al modificar",
            "Debe tener por lo menos 1 caracter en los apellidos",
            "error"
          );
          setvIsModificandoNombre(false);
        }
        break;
      case "profesion":
        vCorrecciones = mValidarProfesion(vValue);
        if (vCorrecciones.length === 0) {
          if (vProfesionProfesorTmp !== vProfesionProfesor) {
            mEnivarDetallesPerfil("profesion", vValue, [
              "Modificación exitosa",
              `Se ha modificado la profesión correctamente.`,
            ]);
          } else {
            setvIsModificandoProfesion(false);
          }
        } else {
          setvProfesionProfesorTmp(vProfesionProfesor);
          openNotification(
            "Error al modificar",
            "Debe tener por lo menos 1 caracter en la profesión",
            "error"
          );
          setvIsModificandoProfesion(false);
        }

        break;

      default:
        vCorrecciones = mValidarDescripcion(vValue);
        if (vCorrecciones.length === 0) {
          if (vDescripcionProfesorTmp !== vDescripcionProfesor) {
            mEnivarDetallesPerfil("descripcion", vValue, [
              "Modificación exitosa",
              `Se ha modificado tú descripción correctamente.`,
            ]);
          } else {
            setvIsModificandoDescripcion(false);
          }
        } else {
          setvDescripcionProfesorTmp(vDescripcionProfesor);
          openNotification(
            "Error al modificar",
            "Debe tener por lo menos 1 caracter en la profesión",
            "error"
          );
          setvIsModificandoDescripcion(false);
        }

        break;
    }
  };

  const handleKeyDown = (event, vKey) => {
    switch (event.key) {
      case "Enter":
      case "Escape":
        mModificarValor(event.target.value, vKey);
        break;

      default:
        break;
    }
  };
  const handleChange = (e, setTmp) => {
    setTmp(e.target.value);
  };
  const handleBlur = (vValueTmp, vKey) => {
    mModificarValor(vValueTmp, vKey);
  };

  const mRenderNombre = () => {
    if (vIsModificandoNombre) {
      return (
        <>
          <input
            autoFocus={true}
            style={inputStyleNombre}
            placeholder="Escribe tú nombre aquí"
            type="text"
            className={`miElemento nombre-profesor ${
              vIsErrorNombre ? "error" : ""
            }`}
            onBlur={() => {
              setvFocusInput("");
              setTimeout(() => {
                setvBlurInput("nombre");
              }, 250);
            }}
            value={vNombreProfesorTmp}
            onChange={(e) => handleChange(e, setvNombreProfesorTmp)}
            onKeyDown={(e) => handleKeyDown(e, "nombre")}
            onFocus={() => {
              setvFocusInput("nombre");
            }}
          />
          <input
            style={inputStyleNombre}
            placeholder="Escribe tus apellidos aquí"
            type="text"
            className={`miElemento nombre-profesor ${
              vIsErrorApellidos ? "error" : ""
            }`}
            onBlur={() => {
              setvFocusInput("");
              setTimeout(() => {
                setvBlurInput("apellidos");
              }, 250);
            }}
            value={vApellidosProfesorTmp}
            onChange={(e) => handleChange(e, setvApellidosProfesorTmp)}
            onKeyDown={(e) => handleKeyDown(e, "apellidos")}
            onFocus={() => {
              setvFocusInput("apellidos");
            }}
          />
        </>
      );
    } else {
      return (
        <Tooltip
          color={"#323644"}
          className="
            nombre-profesor"
          title={vTextoModificar}
          placement="top"
        >
          <div
            class="nombre-profesor"
            onMouseLeave={() => setvIsHoverContent5(false)}
            onMouseEnter={() => setvIsHoverContent5(true)}
            onDoubleClick={() => setvIsModificandoNombre(true)}
          >
            {`${vNombreProfesor} ${vApellidosProfesor}`}
          </div>
        </Tooltip>
      );
    }
  };

  const mRenderDescripcion = () => {
    if (vIsModificandoDescripcion) {
      return (
        <textarea
          style={textAreaStyle}
          ref={textAreaRef}
          autoFocus={true}
          placeholder="Escribe tú descripción aquí"
          type="text"
          className={"text-descripcion-profesor"}
          onBlur={() => {
            setvBlurInput("descripcion");
          }}
          value={vDescripcionProfesorTmp}
          onChange={(e) => handleChange(e, setvDescripcionProfesorTmp)}
        />
      );
    } else {
      return (
        <Tooltip
          color={"#323644"}
          className="text-descripcion-profesor"
          title={vTextoModificar}
        >
          <div
            class="text-descripcion-profesor"
            onMouseLeave={() => setvIsHoverDescripcionProfesor(false)}
            onMouseEnter={() => setvIsHoverDescripcionProfesor(true)}
            onDoubleClick={() => setvIsModificandoDescripcion(true)}
            dangerouslySetInnerHTML={{
              __html: vDescripcionProfesor?.replace(/\n/g, "<br/>"),
            }}
          />
        </Tooltip>
      );
    }
  };
  const [visible, setVisible] = useState(false);
  const [visibleEditPerfil, setVisibleEditPerfil] = useState(false);

  const mRenderEstrellasCalificacion = (vNumCalificacion) => {
    var vEstrellas = [];

    for (let vIndex = 1; vIndex <= 5; vIndex++) {
      if (vIndex <= vNumCalificacion) {
        vEstrellas.push(
          <img
            src="../images/Vectors-Wrapper_35.svg"
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      } else {
        vEstrellas.push(
          <img
            src="../images/Vectors-Wrapper_31.svg"
            loading="lazy"
            width="17"
            height="17"
            alt=""
            className="vectors-wrapper-43"
          />
        );
      }
    }
    return vEstrellas;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div class="div-block-59">
      <div class="div-block-58"></div>
      <div class="div-block-60">
        <div
          class="img-pofile"
          onMouseEnter={() => {
            setVisible(true);
            setvIsHoverFotoPerfil(true);
          }}
          onMouseLeave={() => setvIsHoverFotoPerfil(false)}
          style={{
            backgroundImage: `linear-gradient(${vColorPerfil}, ${vColorPerfil})`,
          }}
          onClick={() => setVisible(true)}
        >
          {vIsHoverFotoPerfil && (
            <img
              src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65946827a372b411a589d393_Vectors-Wrapper.svg"
              loading="lazy"
              width="19"
              height="19"
              alt=""
              class="vectors-wrapper-156"
            />
          )}

          <div
            class="text-block-23"
            style={{
              color: Metodos.getContrastYIQ(vColorPerfil),
            }}
          >
            {vNombreProfesor.toUpperCase().charAt(0) +
              vApellidosProfesor.toUpperCase().charAt(0)}
          </div>
          <Popover
            placement="right"
            content={
              <ColorPicker
                disabledAlpha={true}
                format={"hex"}
                value={vColorPerfil}
                onChange={(e) => setvColorPerfil(e.toHexString())}
                onChangeComplete={(e) => {
                  mEnivarDatosPerfil("color", e.toHexString(), [
                    "Modificación exitosa",
                    `Se ha modificado el color del perfil correctamente.`,
                  ]);
                }}
              />
            }
            trigger="click"
            open={visible}
            onOpenChange={(e) => {
              if (!e) {
              }
              setVisible(e);
            }}
            color="#323644"
          />
        </div>
        <div class="contenido-5">
          <div class="frame-149 ">
            {mRenderNombre()}

            {vIsHoverContent5 && (
              <img
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/659468324228f9a1ae431051_Vectors-Wrapper.svg"
                loading="lazy"
                width="19"
                height="19"
                alt=""
                class="vectors-wrapper-156"
              />
            )}
            {vIsModificandoProfesion ? (
              <input
                autoFocus={true}
                style={inputStyleProfesion}
                placeholder="Escribe tú profesión aquí"
                type="text"
                className={`profesion-profesor`}
                onBlur={() => {
                  setvBlurInput("profesion");
                }}
                value={vProfesionProfesorTmp}
                onChange={(e) => handleChange(e, setvProfesionProfesorTmp)}
                onKeyDown={(e) => handleKeyDown(e, "profesion")}
              />
            ) : (
              <Tooltip
                color={"#323644"}
                className="profesion-profesor miElemento"
                title={vTextoModificar}
                placement="bottom"
              >
                <div
                  class="profesion-profesor miElemento"
                  onMouseLeave={() => setvIsHoverContent5(false)}
                  onMouseEnter={() => setvIsHoverContent5(true)}
                  onDoubleClick={() => setvIsModificandoProfesion(true)}
                >
                  {vProfesionProfesor}
                </div>
              </Tooltip>
            )}
            <div class="frame-248" onClick={showModal}>
              <div class="text-141">Editar</div>
            </div>
          </div>
          <div class="content-info-profesor miElemento">
            <div class="item-info">
              <div class="text-item-info">Estudiantes</div>
              <div class="text-item-info-2">34</div>
            </div>
            <wrapper class="vectors-wrapper-157"></wrapper>
            <div class="item-info">
              <div class="text-item-info">Cursos</div>
              <div class="text-item-info-2">
                {vDatosPerfil.detalles_perfil.total_cursos}
              </div>
            </div>

            <wrapper class="vectors-wrapper-157"></wrapper>
            <div class="item-info">
              <div class="calificacion-profesor">
                {mRenderEstrellasCalificacion(vCalificacionProfesor)}
              </div>
              <div class="text-item-info-2">{vCalificacionProfesor}</div>
            </div>
          </div>
          <div class="content-descripcion-profesor">
            {mRenderDescripcion()}

            {vIsHoverDescripcionProfesor && (
              <img
                src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65946827a372b411a589d393_Vectors-Wrapper.svg"
                loading="lazy"
                width="19"
                height="19"
                alt=""
                class="vectors-wrapper-156"
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        destroyOnClose={true}
        open={isModalOpen}
        footer={null}
        closeIcon={null}
      >
        <CInformacionPersonal
          {...props}
          handleCancel={handleCancel}
          setVisibleEditPerfil={setVisibleEditPerfil}
          vColorPerfil={vColorPerfil}
          vNombreProfesor={vNombreProfesor}
          vApellidosProfesor={vApellidosProfesor}
          setvColorPerfil={setvColorPerfil}
          mEnivarDatosPerfil={mEnivarDatosPerfil}
          visibleEditPerfil={visibleEditPerfil}
          vDatosPerfil={vDatosPerfil}
          mEnivarDatosPerfilAll={mEnivarDatosPerfilAll}
        />
      </Modal>
    </div>
  );
}

function CInformacionPersonal(props) {
  const {
    handleCancel,
    setVisibleEditPerfil,
    vColorPerfil,
    vNombreProfesor,
    vApellidosProfesor,
    setvColorPerfil,
    mEnivarDatosPerfil,
    visibleEditPerfil,
    vDatosPerfil,
    mEnivarDatosPerfilAll,
  } = props;

  var vNombre = vNombreProfesor;
  var vApellidos = vApellidosProfesor;
  var vUsuario = vDatosPerfil.user;
  var vTelefono = vDatosPerfil.telefono;
  var vEmail = vDatosPerfil.email;
  var vColor = vColorPerfil;
  const [vColorPerfilM, setvColorPerfilM] = useState(vColor);

  const [vIsEnviando, setvIsEnviando] = useState(false);

  function CInput(props) {
    const { vTipo, vTitulo, vValue, onChangeValue } = props;

    const [vValueTmp, setvValueTmp] = useState(vValue);

    if (vTipo !== "noedit") {
      return (
        <div class="frame-251">
          <div class="text-143">{vTitulo}</div>
          <div class="frame-241">
            <input
              class="input-info-personal"
              onChange={(evt) => {
                setvValueTmp(evt.target.value);
                onChangeValue(evt.target.value);
              }}
              value={vValueTmp}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div class="frame-251">
          <div class="text-143">{vTitulo}</div>
          <div class="frame-246">
            <div class="text-145">{vValue}</div>
          </div>
        </div>
      );
    }
  }

  const mEnviarDatos = () => {
    setvIsEnviando(true);
    var vDatos = {
      nombre: vNombre,
      apellidos: vApellidos,
      color: vColor,
      telefono: vTelefono,
    };
    mEnivarDatosPerfilAll(vDatos, [
      "Modificación exitosa",
      `Se han modificado los datos correctamente`,
    ]);
  };

  return (
    <div class="perfil" style={{ width: "100%" }}>
      <div class="text-142">Información Personal</div>
      <div class="div-block-73"></div>
      <div class="frame-258">
        <div
          class="vectors-wrapper-195"
          onMouseEnter={() => {
            setVisibleEditPerfil(true);
          }}
          style={{
            borderRadius: "50%",
            backgroundImage: `linear-gradient(${vColorPerfilM}, ${vColorPerfilM})`,
          }}
          onClick={() => setVisibleEditPerfil(true)}
        >
          <div
            class="text-block-23"
            style={{
              color: Metodos.getContrastYIQ(vColorPerfilM),
            }}
          >
            {vNombreProfesor.toUpperCase().charAt(0) +
              vApellidosProfesor.toUpperCase().charAt(0)}
          </div>
          <Popover
            placement="right"
            content={
              <ColorPicker
                disabledAlpha={true}
                format={"hex"}
                value={vColorPerfilM}
                onChange={(e) => setvColorPerfilM(e.toHexString())}
                onChangeComplete={(e) => {}}
              />
            }
            trigger="click"
            open={visibleEditPerfil}
            onOpenChange={(e) => {
              if (!e) {
              }
              setVisibleEditPerfil(e);
            }}
            color="#323644"
          />
        </div>
        <div class="frame-255">
          <CInput
            vTitulo="Nombre (s)"
            onChangeValue={(v) => (vNombre = v)}
            vValue={vDatosPerfil.nombre}
          />
          <CInput
            vTitulo="Apellidos"
            onChangeValue={(v) => (vApellidos = v)}
            vValue={vDatosPerfil.apellidos}
          />
          <CInput
            vTitulo="Usuario"
            vValue={vDatosPerfil.username}
            vTipo="noedit"
          />
          <CInput
            vTitulo="Teléfono"
            onChangeValue={(v) => (vTelefono = v)}
            vValue={vDatosPerfil.telefono}
          />
        </div>
        <div class="div-block-73"></div>
        <div class="frame-255">
          <CInput vTitulo="Email" vValue={vDatosPerfil.email} vTipo="noedit" />
        </div>
        <div class="div-block-73"></div>
        <div class="frame-255">
          <div class="frame-251">
            <div class="text-143">Contraseña</div>
            <div class="frame-246">
              <div class="frame-244">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88379efa56d844303c_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="10"
                  height="12"
                  alt=""
                  class="vectors-wrapper-192"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65bd2f88b093887f44eae738_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="7"
                  height="7"
                  alt=""
                  class="vectors-wrapper-193"
                />
              </div>
            </div>
          </div>
          {/*<div class="btn-change-pass">
                <div class="text-btn-change-pass">cambiar contraseña</div>
              </div> */}
        </div>
      </div>
      <div class="div-block-73"></div>
      <div class="frame-259">
        {vIsEnviando ? (
          <Spin />
        ) : (
          <>
            <div class="frame-245" onClick={handleCancel}>
              <div class="input-info-personal">Cancelar</div>
            </div>
            <div class="frame-249" onClick={mEnviarDatos}>
              <div class="text-146">Guardar cambios</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
