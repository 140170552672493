import { Progress, Spin } from "antd";
import React, { useEffect, useState } from "react";

import * as Metodos from "../../utils/Global/Metodos";

const CRenderRespuestas = (props) => {
  const { vRespuesta, value, onChange, vNum } = props;
  return (
    <div
      class="respuesta-conjunto"
      onClick={() => onChange(vRespuesta.id, vNum)}
    >
      <img
        src={
          value === vRespuesta.id
            ? "images/Vectors-Wrapper_109.svg"
            : "images/Vectors-Wrapper_108.svg"
        }
        loading="lazy"
        width="18"
        height="18"
        alt=""
        class="vectors-wrapper-152"
      />
      <div class="cuestionario-pgt-respuesta">{vRespuesta.descripcion}</div>
    </div>
  );
};

export default function APregunta(props) {
  const { vEvaluacion, setvNumFrame, setvRespuestasEvaluacion, vRespuestas } =
    props;
  const [vNumPregunta, setvNumPregunta] = useState(0);
  const [vPreguntas_Respuestas, setvPreguntas_Respuestas] = useState([]);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");
  const [vIsReadyPregunta, setvIsReadyPregunta] = useState(false);

  useEffect(() => {
    if (vRespuestas.length > 0) {
      setvPreguntas_Respuestas(vRespuestas);
      setvNumPregunta(vEvaluacion.meta.pagination.total - 1);
    } else {
      setvPreguntas_Respuestas(
        vEvaluacion.data.map((vPregunta) => {
          return { pregunta: vPregunta.id, respuesta: null };
        })
      );
    }

    setvIsReadyPregunta(true);
  }, [vEvaluacion]);

  const onChange = (e, vNum) => {
    var vTmp = vPreguntas_Respuestas;
    setOpcionSeleccionada(e);
    vTmp[vNum]["respuesta"] = e;
    setvPreguntas_Respuestas(vTmp);
  };

  const mCambiarFrame = (vNumFrame) => {
    setvNumFrame(vNumFrame);
    setvRespuestasEvaluacion(vPreguntas_Respuestas);
  };

  return (
    <div class="curso-alumno-video">
      <div class="cuestionario-pregunta">
        <div class="cuestionario-contenido">
          <div class="cuestionario-num-pgt">
            <div class="cuestionario-num-pregunta-text">
              Pregunta {vNumPregunta + 1}/{vEvaluacion.meta.pagination.total}
            </div>
          </div>
          <div class="cuestionario-pgt">
            <div class="cuestionario-pgt-izq">
              {vNumPregunta > 0 && (
                <img
                  src={`${window.location.origin}/images/Vectors-Wrapper_64.svg`}
                  loading="lazy"
                  alt=""
                  class="image-14"
                  onClick={() => setvNumPregunta(vNumPregunta - 1)}
                />
              )}
            </div>

            <div class="cuestionario-pgt-cen">
              {vIsReadyPregunta ? (
                <>
                  <div class="cuestionario-pgt-pregunta">
                    {vNumPregunta + 1}.{" "}
                    {vEvaluacion.data[vNumPregunta].attributes.pregunta}
                  </div>
                  <div class="cuestionario-pgt-preguntas">
                    {vEvaluacion.data[vNumPregunta].attributes.respuesta.map(
                      (vRespuesta) => (
                        <CRenderRespuestas
                          value={
                            vPreguntas_Respuestas[vNumPregunta].respuesta ===
                            null
                              ? opcionSeleccionada
                              : vPreguntas_Respuestas[vNumPregunta].respuesta
                          }
                          onChange={onChange}
                          vRespuesta={vRespuesta}
                          vNum={vNumPregunta}
                        />
                      )
                    )}
                  </div>
                </>
              ) : (
                <Spin />
              )}
            </div>
            <div class="cuestionario-pgt-der">
              <img
                onClick={() =>
                  vNumPregunta + 1 === vEvaluacion.meta.pagination.total
                    ? mCambiarFrame(1)
                    : setvNumPregunta(vNumPregunta + 1)
                }
                src={`${window.location.origin}/images/Vectors-Wrapper_64.svg`}
                loading="lazy"
                alt=""
                class="image-13"
              />
            </div>
          </div>
        </div>

        <Progress
          strokeColor={
            vNumPregunta + 1 === vEvaluacion.meta.pagination.total
              ? "rgba(165, 255, 50, 0.7)"
              : "rgba(112, 117, 133, 1)"
          }
          trailColor="rgba(33, 36, 46, 1)"
          className="cuestionario-bar-progress"
          percent={Metodos.calcularPorcentaje(
            vNumPregunta + 1,
            vEvaluacion.meta.pagination.total
          )}
          showInfo={false}
        />
      </div>
    </div>
  );
}
