import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Skeleton, ConfigProvider, Form, message } from "antd";
import * as Input from "../../input_login_singup/Input";
import "./style.css";
import * as Post from "../../../utils/Rest apis/Posts";
import * as Variables from "../../../utils/Global/Variables";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const { handleCancel, mCambiarTipo, messageApi, setvFrame } = props;
  const [vIsDisable, setvIsDisable] = useState(true);
  const [messageApiError, contextHolder] = message.useMessage();

  const formRef = useRef(null);

  const [formValues, setFormValues] = useState({
    correo: '',
    contrasenia: ''
  });

  const [errors, setErrors] = useState({
    correo: '',
    contrasenia: '',
  });

  const handleChange = (id, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { correo, contrasenia } = formValues;

    let correoError = '';
    if (!correo) {
      correoError = 'El correo es requerido';
    } else if (!/\S+@\S+\.\S+/.test(correo)) {
      correoError = 'Ingresa un correo válido';
    }

    let contraseniaError = '';
    if (!contrasenia) {
      contraseniaError = 'La contraseña es requerida';
    }

    setErrors({
      correo: correoError,
      contrasenia: contraseniaError,
    });

    if (!correoError && !contraseniaError) {
      // Aquí puedes manejar el envío del formulario, por ejemplo, enviando los datos a un servidor
      console.log('Formulario enviado:', { correo, contrasenia });
      onFinish({ correo, contrasenia })
    }
  };

  const navigate = useNavigate();

  const onFinish = (values) => {
    setvIsDisable(true);
    Post.mLogin(values)
      .then((response) => response.json())
      .then((response) => {
        if (response?.jwt) {
          messageApi.open({
            type: "success",
            content: "Hola, " + response.user.nombre,
            duration: 10,
          });

          localStorage.setItem("jwt", response?.jwt);
          switch (response.user.role_name) {
            case "admin":
              navigate("/admin", { replace: true });
              break;
            case "profesor":
              navigate("/profesor", { replace: true });
              break;

            default:
              navigate("/alumno", { replace: true });
              break;
          }
          handleCancel();
        } else {
          if (response?.errorMessage) {
            messageApiError.open({
              type: "error",
              content: response.errorMessage,
              duration: 5,
            });
          } else {
            messageApiError.open({
              type: "error",
              content: "Error desconocido, contacta a un administrador.",
              duration: 5,
            });
          }
        }
        setvIsDisable(false);
      })
      .catch((err) => {
        messageApiError.open({
          type: "error",
          content: "Error desconocido, contacta a un administrador.",
          duration: 5,
        });
        setvIsDisable(false);
      });
  };

  return (
    <>
      {contextHolder}
      <div class="div-title-md">
        <div class="subtitle-md">SIGUE APRENDIENDO</div>
        <div class="title-md">Ingresa a tu cuenta.</div>
        <div class="div-md-more-text">
          <div class="text1-md">¿No tienes una cuenta?</div>
          <div class="text2-md" onClick={mCambiarTipo}>
            Solicítala aquí
          </div>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit} className="form-md">
        <div className="inputs-md">
          <Input.Correo
            id="correo"
            nombre="Correo"
            required={true}
            vIsError={!!errors.correo}
            messageError={errors.correo}
            onChange={handleChange}
            placeholder="Ingresa tu correo"
          />
          <Input.Contraseña
            id="contrasenia"
            nombre="Contraseña"
            placeholder="Ingresa tu contraseña"
            required={true}
            vIsError={!!errors.contrasenia}
            messageError={errors.contrasenia}
            onChange={handleChange}
          />
        </div>
        <div className="butons-md btn2-md-crr">
          <div className="btn1-md"  onClick={handleCancel}>
            <div className="text-btn1-md">Cancelar</div>
          </div>
          <button className="btn2-md" type="submit" >
            <div class="text-btn2-md">Iniciar Sesión</div>
          </button>
        </div>
      </form>
    </>
  );
}
