import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuAlumno from "../menuAlumno/MenuAlumno";
import * as Metodos from "../../utils/Global/Metodos";
import * as Variables from "../../utils/Global/Variables";
import * as Gets from "../../utils/Rest apis/Gets";
import { Badge, Spin, Tooltip } from "antd";

export default function UIAlumno(props) {
  const {
    vBodyContent,
    vHorasStudio,
    openNotification,
    vIsLoadingDetallesPerfil,
    setvIsLoadingDetallesPerfil,
    setVUserMeDetallesPerfil,
    setVHorasStudio,
    setVUserMeTmp,
    location,
    vTipo,
    vIdCurso,
  } = props;
  const navigate = useNavigate();
  const [vUserMe, setVUserMe] = useState({});

  const [vIsLoading, setVIsLoading] = useState(true);
  const [vNumNotificaciones, setvNumNotificaciones] = useState(0);

  // Estado para almacenar las dimensiones actuales de la ventana
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    setVIsLoading(true);
  }, [location]);

  useEffect(() => {
    Gets.mUserMe()
      .then((response) => {
        if (!response.ok) {
          openNotification(
            "Congruencia en datos del perfil",
            "Se produjo un error al momento de cargar los datos del perfil.",
            "error"
          );
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      }) // 2
      .then((response) => {
        localStorage.setItem("id_alumno", response?.id);
        localStorage.setItem("nombre_a", response?.nombre);
        localStorage.setItem("apellidos_a", response?.apellidos);
        localStorage.setItem("color_a", response?.color);
        setVUserMe(response);
        setVUserMeTmp(response);
        Gets.mUserMeDetallesPerfil()
          .then((response2) => response2.json()) // 2
          .then((response2) => {
            setVHorasStudio(response2[0]?.segundos_estudio);

            const uniqueCourses = response2[0].mi_progreso
              .slice()
              .reverse()
              .reduce((acc, current) => {
                const x = acc.find(
                  (item) => item.id_curso === current.id_curso
                );
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, [])
              .reverse(); // Aplica reverse nuevamente para restaurar el orden original

            setVUserMeDetallesPerfil(uniqueCourses);
          })
          .catch((error) => {
            navigate("/", { replace: true });
          })
          .finally(() => {
            setvIsLoadingDetallesPerfil(false);
          });
      })
      .catch((error) => {
        navigate("/", { replace: true });
      })
      .finally(() => {
        setVIsLoading(false);
      });

    // Función manejadora del cambio de tamaño
    function handleResize() {
      // Establece las nuevas dimensiones en el estado
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    document.body.style.overflow = "hidden";

    // Agrega el event listener
    window.addEventListener("resize", handleResize);

    // Función de limpieza para eliminar el event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [location]); // La dependencia es el objeto de ubicación, que cambia cada vez que cambia la ruta

  const mRenderAvatar = () => {
    if (vIsLoading) {
      return (
        <>
          <div
            data-figma-id="635:3109"
            class="perfil-icon"
            style={{
              backgroundImage: `linear-gradient(#fff, #fff)`,
            }}
          >
            <div
              class="perfil-icon-texto"
              data-figma-id="635:3110"
              style={{ color: Metodos.getContrastYIQ("#ffffff") }}
            ></div>
          </div>
        </>
      );
    } else {
      return (
        <Tooltip
        open={false}
          title={
            vNumNotificaciones > 99
              ? "Tienes muchas notificaciones"
              : `Tienes ${vNumNotificaciones} ` +
                (vNumNotificaciones > 1 || vNumNotificaciones === 0
                  ? "notificaciones"
                  : "notificación")
          }
        >
          <Badge count={vNumNotificaciones}>
            <>
              <div
                data-figma-id="635:3109"
                class="perfil-icon"
                style={{
                  backgroundImage: `linear-gradient(${vUserMe.color}, ${vUserMe.color})`,
                }}
              >
                <div
                  class="perfil-icon-texto"
                  data-figma-id="635:3110"
                  style={{
                    color: Metodos.getContrastYIQ(vUserMe.color),
                  }}
                >
                  {vUserMe.nombre.toUpperCase().charAt(0) +
                    vUserMe.apellidos.toUpperCase().charAt(0)}
                </div>
              </div>
            </>
          </Badge>
        </Tooltip>
      );
    }
  };

  const mIsCursoComprado = () => {
    return (
      vIdCurso != null &&
      vIdCurso.length > 0 &&
      /^\d+$/.test(vIdCurso) &&
      vTipo === Variables.vFrames.alumno.mis_cursos
    );
  };

  const mRenderHeader = (vTitulo, vConteoIcon) => {
    return (
      <div
        data-figma-id="635:3115"
        class="header-chido"
        style={mIsCursoComprado() ? { maxWidth: "1741px" } : {}}
      >
        <div data-figma-id="635:3106" class="mis-cursos-alumno-perfil">
          {mRenderAvatar()}
        </div>
        <div data-figma-id="635:3111" class="mis-cursos-alumno-titulo">
          <div class="mis-cursos-alumno-texto-titulo " data-figma-id="635:3112">
            {vTitulo}
          </div>
          {vTipo !== Variables.vFrames.alumno.perfil &&
            vTipo !== Variables.vFrames.alumno.curso_no_comprado &&
            !mIsCursoComprado() && (
              <div data-figma-id="635:3113" class="conteo-icon">
                <div class="conteo-icon-texto" data-figma-id="635:3114">
                  {vConteoIcon}
                </div>
              </div>
            )}
        </div>
      </div>
    );
  };

  const mDetectarBody = () => {
    switch (vTipo) {
      case Variables.vFrames.alumno.mis_cursos:
        if (vIdCurso != null && vIdCurso.length > 0 && /^\d+$/.test(vIdCurso)) {
          return (
            <div class="curso-alumno-body">
              {vBodyContent(windowSize, mRenderHeader)}
            </div>
          );
        } else {
          return (
            <div
              class="mis-cursos-alumno-body"
              style={{ height: windowSize.height }}
            >
              {vBodyContent(windowSize, mRenderHeader)}
            </div>
          );
        }
      case null:
      case Variables.vFrames.alumno.home:
        return (
          <div class="home-alumno-body" style={{ height: windowSize.height }}>
            {vBodyContent(windowSize, mRenderHeader)}
          </div>
        );

      case Variables.vFrames.alumno.perfil:
        return (
          <div class="perfil-alumno-body" style={{ height: windowSize.height }}>
            {mRenderHeader(
              !vIsLoading ? `¡Hola ${vUserMe.nombre} ! 👋🏼` : " ",
              ""
            )}
            {vBodyContent(windowSize)}
          </div>
        );
      case Variables.vFrames.alumno.curso_no_comprado:
        return (
          <div class="curso-no-comprado-alumno-body">
            {vBodyContent(windowSize, mRenderHeader)}
          </div>
        );

      default:
        return (
          <div class="body-2" style={{ height: windowSize.height }}>
            <div class="header-2">{mRenderAvatar()}</div>
            <div class="frame-30">
              {vBodyContent(windowSize, mRenderHeader)}
            </div>
          </div>
        );
    }
  };

  return (
    <body class="body-3" style={{ height: windowSize.height }}>
      <MenuAlumno
        {...props}
        vIsCursoComprado={mIsCursoComprado}
        vNum={
          vIsLoadingDetallesPerfil
            ? [<Spin />, "horas"]
            : Metodos.convertirYDenominar(vHorasStudio)
        }
      />
      {mDetectarBody()}
    </body>
  );
}
