import { Collapse, Spin } from "antd";
import React, { useEffect, useState } from "react";
import VideoPlayerProfesor from "../../../components/videoPlayer/VideoPlayerProfesor";
import * as Variables from "../../../utils/Global/Variables";

export default function Recursos(props) {
  const {
    BotonesCurso,
    nombreCurso,
    vSecciones,
    vJWT_Access,
    vCalidadImagen,
    windowSize,
  } = props;

  const [vLeccionSeleccionada, setVLeccionSeleccionada] = useState(null);
  const [vIsLoadingLeccion, setVIsLoadingLeccion] = useState(true);
  const [vKeyVideo, setVKeyVideo] = useState(new Date().now);
  function Leccion(props) {
    const { vLeccion, vLeccionSeleccionada } = props;

    return (
      <p
        onClick={() => {
          setVIsLoadingLeccion(true);
          setVLeccionSeleccionada(vLeccion);
          setVKeyVideo(new Date().now);
          setVIsLoadingLeccion(false);
        }}
        style={{
          cursor: "pointer",
          color:
            `${vLeccionSeleccionada?.num_leccion}` === `${vLeccion.num_leccion}`
              ? "write"
              : "gray",
        }}
      >
        Leccion {vLeccion.num_leccion}:{" "}
        {vLeccion.attributes.nombre != null ? vLeccion.attributes.nombre : "-"}
      </p>
    );
  }

  function RenderCentro(props) {
    const { vIsLoadingLeccion } = props;
    if (vLeccionSeleccionada === null) {
      return <p style={{ color: "white." }}>Seleccione una leccion</p>;
    }
    return (
      <>
        {vIsLoadingLeccion ? (
          <Spin />
        ) : (
          <>
            <div class="btn-video-profesor">
              <img
                src="../images/Vectors-Wrapper_74.svg"
                loading="lazy"
                width="25"
                height="25"
                alt=""
                class="vectors-wrapper-96"
              />
              <div class="btn-text-recursos">Video</div>
            </div>
            <VideoPlayerProfesor
              key={vKeyVideo}
              {...props}
              src={
                Variables.V_URL_API +
                `/api2/video_leccion?id_leccion=${
                  vLeccionSeleccionada.id
                }&video=${
                  vLeccionSeleccionada.attributes.ruta_video
                }&ath_accss=${"Bearer " + vJWT_Access}`
              }
              src_portada={
                Variables.V_URL_API +
                `/api2/img?id_leccion=${vLeccionSeleccionada.id}&img=${
                  vLeccionSeleccionada.attributes.image_portada.data.attributes
                    .url
                }&ath_accss=${
                  "Bearer " + vJWT_Access
                }&quality=${vCalidadImagen}`
              }
              vIsLoadingTemario={vIsLoadingLeccion}
            />
          </>
        )}
      </>
    );
  }
  function RenderDerecha(props) {
    if (vLeccionSeleccionada === null) {
      return <p style={{ color: "white." }}>Seleccione una leccion</p>;
    }

    const mRednerTipoRecurso = (vRecurso, index, vTotalRecursos) => {
      switch (vRecurso.tipo) {
        case 0:
          return (
            <>
              <div class="item-recurso-contenedor">
                <div class="item-recurso-text-video">
                  <img
                    src="../images/Vectors-Wrapper_74.svg"
                    loading="lazy"
                    width="25"
                    height="25"
                    alt=""
                    class="vectors-wrapper-96"
                  />
                  <div class="btn-text-glosario">Video</div>
                </div>
                <div class="text-block-30">{vRecurso.nombre}</div>
              </div>
              {index < vTotalRecursos && (
                <div class="separador-recursos-profesor" />
              )}
            </>
          );
        case 1:
          return (
            <>
              <div class="item-recurso-contenedor">
                <div class="item-recurso-text-infografia">
                  <img
                    src="../images/Vectors-Wrapper_77.svg"
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                    class="vectors-wrapper-100"
                  />
                  <div class="btn-text-glosario">Infografia</div>
                </div>
                <div class="text-block-30">{vRecurso.nombre}</div>
              </div>
              {index < vTotalRecursos && (
                <div class="separador-recursos-profesor" />
              )}
            </>
          );
        case 2:
          return (
            <>
              <div class="item-recurso-contenedor">
                <div class="item-recurso-text-precentacion">
                  <img
                    src="../images/Vectors-Wrapper_79.svg"
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                    class="vectors-wrapper-102"
                  />
                  <div class="btn-text-glosario">Precentación</div>
                </div>
                <div class="text-block-30">{vRecurso.nombre}</div>
              </div>
              {index < vTotalRecursos && (
                <div class="separador-recursos-profesor" />
              )}
            </>
          );
        case 3:
          return (
            <>
              <div class="item-recurso-contenedor">
                <div class="item-recurso-text-plantilla">
                  <img
                    src="../images/Vectors-Wrapper_81.svg"
                    loading="lazy"
                    width="20"
                    height="20"
                    alt=""
                    class="vectors-wrapper-104"
                  />
                  <div class="btn-text-glosario">Plantilla Excel</div>
                </div>
                <div class="text-block-30">{vRecurso.nombre}</div>
              </div>
              {index < vTotalRecursos && (
                <div class="separador-recursos-profesor" />
              )}
            </>
          );

        default:
          break;
      }
    };

    return (
      <>
        <div class="btn-video-profesor">
          <img
            src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65b92782c36cc8dafbbe8d62_Vectors-Wrapper.svg"
            loading="lazy"
            width="13.602392196655273"
            height="15"
            alt=""
            class="vectors-wrapper-187"
          />
          <div class="btn-text-recursos">Recursos</div>
        </div>
        {vLeccionSeleccionada.attributes.recursos.map((vRecurso, index) => {
          return mRednerTipoRecurso(
            vRecurso,
            index,
            vLeccionSeleccionada.attributes.recursos.length - 1
          );
        })}
      </>
    );
  }

  const { Panel } = Collapse;
  return (
    <div
      class="conteniido-curso-profesor"
      style={{ height: `${windowSize.height - 80}px` }}
    >
      <div class="text-title-curso">{nombreCurso}</div>
      <BotonesCurso {...props} />
      <div
        class="container-curso-profesor"
        style={{ height: `${windowSize.height - 120}px` }}
      >
        <div class="text-133">Recursos</div>
        <div class="container-curso-profesor-body">
          <div class="div-contenido">
            <Collapse
              bordered={false}
              style={{ width: "100%" }}
              expandIconPosition={"end"}
              defaultActiveKey={"1"}
              accordion
            >
              {vSecciones.map((vSeccion) => {
                return (
                  <Panel
                    header={`Seccion ${vSeccion.num_seccion}: ${vSeccion.attributes.nombre}`}
                    key={vSeccion.num_seccion}
                  >
                    {vSeccion.attributes.lecciones.data.map((vLeccion) => (
                      <Leccion
                        vLeccion={vLeccion}
                        vLeccionSeleccionada={vLeccionSeleccionada}
                      />
                    ))}
                  </Panel>
                );
              })}
            </Collapse>
            ;
          </div>
          <div key={vKeyVideo} class="div-contenido">
            <RenderCentro vIsLoadingLeccion={vIsLoadingLeccion} />
          </div>

          <div class="div-contenido">
            <RenderDerecha vIsLoadingLeccion={vIsLoadingLeccion} />
          </div>
        </div>
      </div>
    </div>
  );
}
