import React, { useEffect, useState } from "react";

import * as Variables from "../utils/Global/Variables";
import Header from "../components/header/Header";
import Particles from "../components/particles/particles";
import Tabla from "../components/Tabla/tabla";
import CourseNotPurchased from "../components/CursoNoComprado/CourseNotPurchased";

import * as Gets from "../utils/Rest apis/Gets";
import { useNavigate, useParams } from "react-router-dom";

export default function PublicCourseNotPurchased(props) {
  const { setvFrame, key } = props;
  const [vId, setvId] = useState(useParams()?.id);
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (!vId) {
      navigate("/", { replace: true });
    }

    // Función manejadora del cambio de tamaño
    function handleResize() {
      // Establece las nuevas dimensiones en el estado
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    document.body.style.overflow = "hidden";

    // Agrega el event listener
    window.addEventListener("resize", handleResize);

    // Función de limpieza para eliminar el event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="section-course-no-comprado" style={{ height: windowSize.height }}>
      <Particles className="section-header-course-no-purchased">
        <Header
          setvFrame={setvFrame}
          vSelected={Variables.vFrames.course_not_purchased}
        />
      </Particles>
      <CourseNotPurchased key={key} vId={vId} vIsA={false} />
    </div>
  );
}
