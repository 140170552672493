import React from "react";

import * as Variables from "../../utils/Global/Variables";

import "./style.css";
import { Link } from "react-router-dom";
import { Spin } from "antd";

export default function MenuAlumno(props) {
  const { vNum, vTipo, vIdCurso, vIsCursoComprado, vIsLoadingDetallesPerfil } =
    props;

  if (vIsCursoComprado()) {
    return (
      <div data-figma-id="362:1118" class="menu-lateral-chico">
        <div data-figma-id="362:1104" class="menu-header">
          <div
            data-figma-id="427:2114"
            class="div-block-78"
            style={{ display: "block" }}
          >
            <img
              class="img"
              width="38"
              src={`${Variables.V_URL_API}/uploads/elisa_isotipo_Blanco_c656a0fb97.png`}
              data-figma-id="427:2111"
            />
          </div>
          <div data-figma-id="362:1106" class="menu-iconos">
            <Link to="/alumno" className={`link-style menu-icono-item`}>
              <>
                <img
                  src={`${window.location.origin}/images/Vectors-Wrapper_39.svg`}
                  data-figma-id="123:584"
                />
              </>{" "}
            </Link>
            <Link
              to="/alumno?tipo=mio"
              className={`link-style menu-icono-item`}
            >
              <>
                <img
                  src={`${window.location.origin}/images/Vectors-Wrapper_40.svg`}
                  data-figma-id="123:588"
                />
              </>
            </Link>
            <Link
              to="/alumno?tipo=perfil"
              className={`link-style menu-icono-item`}
            >
              <>
                <img
                  src={`${window.location.origin}/images/Vectors-Wrapper_41.svg`}
                  data-figma-id="123:592"
                />
              </>
            </Link>
          </div>
        </div>
        <Link
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          className="link-style menu-log-out"
        >
          <>
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_43.svg`}
              data-figma-id="123:646"
            />
          </>
        </Link>
      </div>
    );
  }

  return (
    <div class="menu-3">
      <div className="div-block-77">
        <img
          src={`${Variables.V_URL_API}/uploads/elisa_final_web_150x41_Blanco_7b2478c47c.png`}
          loading="lazy"
          width="150"
          height="41"
          alt=""
          class="image-1"
        />
        <div data-figma-id="427:2114" class="div-block-78">
          <img
            class="img"
            width="38"
            src={`${Variables.V_URL_API}/uploads/elisa_isotipo_Blanco_c656a0fb97.png`}
            data-figma-id="427:2111"
          />
        </div>
        <div class="menu-lateral">
          <Link
            to="/alumno"
            className={`link-style menu-item${
              vTipo === null ? "-seleccionado" : ""
            }`}
          >
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_39.svg`}
              loading="lazy"
              width="25"
              height="25"
              alt=""
              class="vectors-wrapper-47"
            />
            <div class="item">Home</div>
          </Link>
          <Link
            to="/alumno?tipo=mio"
            className={`link-style menu-item${
              vTipo === "mio" && vIdCurso === null ? "-seleccionado" : ""
            }`}
          >
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_40.svg`}
              loading="lazy"
              width="25"
              height="25"
              alt=""
              class="vectors-wrapper-47"
            />
            <div class="item">Mis Cursos</div>
          </Link>
          <Link
            to="/alumno?tipo=perfil"
            className={`link-style menu-item${
              vTipo === "perfil" ? "-seleccionado" : ""
            }`}
          >
            <img
              src={`${window.location.origin}/images/Vectors-Wrapper_41.svg`}
              loading="lazy"
              width="25"
              height="25"
              alt=""
              class="vectors-wrapper-47"
            />
            <div class="item">Perfil</div>
          </Link>
        </div>
      </div>

      <div class="frame-14">
        <div class="cuadro-imagen">
          <img
            src={`${window.location.origin}/images/Vectors-Wrapper_42.svg`}
            loading="lazy"
            width="223"
            height="317"
            alt=""
            class="vectors-wrapper-48"
          />
          <img
            src="images/imagen.png"
            loading="lazy"
            width="213"
            height="220"
            alt=""
            class="imagen"
          />
          <div class="texto">
            {vIsLoadingDetallesPerfil ? (
              <Spin />
            ) : (
              <>
                <div class="text-38">{vNum[0]}</div>
                <div class="div-block-9">
                  <div class="text-hora-estudio">{vNum[1]} de estudio</div>
                  <div class="text-hora-estudio-2">¡Sigue asi!</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Link
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
        className="link-style menu-item"
      >
        <img
          src={`${window.location.origin}/images/Vectors-Wrapper_43.svg`}
          loading="lazy"
          width="25"
          height="25"
          alt=""
          class="vectors-wrapper-47"
        />
        <div class="item">Log Out</div>
      </Link>
    </div>
  );
}
