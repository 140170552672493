import * as Variables from "../Global/Variables";

export async function mLogin(vDatos) {
  const formData = new FormData();
  formData.append("identifier", vDatos.correo);
  formData.append("password", vDatos.contrasenia);

  return await fetch(Variables.V_URL_API + "/api2/login", {
    mode: "cors",
    method: "POST",
    body: formData,
  }); // 3
}
export async function mSignup(vDatos) {
  const formData = new FormData();
  formData.append("data", JSON.stringify(vDatos));

  return await fetch(Variables.V_URL_API + "/api2/register", {
    mode: "cors",
    method: "POST",
    body: formData,
  }); // 3
}
export async function mContactanosSoporte(vDatos) {
  var formdata = new FormData();
  formdata.append("nombres", vDatos["nombre"]);
  formdata.append("apellidos", vDatos["apellidos"]);
  formdata.append("mail", vDatos["email"]);
  formdata.append("telefono", vDatos["phone"]);
  formdata.append("mensaje", vDatos["message"]);
  formdata.append("institucion", vDatos["institucion"]);

  var requestOptions = {
    mode: "cors",
    method: "POST",
    body: formdata,
  };

  return await fetch(
    Variables.V_URL_API + "/api2/contacto-soporte",
    requestOptions
  );
}
export async function mEnviarPreguntaForoA(vDatos) {
  var formdata = new FormData();
  formdata.append("pregunta", vDatos["pregunta"]);
  formdata.append("mensaje", vDatos["mensaje"]);
  formdata.append("id_user", vDatos["id_user"]);
  formdata.append("id_curso", vDatos["id_curso"]);
  formdata.append("numero_leccion", vDatos["numero_leccion"]);
  formdata.append("id_leccion", vDatos["id_leccion"]);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("jwt")}`);

  var requestOptions = {
    mode: "cors",
    method: "POST",
    body: formdata,
    headers: myHeaders,
  };

  return await fetch(
    Variables.V_URL_API + "/api2/preguntas-foro",
    requestOptions
  );
}
