import React, { useEffect, useState } from "react";

import "./style.css";
import { Table, Button, Progress, ConfigProvider, Modal, Spin } from "antd";
import {
  CheckCircleOutlined,
  FileTextOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import * as Gets from "../../../utils/Rest apis/Gets";

const columns = [
  {
    width: 50,
    title: "Acceso",
    dataIndex: "acceso",
    key: "acceso",
    render: (acceso) => (
      // Suponiendo que estado es un booleano, renderiza un ícono de check si es true
      <>
        {acceso ? (
          <CheckCircleOutlined style={{ color: "#A5FF32", fontSize: "20px" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#84868f", fontSize: "20px" }} />
        )}
      </>
    ),
  },
  {
    title: "Estudiante",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    width: 100,
    title: "Certificado",
    dataIndex: "certificado",
    key: "certificado",
    render: (certificado) => {
      // Botón con ícono que realizará una acción al hacer clic
      var vTexto = certificado;
      certificado = certificado !== "none";
      return (
        <Button
          shape="circle"
          type="text"
          icon={
            certificado ? (
              <FileTextOutlined style={{ color: "#fff", fontSize: "20px" }} />
            ) : (
              <FileTextOutlined
                style={{ color: "#ffffff51", fontSize: "20px" }}
              />
            )
          }
          onClick={() => {
            if (certificado) {
              alert("Descargando certificado");
            }
          }}
        />
      );
    },
  },
  {
    width: 150,
    title: "Vencimiento",
    dataIndex: "vencimiento",
    key: "vencimiento",
    render: (vencimiento) => (
      // Texto con un borde, reemplazar colores y estilos según necesidades
      <div
        style={{
          border: `1px solid ${
            vencimiento.bandera === 0
              ? "#fff"
              : vencimiento.bandera === 1
              ? "#ffff00"
              : "#ff0000"
          }`, // reemplaza #colorBorde con el color deseado
          color:
            vencimiento.bandera === 0
              ? "#fff"
              : vencimiento.bandera === 1
              ? "#ffff00"
              : "#ff0000", // reemplaza #colorTexto con el color deseado
          borderRadius: "5px",
          width: "125px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",

          padding: "5px 20px",
        }}
      >
        {vencimiento.fecha_vencimiento}
      </div>
    ),
  },
  {
    title: "Avance",
    dataIndex: "avance",
    key: "avance",
    render: (avance) => (
      // Componente de progreso de Ant Design
      <ConfigProvider
        theme={{
          components: {
            Progress: {
              colorText: "#fff",
            },
          },
        }}
      >
        <Progress percent={avance} size="small" />
      </ConfigProvider>
    ),
  },
  {
    width: 50,
    title: "Nota",
    dataIndex: "nota",
    key: "nota",
    render: (nota) => (
      // Texto con un borde, similar al del vencimiento
      <div
        style={{
          border: "1px solid #fff", // reemplaza #colorBordeNota con el color deseado
          color: "#fff", // reemplaza #colorTextoNota con el color deseado
          padding: "5px 20px",
          borderRadius: "5px",
          width: "65px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {nota}
      </div>
    ),
  },
];

function InfoAlumno(props) {
  const { isModalOpen, handleOk, handleCancel, vIdCurso, vIdEstudiante } =
    props;
  const [vTemario, setvTemario] = useState([]);
  const [vSecciones, setvSecciones] = useState("");
  const [vLecciones, setvLecciones] = useState("");
  const [vUltima_Conexion, setvUltima_Conexion] = useState("");
  const [vIsLoadingTemario, setVIsLoadingTemario] = useState(true);
  var vCountSeccion = 0;

  useEffect(() => {
    setVIsLoadingTemario(true);
    if (isModalOpen) {
      Gets.mGetEstudianteCursoProfesor(vIdCurso, vIdEstudiante)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Respuesta de red no fue exitosa");
          }
          return response.json();
        })
        .then((result) => {
          setvTemario(result.data);
          setvLecciones(result.info.lecciones);
          setvSecciones(result.info.secciones);
          setvUltima_Conexion(result.info.ultima_conexion);
        })
        .catch(() => {})
        .finally(() => {
          setVIsLoadingTemario(false);
        });
    }
  }, [isModalOpen]);

  const mGenerarTemario = () => {
    return vTemario.map((vSeccion) => {
      return (
        <div class="seccion">
          <div class="text-128">{vSeccion.nombre}</div>
          {vSeccion.lecciones === "vavio"
            ? "vacio"
            : vSeccion.lecciones.map((vLeccion) => {
                return (
                  <div class="frame-165">
                    <div
                      class="text-129"
                      style={{
                        color:
                          vLeccion.bandera === 0
                            ? "#A5FF32"
                            : vLeccion.bandera === 1
                            ? "#1DE8F5"
                            : "#B1BDCD", // remplaza #colorTexto con el color deseadoe
                      }}
                    >
                      {vLeccion.nombre}
                    </div>
                    <div class="text-130">{vLeccion.calificacion}</div>
                  </div>
                );
              })}
        </div>
      );
    });
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
      closeIcon={false}
      wrapClassName="custom-modal"
    >
      <div class="container-modal-body">
        {vIsLoadingTemario ? (
          <div style={{ height: "50px" }}>
            <Spin />
          </div>
        ) : (
          <>
            <div class="frame-modulos-y-lecciones">
              <div class="text-127">
                Última conexión: Hace {vUltima_Conexion}
              </div>
              {mGenerarTemario()}
            </div>
            <div class="lateral-2">
              <div class="modulos-2">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65b060f89d9528a3f6fcc595_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="20"
                  height="20"
                  alt=""
                  class="vectors-wrapper-185"
                />
                <div class="frame-166">
                  <div class="text-131">Secciones</div>
                  <div class="text-131">{vSecciones}</div>
                </div>
              </div>
              <div class="modulos-2">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65b060f913e51b058250161a_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="20"
                  height="20"
                  alt=""
                  class="vectors-wrapper-185"
                />
                <div class="frame-166">
                  <div class="text-131">Lecciones</div>
                  <div class="text-131">{vLecciones}</div>
                </div>
              </div>
              <div class="modulos-2">
                <img
                  src="https://uploads-ssl.webflow.com/64d7b17fb928cf4cb9a952fa/65b060fa9b0d4a7a7ae8ad52_Vectors-Wrapper.svg"
                  loading="lazy"
                  width="20"
                  height="20"
                  alt=""
                  class="vectors-wrapper-185"
                />
                <div class="frame-166">
                  <div class="text-131">Evaluaciones</div>
                  <div class="text-131">{vLecciones}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

function TablaEstudiantes(props) {
  const { vData } = props;
  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "row-par " : "row-impar";
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vIdEstudiante, setvIdEstudiante] = useState(-1);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={vData}
        rowClassName={getRowClassName}
        bordered={false}
        pagination={{
          position: ["none", "none"],
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              setvIdEstudiante(record.key);
              showModal();
            }, // double click row
          };
        }}
      />
      <InfoAlumno
        {...props}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        vIdEstudiante={vIdEstudiante}
      />
    </>
  );
}

export default function Estudiantes(props) {
  const { BotonesCurso, vIdCurso, nombreCurso, windowSize } = props;

  const [vData, setvData] = useState([]);
  const [vIsLoadingAlumnos, setvIsLoadingAlumnos] = useState(true);

  const mCrearData = (vDatos) => {
    var vData = vDatos.map((vDato, index) => {
      return {
        key: vDato.id,
        acceso: vDato.acceso,
        estado: vDato.estado,
        nombre: vDato.nombre,
        certificado: vDato.certificado,
        vencimiento: vDato.vencimiento,
        avance: vDato.porcentaje,
        nota: vDato.nota,
      };
    });
    setvData(vData);
    return vData;
  };
  useEffect(() => {
    Gets.mGetEstudiantesCursoProfesor(vIdCurso)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Respuesta de red no fue exitosa");
        }
        return response.json();
      })
      .then((result) => {
        mCrearData(result.alumnos);
      })
      .catch(() => {})
      .finally(() => {
        setvIsLoadingAlumnos(false);
      });
  }, []);

  return (
    <div
      class="conteniido-curso-profesor"
      style={{ height: `${windowSize.height - 80}px` }}
    >
      <div class="text-title-curso">{nombreCurso}</div>
      <BotonesCurso {...props} />

      <div class="contenedor-buscador">
        <div class="barra-de-busqueda-perfil">
          <img
            src="../images/Vectors-Wrapper_3.svg"
            loading="lazy"
            width="18"
            height="18"
            alt=""
            class="vectors-wrapper-115"
          />
          <input class="input-busqueda" />
        </div>
      </div>
      {vIsLoadingAlumnos ? (
        <Spin />
      ) : (
        <TablaEstudiantes {...props} vData={vData} />
      )}
    </div>
  );
}
